var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-label', [_vm._v("Center of Gravity (m)")])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "px-2 pt-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "X",
      "value": _vm.value.x,
      "disabled": _vm.disabled,
      "placeholder": "0",
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('x', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "px-2 pt-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Y",
      "value": _vm.value.y,
      "disabled": _vm.disabled,
      "placeholder": "0",
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('y', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "px-2 pt-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Z",
      "value": _vm.value.z,
      "disabled": _vm.disabled,
      "placeholder": "0",
      "dense": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('z', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }