var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-simple-table', {
    attrs: {
      "dense": ""
    }
  }, [_c('tbody', _vm._l(_vm.results, function (result, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(result.key))]), result.units === 'mm' || result.units === 'kg' ? _c('td', [_vm._v(" " + _vm._s(_vm._f("formatNumber")(result.value)) + " " + _vm._s(result.units) + " ")]) : _c('td', [_vm._v(" " + _vm._s(result.value) + " " + _vm._s(result.units) + " ")])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }