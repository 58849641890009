<template>
  <v-container fluid>
    <!-- may use breakpoints on v-navigation-drawer after "permanent" with
    :mini-variant="$vuetify.breakpoint.mdAndDown" -->
    <v-navigation-drawer
      app
      clipped
      permanent
    >
      <v-btn
        slot="prepend"
        class="ma-2"
        dark
        color="primary"
        :to="{name: 'create-' + route}"
      >
        New {{ displayName }}
      </v-btn>
      <v-subheader>Local</v-subheader>
      <nav-list
        :route="'edit-'+route"
        :objects="localObjects"
        :group-by="groupBy"
        :add-other-option="loadSetRoute ? true : false"
      />
      <v-subheader>Global</v-subheader>
      <nav-list
        :route="'edit-'+route"
        :objects="globalObjects"
        :group-by="groupBy"
      />
    </v-navigation-drawer>
    <template v-if="isEditing || isCreating || loadSetRoute">
      <v-toolbar
        v-if="isEditing || isCreating"
        class="mt-2"
        elevation="3"
        :style="{
          'position': 'sticky',
          'z-index': 20,
          'top': toolbarBreakpoint,
        }"
      >
        <v-alert
          v-if="isLocked"
          dense
          type="info"
          icon="mdi-lock"
          class="ml-2"
          style="margin:auto;"
        >
          Item Locked - Used in Locked Tower(s)
        </v-alert>
        <v-alert
          v-else-if="isGlobal && !isLocked"
          dense
          type="info"
          icon="mdi-earth"
          class="ml-2"
          style="margin:auto;"
        >
          Item Locked - 'Global' Org. ID
        </v-alert>
        <v-btn
          v-else
          :disabled="unedited || isGlobal || !isValid || saving || componentInvalid || isLocked"
          :loading="saving"
          color="primary"
          @click="saveObject()"
        >
          Save
        </v-btn>
        <v-spacer />
        <usage-modal 
          v-if="original.tower_references"
          v-model="showUsageModal" 
          :references="original.tower_references"
          :num-refs="numRefs"
          :object-name="original.data.name"
        />
        <history-modal 
          v-if="original.history"
          v-model="showHistoryModal"
          :history="original.history"
          :object-name="original.data.name"
        />
        <v-menu
          open-on-hover
          bottom
          rounded="lg"
          :offset-y="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :large="$vuetify.breakpoint.mdAndUp">
                mdi-menu
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="selection">
              <!-- COPY -->
              <v-list-item
                :disabled="isCreating || isLoadSetRoute"
                @click="copyObject($route.params.uuid)"
              >
                <v-list-item-icon>
                  <v-icon v-if="!copying">mdi-content-copy</v-icon>
                  <v-progress-circular
                    v-else
                    indeterminate
                    :width="3"
                    :size="25"
                    color="primary"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="!copying">Copy</v-list-item-title>
                  <v-list-item-title v-else>Copying...</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- Archive -->
              <v-list-item
                :disabled="isGlobal || isCreating || isLoadSetRoute"
                @click="archiveObject($route.params.uuid)"
              >
                <v-list-item-icon>
                  <v-icon v-if="!archiving">mdi-archive-lock-outline</v-icon>
                  <v-progress-circular
                    v-else
                    indeterminate
                    :width="3"
                    :size="25"
                    color="primary"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="!archiving">Archive</v-list-item-title>
                  <v-list-item-title v-else>Archiving...</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- Delete -->
              <v-list-item
                :disabled="isGlobal || isCreating || numRefs !== 0 || isLocked"
                @click="deleteObject($route.params.uuid)"
              >
                <v-list-item-icon>
                  <v-icon v-if="!deleting">mdi-delete-outline</v-icon>
                  <v-progress-circular
                    v-else
                    indeterminate
                    :width="3"
                    :size="25"
                    color="primary"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="!deleting">Delete</v-list-item-title>
                  <v-list-item-title v-else>Deleting...</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- Usages -->
              <v-list-item
                :disabled="numRefs === 0 || isCreating"
                @click.stop="showUsageModal = true"
              >
                <v-list-item-icon style="align-self:center">
                  <v-icon>mdi-wind-turbine</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <div style="display: flex; width:80px; height:40px; align-items: center;">
                    <v-badge
                      bordered
                      color="primary"
                      :content="numRefs"
                      :value="numRefs"
                    >
                      Usages
                    </v-badge>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <!-- History -->
              <v-list-item
                :disabled="!original.history"
                @click.stop="showHistoryModal = true"
              >
                <v-list-item-icon>
                  <v-icon>mdi-history</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>History</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        rounded
        color="primary"
        width="100%"
      />
      <v-form v-model="isValid">
        <component
          v-if="$route.name === 'list-load-set'"
          :is="list"
        />
        <component
          v-else-if="isCreating || localObject"
          :is="edit"
          v-model="localObject"
          :disabled="isGlobal || isLocked"
          :extraData="extraData"
          :warnings="warnings"
          ref="crudChildRef"
          @validityChange="componentInvalid = $event"
        />
      </v-form>
    </template>
  </v-container>
</template>

<script>
import _ from "lodash/fp"
import api from "@/store/api"
import NavList from "@/components/NavList"
import Turbine from "@/components/Turbine"
import DesignBasis from "@/components/design/DesignBasis"
import LoadSetList from "./LoadSetList"
import EditLoadSet from "@/components/EditLoadSet"
import Inventory from "@/components/Inventory"
import Material from "@/components/Material"
import Bolt from "@/components/Bolt"
import UsageModal from "@/components/UsageModal"
import HistoryModal from "@/components/HistoryModal"
export default {
  name: "CRUDView",
  components: {
    NavList,
    UsageModal,
    HistoryModal
  },
  beforeRouteLeave(to, from, next) {
    this.checkUnsavedChanges(next)
  },
  beforeRouteUpdate(to, from, next) {
    this.checkUnsavedChanges(next)
  },
  data() {
    return {
      isValid: true,
      original: {},
      localObject: {},
      objects: [],
      saving: false,
      copying: false,
      deleting: false,
      archiving: false,
      componentInvalid: false,
      showUsageModal: false,
      showHistoryModal: false,
      selection: null,
      loading: false,
      extraData: null,
      warnings: null,
    }
  },
  computed: {
    route() {
      return this.$route.path.split("/")[1]
    },
    isLoadSetRoute() {
      return this.$route.path.split("/")[1] === 'load-set'
    },
    displayName() {
      return {
        "turbine": "Turbine",
        "design-basis": "Design Basis",
        "load-set": "Load Set",
        "inventory": "Inventory",
        "material": "Material",
        "bolt": "Bolt",
      }[this.route]
    },
    edit() {
      return {
        "turbine": Turbine,
        "design-basis": DesignBasis,
        "load-set": EditLoadSet,
        "inventory": Inventory,
        "material": Material,
        "bolt": Bolt,
      }[this.route]
    },
    list() {
      return {
        "load-set": LoadSetList,
      }[this.route]
    },
    groupBy() {
      return {
        "turbine": "data.oem",
        "load-set": "data.oem"
      }[this.route]
    },
    sortedObjects() {
      return [...this.objects].sort((a, b) =>
        b.lastmodified.localeCompare(a.lastmodified)
      )
    },
    localObjects() {
      return this.sortedObjects.filter(
        (item) => item.organization_id != "global")
    },
    globalObjects() {
      return this.sortedObjects.filter(
        (item) => item.organization_id == "global")
    },
    isGlobal() {
      return this.original.organization_id == "global"
    },
    isEditing() {
      return !!this.$route.params.uuid
    },
    isCreating() {
      return this.$route.path == "/" + this.route + "/create"
    },
    loadSetRoute() {
      return "load-set" === this.$route.path.split("/")[1]
    },
    unedited() {
      if (this.isCreating) {
        return _.isEqual({}, this.localObject)
      } else {
        return this.$route.name.startsWith('list')
          || !("data" in this.original)
          || _.isEqual(this.original.data, this.localObject)
      }
    },
    numRefs() {
      return this.original.tower_references ? this.original.tower_references.length : 0
    },
    toolbarBreakpoint() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '56px'
        case 'sm': return '56px'
        default: return '64px'
      }
    },
    isLocked() {
      // Use `isLockedItem` mixin
      return this.isLockedItem(this.original?.tower_references)
    },
  },
  watch: {
    $route() {
      this.loadObjects()
    },
    selection() {
      this.$nextTick(() => {
        this.selection = null
      });
    },
  },
  mounted() {
    this.loadObjects()
  },
  methods: {
    createObject() {
      return api.post("/" + this.route, this.localObject)
        .then((response) => {
          this.objects.push(response.data)
          this.original = response.data
          this.$router.push({
            name: "edit-" + this.route,
            params: { uuid: response.data.uuid },
          })
        })
        .catch((error) => {
          this.$store.commit(
            "displayError",
            "Error creating object: " + error
          )
        })
    },
    updateObject() {
      let uuid = this.$route.params.uuid
      return api.put("/" + this.route + "/" + uuid, this.localObject)
        .then((response) => {
          this.objects = _.concat(
            _.remove((o) => o.uuid == uuid, this.objects),
            response.data)
          this.original = response.data
        })
        .catch((error) => {
          this.$store.commit(
            "displayError",
            "Error updating object: " + error
          )
        })
    },
    saveObject() {
      this.saving = true
      if (this.loadSetRoute) {
        if (this.isEditing) {
          this.$refs.crudChildRef.saveLoadSet()
            .then(() => this.saving = false)
        } else {
          this.$refs.crudChildRef.uploadLoadSet()
            .then(() => this.saving = false)
        }
      } else {
        if (this.isEditing) {
          this.updateObject().finally(() => this.saving = false)
        } else {
          this.createObject().finally(() => this.saving = false)
        }
      }
    },
    deleteObject(uuid) {
      this.$confirm("Do you really want to delete this?").then(
      (res) => {
        if (res) {
          this.deleting = true
          if (this.loadSetRoute) {
            this.$store.dispatch("deleteLoadSet", uuid)
              .finally(() => {
                this.$router.push("/load-set")
                this.deleting = false
              })
          } else {
            api.delete("/" + this.route + "/" + uuid)
            .then(() => {
              this.$router.push({ name: "list-" + this.route })
            })
            .catch((error) => {
              this.$store.commit(
                "displayError",
                "Error deleting object: " + error.response.data
              )
            })
            .finally(() => this.deleting = false)
          }
        }
      })
    },
    copyObject(uuid) {
      this.copying = true
      api.post("/" + this.route + "/" + uuid + "/copy", {})
      // used to fetch here
      .then((response) => {
        this.objects.push(response.data)
        this.$router.push({
          name: "edit-" + this.route,
          params: { uuid: response.data.uuid },
        })
      }).finally(() => this.copying = false)
    },
    archiveObject(uuid) {
      this.$confirm("Do you really want to archive this?").then(
      (res) => {
        if (res) {
          this.archiving = true
          api.put("/" + this.route + "/" + uuid + "/archive", {})
          .then(() => {
            this.$router.push({ name: "list-" + this.route })
          })
          .catch((error) => {
            this.$store.commit(
              "displayError",
              "Error archiving object" + error.response.data
            )
          })
          .finally(() => this.archiving = false)
        }
      })
    },
    getLoadSetData(uuid) {
      // Retrieves loadset dynamo data & s3 data
      this.original = { data: {} }
      this.localObject = {}
      this.extraData = []
      const defaultParserVars = {
        f_includes_tower_mass: undefined,
        m_includes_tilt_moment: undefined,
        unit_overrides: {
          elevation: undefined,
          f_x: undefined,
          f_y: undefined,
          f_z: undefined,
          m_x: undefined,
          m_y: undefined,
          m_z: undefined,
        },
        reference_cycles: undefined,
        slope: undefined,
        // TODO Add sensor_factory
      }
      this.$store.dispatch("getDynamoLoadSet", uuid)
        .then((response) => {
          // Add default parserVars
          let data = {
            ...response.data.data,
            parserVars: { ...defaultParserVars },
          }
          response.data.data = data
          this.original = response.data
          this.localObject = this.original.data
          this.warnings = this.original.errors
          this.extraData = []

          let moreThanOneLoadCase = Object.keys(this.localObject.load_case_counts).length !== 1
          let loadTypeIsMarkov = ['Markov', 'Markov ZIP'].includes(this.localObject.load_set_type)
          if (!moreThanOneLoadCase && !loadTypeIsMarkov) {
            return this.$store.dispatch("getS3LoadSet", uuid)
              .then((response) => {
                if (response) {
                  this.extraData = response
                }
              })
              .finally(() => this.loading = false)
          }
        })
        .finally(() => this.loading = false)
    },
    loadObjects() {
      this.loading = true
      const fetchList = {
        // From src>store>index.js
        "turbine":      "fetchTurbines",
        "design-basis": "fetchDesignBases",
        "load-set":     "fetchTurbines", // Used for listing by turbine
        "inventory":    "fetchInventory",
        "material":     "fetchMaterials",
        "bolt":         "fetchBolts",
      }
      this.$store.dispatch(fetchList[this.route])
        .then((response) => {
          this.objects = response
        })

      let uuid = this.$route.params.uuid
      if (uuid) {
        if (this.$route.name === 'edit-load-set') {
          this.getLoadSetData(uuid)
        } else {
          api.get("/" + this.route + "/" + uuid)
            .then((response) => {
              this.original = response.data
              this.localObject = _.cloneDeep(this.original.data)
            })
            .finally(() => this.loading = false)
        }
      } else if (this.$route.name.startsWith('create')) {
        if (this.$route.name === 'create-load-set') {
          const loadSetDefaults = {
            name: "",
            load_set_type: 'Buckling',
            turbine: null,
            hub_height: null,
            wind_class: null,
            notes: [],
            internal_notes: "",
            parserVars: {
              m_includes_tilt_moment: undefined,
              f_includes_tower_mass: undefined,
              reference_cycles: undefined,
              slope: undefined,
              unit_overrides: {
                elevation: undefined,
                f_x: undefined,
                f_y: undefined,
                f_z: undefined,
                m_x: undefined,
                m_y: undefined,
                m_z: undefined,
              }
            }
          }
          this.original = { data: {...loadSetDefaults} }
          this.localObject = loadSetDefaults
        } else {
          this.setComponentDefaults()
        }
      } else {
        this.setComponentDefaults()
      }
      this.loading = false
    },
    checkUnsavedChanges(next) {
      if (this.loadSetRoute) {
        next(true)
      } else {
        if (this.unedited) {
          next(true)
        } else {
          this.$confirm(
            "You have unsaved changes which will be lost. Still leave?"
          ).then((res) => {
            next(res)
          });
        }
      }
    },
    setComponentDefaults() {
      this.original = { data: {} }
      this.localObject = {}
      this.extraData = null
      this.warnings = null
      this.loading = false
    },
  },
}
</script>
