<template>
  <v-row no-gutters>
    <v-col :cols="colWidth">
      <v-textarea
        :value="value"
        filled
        auto-grow
        no-resize
        :label="label"
        :disabled="disabled"
        @input="$emit('input', $event)"
      >
        <template #prepend-inner>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                color="primary"
              >
                mdi-information
              </v-icon>
            </template>
            {{ notesInfo }}
          </v-tooltip>
        </template>
      </v-textarea>
    </v-col>
  </v-row>

</template>

<script>
// This component is used for Internal Notes & Report Notes
export default {
  name: "AllNotes",
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    notesInfo: {
      type: String,
      default: null,
    },
    colWidth: {
      type: Number,
      default: 12,
    }
  },
}
</script>