import Vue from "vue"
import VueRouter from "vue-router"
import TowerList from "@/views/TowerList"
import UserProfile from "@/views/UserProfile"
import Intent from "@/views/tower/Intent"
import SRFPlot from "@/views/tower/SRFPlot"
import Reports from "@/views/tower/Reports"
import ViewCSVReport from "@/views/tower/ViewCSVReport"
import CrudView from "@/views/CrudView"

Vue.use(VueRouter);

const libraryRoutes = [
  "turbine",
  "design-basis",
  "load-set",
  "inventory",
  "material",
  "bolt",
]
export default new VueRouter({
  routes:
    libraryRoutes.flatMap((route) => [
      {
        path: "/" + route,
        name: "list-" + route,
        component: CrudView,
      },
      {
        path: "/" + route + "/create",
        name: "create-" + route,
        component: CrudView,
      },
      {
        path: "/" + route + "/:uuid",
        name: "edit-" + route,
        component: CrudView,
      },
    ]).concat([
      {
        path: "/",
        redirect: { name: 'towers' },
      },
      {
        path: "/towers",
        name: "towers",
        component: TowerList,
      },
      {
        path: "/towers/list?turbine=:turbine&model_number=:model_number",
        name: "towers-list-turbine-model-number",
        component: TowerList,
      },
      {
        path: "/towers/list?turbine=:turbine",
        name: "towers-list-turbine",
        component: TowerList,
      },
      {
        path: "/towers/list",
        name: "towers-list",
        component: TowerList,
      },
      {
        path: "/tower/:uuid/intent",
        name: "tower-intent",
        component: Intent,
      },
      {
        path: "/tower/:uuid/srf",
        name: "tower-srf-plot",
        component: SRFPlot,
      },
      {
        path: "/tower/:uuid/reports",
        name: "tower-reports",
        component: Reports,
      },
      {
        path: "/tower/report-csv-viewer/:filename",
        name: "tower-report-csv-viewer",
        component: ViewCSVReport,
      },
      {
        path: "/profile",
        name: "profile",
        component: UserProfile,
      },
    ]),
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
