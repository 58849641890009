<template>
  <v-card elevation="4" class="mb-4">
    <v-card-title class="pb-2">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="pb-0">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-subtitle v-if="showRevisionSubtitle">
      <span>Revision: </span>
      <bordered-text-span
        :text="String(maxRevisionNumber)"
        padding="3px"
      />
    </v-card-subtitle>
    <v-data-table
      v-if="towerListRoute"
      :headers="mainHeaders"
      :items="items"
      sort-by="lastmodified"
      sort-desc
      disable-pagination
      hide-default-footer
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="uuid"
      :loading="fetching || actionOccuring"
      v-bind="optionalProps"
    >
      <!-- Expanded Item Contents -->
      <template
        #expanded-item="{ headers, item }"
      >
        <td :colspan="headers.length">
          <v-card elevation="4">
            <v-data-table
              :headers="sublistHeaders"
              :items="subItems(item)"
              sort-by="lastmodified"
              sort-desc
              disable-pagination
              hide-default-footer
              item-key="uuid"
            >
              <!-- Name with Hover to View Tower Plot -->
              <template #[`item.name`]="{ item }">
                <span>
                  <v-menu
                    transition="slide-x-transition"
                    offset-x
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-if="item.sketch"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <v-container fluid class="ma-0 pa-0">
                      <v-card class="d-flex flex-column">
                        <v-card-title>Tower Plot</v-card-title>
                        <v-card-subtitle style="width: 250px;">{{ item.name }}</v-card-subtitle>
                        <div
                          :style="{
                            'max-height': $vuetify.breakpoint.xlOnly ? 'auto' : '400px',
                            'overflow': $vuetify.breakpoint.xlOnly ? 'auto' : 'scroll',
                          }">
                          <v-img
                            alt="tower-sketch"
                            v-html="item.sketch"
                            style="
                              border:1px solid black;
                              max-height: 100%;
                              max-width: 100%;
                            "
                          />
                        </div>
                      </v-card>
                    </v-container>
                    </v-menu>
                  </span>
                  <span>
                    <router-link
                      :to="{
                        name: 'tower-intent',
                        params: { uuid: item.uuid },
                      }"
                    >
                      {{ item.name }}
                    </router-link>
                  </span>
                </template>

                <!-- Format Last Modified -->
                <template #[`item.lastmodified`]="{ item }">
                  <time-distance :time-str="item.lastmodified" />
                </template>

                <!-- Action Buttons -->
                <template #[`item.actions`]="{ item }">
                  <div class="d-flex flex-row">
                    <!-- Copy Tower Button -->
                    <v-btn
                      icon
                      class="mr-2"
                      @click="copyTower(item)"
                    >
                      <v-icon>mdi-file-multiple</v-icon>
                    </v-btn>
                    <!-- Archive Tower Button -->
                    <v-btn
                      v-if="checkIfTowerDraft(item.status_dates)"
                      icon
                      class="mr-2"
                      @click="archiveTower(item)"
                    >
                      <v-icon>mdi-package-down</v-icon>
                    </v-btn>
                    <!-- Delete Tower Button -->
                    <v-btn
                      v-if="checkIfTowerDraft(item.status_dates)"
                      icon
                      @click="deleteTower(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </td>
        </template>
        <!-- Name with Hover to View Tower Plot -->
        <template #[`item.name`]="{ item }">
          <span>
            <router-link
              :to="{
                name: 'tower-intent',
                params: { uuid: item.uuid },
              }"
            >
              {{ item.name }}
            </router-link>
          </span>
          <span>
            <v-menu
              open-on-hover
              open-delay="200"
              offset-x
            >
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="item.sketch"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <v-container fluid class="ma-0 pa-0">
              <v-card class="d-flex flex-column">
                <v-card-title>Tower Plot</v-card-title>
                <v-card-subtitle style="width: 250px;">{{ item.name }}</v-card-subtitle>
                <div
                  :style="{
                    'max-height': $vuetify.breakpoint.xlOnly ? 'auto' : '400px',
                    'overflow': $vuetify.breakpoint.xlOnly ? 'auto' : 'scroll',
                  }">
                  <v-img
                    v-html="item.sketch"
                    style="
                      max-height: 100%;
                      max-width: 100%;
                    "
                  />
                </div>
              </v-card>
            </v-container>
          </v-menu>
        </span>
      </template>

      <!-- Tower Status Button -->
      <template #[`item.status`]="{ item }">
        <v-btn
          v-if="showStatus(item)"
          small
          rounded
          outlined
          :color="`${status(item).color}`"
        >
          {{ status(item).status }}
        </v-btn>
      </template>

      <!-- Format Last Modified -->
      <template #[`item.lastmodified`]="{ item }">
        <time-distance :time-str="item.lastmodified" />
      </template>

      <!-- Action Buttons -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex flex-row">
          <!-- Copy Tower Button -->
          <v-btn
            icon
            class="mr-2"
            @click="copyTower(item)"
          >
            <v-icon>mdi-file-multiple</v-icon>
          </v-btn>
          <!-- Archive Tower Button -->
          <v-btn
            v-if="checkIfTowerDraft(item.status_dates)"
            icon
            class="mr-2"
            @click="archiveTower(item)"
          >
            <v-icon>mdi-package-down</v-icon>
          </v-btn>
          <!-- Delete Tower Button -->
          <v-btn
            v-if="checkIfTowerDraft(item.status_dates)"
            icon
            @click="deleteTower(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import TimeDistance from "@/components/common/TimeDistance"
import BorderedTextSpan from "@/components/common/BorderedTextSpan"

export default {
  name: "TowerListDataCard",
  components: {
    TimeDistance,
    BorderedTextSpan,
  },
  props: {
    headers: {
      type: Array
    },
    expandedHeaders: {
      type: Array
    },
    items: {
      type: Array
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    showRevisionSubtitle: {
      type: Boolean,
      default: false,
    },
    removeTableRevision: {
      type: Boolean,
      default: false,
    },
    variableStatus: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean
    },
    optionalProps: {
      type: Object
    },
  },
  data() {
    return {
      expanded: [],
      actionOccuring: false,
    }
  },
  computed: {
    towerListRoute() {
      return this.$route.path.slice(0,12) === '/towers/list'
    },
    sublistHeaders() {
      if (!this.expandedHeaders) {
        return this.headers
      } else {
        return this.expandedHeaders.filter((header) => {
          return header.text !== "Revision"
        })
      }
    },
    mainHeaders() {
      if (this.removeTableRevision) {
        return this.headers.filter((header) => {
          return header.text !== "Revision"
        })
      } else {
        return this.headers
      }
    },
    maxRevisionNumber() {
      return Math.max(
        ...this.$store.state.towers.map(tower => tower.revision), 1)
    },
  },
  methods: {
    status(item) {
      return this.currentStatus(item.status_dates)
    },
    showStatus(item) {
      if (this.variableStatus) {
        let status = this.currentStatus(item.status_dates).status
        return (status !== "Draft")
      } else {
        return true
      }
    },
    subItems(item) {
      return this.$store.state.towers.filter((tower) => {
        return (tower.revision === item.revision)
          && (this.checkIfTowerDraft(tower.status_dates))
      })
    },
    deleteTower(item) {
      this.$confirm("Do you really want to delete this tower?").then((res) => {
        if (res) {
          this.actionOccuring = true
          this.$store.dispatch("deleteTower", item.uuid)
            .then(() => this.actionOccuring = false)
        }
      });
    },
    copyTower(item) {
      this.$confirm("Are you sure you want to copy this tower?")
      .then((res) => {
        this.actionOccuring = true
        if (res) {
          let params = this.checkParams()
          let maxRevision = Math.max(
            ...this.$store.state.towers.map(tower => tower.revision),
            1
          )
          params = { ...params, last_revision: maxRevision }
          this.$store.dispatch('copyTower', { towerId: item.uuid, params: params })
            .then(() => this.actionOccuring = false)
          }
        })
    },
    archiveTower(item) {
      this.$confirm("Do you really want to archive this tower?").then((res) => {
        if (res) {
          this.actionOccuring = true
          let params = this.checkParams()
          this.$store.dispatch("archiveTower", item.uuid, params)
            .then(() => this.actionOccuring = false)
        }
      });
    },
    checkParams() {
      let params = ("turbine" in this.$route.params) ? this.$route.params : this.$route.query
      if (params.model_number) {
        params.model_number = Number(params.model_number)}
      return params
    },
  }
}
</script>

<style scoped>
  .v-data-table >>> tr:hover {
    background: transparent !important;
  }
  </style>