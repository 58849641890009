<template>
  <v-card
    class="my-2"
    elevation="24"
  >
    <v-card-title class="headline v-subheader justify-center">
      Material
    </v-card-title>
    <v-card-text>
      <v-col>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Name"
              :value="value.name"
              :disabled="disabled"
              :rules="[formRules.required]"
              @input="updateValue('name', $event, value)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <numeric-text-field
              label="Tensile Strength"
              :value="value.tensile_strength"
              units="Mpa"
              :disabled="disabled"
              required
              @input="updateValue('tensile_strength', $event, value)"
            />
          </v-col>
          <v-col cols="3">
            <numeric-text-field
              label="Yield Strength"
              :value="value.yield_strength"
              units="Mpa"
              :disabled="disabled"
              required
              @input="updateValue('yield_strength', $event, value)"
            />
          </v-col>
          <v-col cols="3">
            <numeric-text-field
              label="Density"
              :value="value.density"
              class="mx-auto"
              units="kg/m^3"
              :disabled="disabled"
              required
              @input="updateValue('density', $event, value)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <numeric-text-field
              label="Elastic Modulus"
              :value="value.elastic_modulus"
              class="mx-auto"
              units="Gpa"
              :disabled="disabled"
              required
              @input="updateValue('elastic_modulus', $event, value)"
            />
          </v-col>
          <v-col cols="3">
            <numeric-text-field
              label="Poisson's Ratio"
              :value="value.poissons_ratio"
              class="mx-auto"
              :disabled="disabled"
              required
              @input="updateValue('poissons_ratio', $event, value)"
            />
          </v-col>
          <v-col cols="3">
            <numeric-text-field
              label="Shear Modulus"
              :value="value.shear_modulus"
              class="mx-auto"
              units="Gpa"
              :disabled="disabled"
              required
              @input="updateValue('shear_modulus', $event, value)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "ComponentMaterial",
  components: {
    NumericTextField,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
