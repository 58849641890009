<template>
  <v-text-field
    v-model="displayValue"
    :suffix="units"
    :rules="rules"
    v-bind="$attrs"
    :disabled="disabled"
  />
</template>

<script>
import {isNumeric} from "@/utils/predicate"

export default {
  name: "CommaSeparateList",
  props: ["value", "units", "disabled"],
  computed: {
    displayValue: {
      get () {
        return this.value ? this.value.map((n) => n.toString()).join(",") : ""
      },
      set (v) {
        this.$emit("input", this.parseInput(v).map((s) => Number(s)))
      },
    },
    rules () {
      return [
        (v) =>
          this.parseInput(v).filter((i) => !isNumeric(i)).length == 0
          || "Must be a list of numbers.",
      ]
    },
  },
  methods: {
    parseInput(str) {
      return str.split(",").filter((item) => item.trim())
    },
  },
}
</script>
