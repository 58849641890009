<template>
  <v-app>
    <v-app-bar
      app
      dark
      clipped-left
      color="primary"
    >
      <router-link to="/">
        <v-img
          class="ml-2 mr-4"
          alt="KTS"
          src="https://images.squarespace-cdn.com/content/v1/6081b76972ec8a15b7a63149/adb36b73-531a-4920-bcc5-559630ae8fc8/Logo_medium.svg"
          max-width="150"
          contain
        />
      </router-link>
      <v-tabs
        v-if="$store.getters.isSignedIn()"
        background-color="primary"
      >
        <v-tab
          v-for="(entry, index) in topLevelRoutes"
          :key="index"
          :to="{ name: entry.route }"
        >
          {{ entry.label }}
        </v-tab>
      </v-tabs>

      <v-spacer />

      <v-menu
        v-if="$store.getters.isSignedIn()"
        left
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/account">
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main app>
      <main-alert />
      <template v-if="$store.state.gettingUser">
        <v-progress-circular
          indeterminate
          width="3"
          size="55"
          color="primary"
          style="width:100%"
          class="d-flex justify-center mt-5"
          />
      </template>
      <template v-else>
        <template v-if="$store.getters.isSignedIn()">
          <router-view />
        </template>
        <template v-else>
          <sign-in />
        </template>
      </template>
    </v-main>

    <v-footer
      absolute
      app
      bottom
    >
      <span>&copy; 2022 Keystone Tower Systems. All Rights Reserved.</span>
    </v-footer>
  </v-app>
</template>

<script>
import SignIn from "@/views/SignIn"
import MainAlert from "@/components/MainAlert"

export default {
  name: "App",
  components: {
    SignIn,
    MainAlert,
  },
  watch: {
    $route() {
      this.$store.dispatch("clearErrors")
    },
  },
  computed: {
    topLevelRoutes() {
      return [
        { route: "towers", label: "Towers" },
        { route: "list-turbine", label: "Turbines" },
        { route: "list-design-basis", label: "Design Bases" },
        { route: "list-load-set", label: "Load Sets" },
        { route: "list-inventory", label: "Inventories" },
        { route: "list-material", label: "Materials" },
        { route: "list-bolt", label: "Bolts" },
      ]
    },
  },
  beforeCreate () {
    this.$store.dispatch("getCurrentUser")
  },
  methods: {
    logout() {
      this.$store.dispatch("signOut")
    },
  },
}
</script>
