<template>
  <v-card elevation="4" max-width="1000px" style="margin:0 auto;">
    <v-alert v-if="markovZipWarning" type="warning" dense>
      Can only parse Markov Zip files for 'GE' Turbines
    </v-alert>
    <v-card-title v-if="editLoadset">Edit Load Set</v-card-title>
    <v-card-title v-else>
      New Load Set
      <span>
        <v-menu
          open-on-hover
          transition="slide-x-transition"
          offset-x
          min-width="auto"
          nudge-top="15"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary">mdi-information</v-icon>
            </v-btn>
          </template>
          <v-card width="600px" height="480px">
            <v-card-title>Load Set Info</v-card-title>
            <v-card-subtitle>Load Set Import Coordinate System</v-card-subtitle>
            <v-row>
              <v-col cols="7">
                <v-avatar
                  rounded="0"
                  size="380"
                >
                  <v-img
                    alt="loadset_coordinate_system.png"
                    src="@/assets/loadset_coordinate_system.png"
                    contain
                  />
                </v-avatar>
              </v-col>
              <v-col cols="5">
                <v-card-text class="text-wrap">
                  <span class="font-weight-bold">Note:</span>
                  Load Set imports (notably Buckling load sets) must comply with the axes in this diagram.<br/><br/>
                  Careful as not all manufacturers use the same coordinate system convention. Check with the OEM that produced your load set.
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </span>
    </v-card-title>
      <v-card-text class="py-0">
        <v-form v-model="isValidForm">
          <v-row>
            <v-col cols="6">
              <!-- Name Field -->
              <v-text-field
                :value="value.name"
                dense
                label="Name"
                :rules="[formRules.required]"
                :disabled="disabled"
                @input="updateValue('name', $event, value)"
              />
              <!-- Load Set Type Field -->
              <v-select
                :value="value.load_set_type"
                dense
                label="Load Set Type"
                :items="loadSetTypes"
                :rules="[formRules.required]"
                :disabled="($route.name === 'edit-load-set' && !reuploadFile) || disabled"
                @input="updateValue('load_set_type', $event, value)"
              />
              <!-- Turbine Field -->
              <library-select 
                :value="value.turbine"
                label="Turbine"
                :items="$store.state.turbines"
                item-text="data.name"
                item-value="uuid"
                :disabled="disabled"
                @input="updateValue('turbine', $event, value)"
              />
              <v-row class="mb-2">
                <v-col cols="12" md="6" class="py-0">
                  <!-- Hub Height Field -->
                  <numeric-text-field
                    :value="value.hub_height"
                    dense
                    label="Hub Height"
                    units="m"
                    :disabled="disabled"
                    @input="updateValue('hub_height', $event, value)"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <!-- Wind Class Field -->
                  <v-select
                    :value="value.wind_class"
                    dense
                    label="Wind Class/Site"
                    :items="windClasses"
                    item-text="text"
                    item-value="value"
                    :disabled="disabled"
                    @input="updateValue('wind_class', $event, value)"
                  />
                </v-col>
              </v-row>
              <v-switch
                v-if="editLoadset"
                v-model="reuploadFile"
                label="Re-upload a csv loadset file?"
                class="mt-0"
              />
              <v-row>
                <template v-if="(createLoadSetRoute || reuploadFile) && value.load_set_type && value.load_set_type !== 'Markov ZIP'">
                  <v-col cols="12" md="12" class="pb-0">
                    <h4>
                      Example CSV for <span>{{ value.load_set_type }}</span>
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            @click="handleDownloadCSV"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </template>
                        Download Default CSV
                      </v-tooltip>
                    </h4>
                    <ul class="mb-2">
                      <li>Note: Do not include units in column headers</li>
                    </ul>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-data-table
                      :headers="defaultCSVHeaders"
                      :items="defaultCSVItems"
                      disable-pagination
                      hide-default-footer
                      dense
                      disable-sort
                      no-data-text=""
                      class="mb-7 ml-4"
                    />
                  </v-col>
                </template>
              </v-row>
              <!-- Advanced Settings for Damage Equivalent Loads -->
              <v-row
                v-if="value.load_set_type === 'Damage Equivalent' && (reuploadFile || createLoadSetRoute)"
                class="ma-0"
              >
                <span class="mb-4 font-weight-bold">Advanced Settings (defaults are preset)</span>
                <v-col cols="12" md="6" class="py-0 mb-0">
                  <!-- Reference Cycles Field (Parser Variable) -->
                  <numeric-text-field
                    :value="value.parserVars.reference_cycles"
                    dense
                    label="Reference Cycles"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.reference_cycles', $event, value)"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0 mb-0">
                  <!-- Slope Field (Parser Variable) -->
                  <numeric-text-field
                    :value="value.parserVars.slope"
                    dense
                    label="Slope"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.slope', $event, value)"
                  />
                </v-col>
              </v-row>
              <!-- Advanced Settings for MultiBuckling Loads -->
              <v-row
                v-if="value.load_set_type === 'Buckling' && (reuploadFile || createLoadSetRoute)"
                class="ma-0"
              >
                <span class="font-weight-bold">Advanced Settings (defaults are preset)</span>
                <v-col cols="12" md="6" class="py-0 mb-0">
                  <!-- f_includes_tower_mass Field (Parser Variable) -->
                  <v-checkbox
                    :input-value="value.parserVars.f_includes_tower_mass"
                    label="f includes tower mass?"
                    :disabled="disabled"
                    @change="updateValue('parserVars.f_includes_tower_mass', $event, value)"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <!-- m_includes_tilt_moment Field (Parser Variable) -->
                  <v-checkbox
                    :input-value="value.parserVars.m_includes_tilt_moment"
                    label="m includes tilt moment?"
                    :disabled="disabled"
                    @change="updateValue('parserVars.m_includes_tilt_moment', $event, value)"
                  />
                </v-col>
                <!-- Buckling unit_overrides (Parser Variables) -->
                <v-col cols="12" md="4">
                  <v-select
                    :value="value.parserVars.unit_overrides.elevation"
                    dense
                    label="Elevation"
                    :items="unit_override_items.elevation"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.unit_overrides.elevation', $event, value)"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :value="value.parserVars.unit_overrides.f_x"
                    dense
                    label="f_x"
                    :items="unit_override_items.force"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.unit_overrides.f_x', $event, value)"
                  />
                  <v-select
                    :value="value.parserVars.unit_overrides.f_y"
                    dense
                    label="f_y"
                    :items="unit_override_items.force"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.unit_overrides.f_y', $event, value)"
                  />
                  <v-select
                    :value="value.parserVars.unit_overrides.f_z"
                    dense
                    label="f_z"
                    :items="unit_override_items.force"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.unit_overrides.f_z', $event, value)"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    :value="value.parserVars.unit_overrides.m_x"
                    dense
                    label="m_x"
                    :items="unit_override_items.moment"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.unit_overrides.m_x', $event, value)"
                  />
                  <v-select
                    :value="value.parserVars.unit_overrides.m_y"
                    dense
                    label="m_y"
                    :items="unit_override_items.moment"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.unit_overrides.m_y', $event, value)"
                  />
                  <v-select
                    :value="value.parserVars.unit_overrides.m_z"
                    dense
                    label="m_z"
                    :items="unit_override_items.moment"
                    :rules="[formRules.required]"
                    :disabled="disabled"
                    @input="updateValue('parserVars.unit_overrides.m_z', $event, value)"
                  />
                </v-col>
              </v-row>
              <!-- Upload CSV File -->
              <p
                v-if="reuploadFile || !editLoadset"
                class="mb-2 font-weight-bold"
              >
                Add {{value.load_set_type !== 'Markov ZIP' ? 'csv' : 'zip'}} file:
              </p>
              <form
                v-if="reuploadFile || !editLoadset"
                enctype="multipart/form-data"
                ref="fileField"
              >
                <input
                  id="file"
                  type="file"
                  :name="uploadFieldName"
                  :accept="value.load_set_type !== 'Markov ZIP' ? '.csv' : '.zip'"
                  :disabled="disabled"
                  @change="filesChange($event.target.files)"
                >
              </form>
            </v-col>
            <v-col cols="6">
              <!-- Internal Notes (Optional) -->
              <notes
                :value="handleInternalNotes(value?.notes, value?.internal_notes)"
                label="Internal Notes"
                notes-info="Internal Notes are never added to reports"
                :disabled="disabled"
                @input="updateValue('internal_notes', $event, value)"
              />
              <notes
                :value="value.certification_notes"
                label="Certification Report Notes"
                notes-info="Certification Notes: Added to pg. 3 'Design Notes'"
                :disabled="disabled"
                @input="updateValue('certification_notes', $event, value)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    <v-card-actions class="pt-0">
      <div
        v-if="['Markov', 'Markov ZIP'].includes(value.load_set_type)"
        class="grey--text text-subtitle-2 font-weight-normal mt-3 ml-2"
      >
        Note: Markov file uploads may take a while.
      </div>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash"
import LibrarySelect from "@/components/LibrarySelect"
import NumericTextField from "@/components/common/NumericTextField"
import Notes from "@/components/Notes"

export default {
  name: "LoadSet",
  components: {
    LibrarySelect,
    NumericTextField,
    Notes,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    editLoadset: {
      type: Boolean,
      default: false,
    },
    original: {
      // Original DynamoDB LoadSet Metadata
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file_name: null,
      fileAdded: null,
      isValidForm: false,
      uploadFieldName: 'csv',
      uploading: false,
      saving: false,
      reuploadFile: false,
    }
  },
  mounted() {
    this.setPVOnTypeChange()
    this.checkValidForm()
  },
  watch: {
    $route() {
      this.setPVOnTypeChange()
    },
    reuploadFile() {
      this.reuploadFile ? this.setParserVars() : this.resetParserVars()
    },
    value(newVal, oldVal) {
      // Set default parserVars on load_set_type change
      if (newVal.load_set_type !== oldVal.load_set_type && newVal.load_set_type !== undefined) {
        this.setParserVars()
      }
      this.checkValidForm()
    },
  },
  computed: {
    createLoadSetRoute() {
      return this.$route.name === 'create-load-set'
    },
    loadSetTypes() {
      return [
        'Buckling',
        'Damage Equivalent',
        'Markov',
        'Markov ZIP',
        'Thickness',
      ]
    },
    windClasses() {
      return [
        { text: "I", value: 1 },
        { text: "II", value: 2 },
        { text: "III", value: 3 },
        { text: "IV", value: 4 },
      ]
    },
    fileNameProvided() {
      // Determine if filename needed
      // if so, disable save btn accordingly
      let requireFile = false
      if (this.reuploadFile) {
        requireFile = true
      }
      return requireFile ? this.file_name === null : false
    },
    unit_override_items() {
      // Default selections for unit_overrides
      return {
        elevation: ['m', 'mm', 'cm'],
        force: ['kN', 'N'],
        moment: ['kNm', 'Nm']
      }
    },
    defaultCSVItems() {
      switch(this.value.load_set_type) {
        case "Buckling":
          return [
            {
              elevation: 111.20,
              f_x: -185.19,
              f_y: -75.5795,
              f_z: -10853.7,
              m_x: 36293.5,
              m_y: -45503.2,
              m_z: -1558.42,
              windspeed: 21.0314,
              partial_load_factor: 1.35,
              load_case: "row_0"
            }
          ]
        case "Damage Equivalent":
          return [
            {
              elevation: 25.59,
              moment_delta: 49590,
            }
          ]
        case "Markov":
          return [
            {
              elevation: 132.837,
              width: 271.592,
              mean: -67960.2,
              n: 0,
            }
          ]
        case "Thickness":
          return [
            {
              elevation: 25.59,
              min_thickness: 6,
            },
          ]
        default:
          return []
      }
    },
    defaultCSVHeaders() {
      switch(this.value.load_set_type) {
        case "Buckling":
          return [
            { text: "elevation (m)", value: "elevation" },
            { text: "f_x (kN)", value: "f_x" },
            { text: "f_y (kN)", value: "f_y" },
            { text: "f_z (kN)", value: "f_z" },
            { text: "m_x (kNm)", value: "m_x" },
            { text: "m_y (kNm)", value: "m_y" },
            { text: "m_z (kNm)", value: "m_z" },
            { text: "windspeed (m/s)", value: "windspeed" },
            { text: "partial_load_factor", value: "partial_load_factor" },
            { text: "load_case", value: "load_case" },
          ]
        case "Damage Equivalent":
          return [
            { text: "elevation (m)", value: "elevation" },
            { text: "moment_delta (kNm)", value: "moment_delta" },
          ]
        case "Markov":
          return [
            { text: "elevation (m)", value: "elevation" },
            { text: "width", value: "width" },
            { text: "mean", value: "mean" },
            { text: "n", value: "n" },
          ]
        case "Thickness":
          return [
            { text: "elevation (m)", value: "elevation" },
            { text: "min_thickness (mm)", value: "min_thickness" },
          ]
        default:
          return []
      } 
    },
    markovZipWarning() {
      let oem = this.$store.state.turbines.find((item) => item.uuid === this.value.turbine)?.data?.oem
      if (oem) {
        if (this.value.load_set_type === "Markov ZIP" && oem !== "GE") {
          return true
        }
      }
      return false
    },
  },
  methods: {
    addNote() {
      if (!this.value.notes) {
        this.updateValue("notes", [''], this.value)
      } else {
        let notes = this.value.notes
        notes.push("")
        this.updateValue("notes", notes, this.value)
      }
    },
    deleteNote(idx) {
      let notes = this.value.notes
      notes.splice(idx, 1)
      this.updateValue("notes", notes, this.value)
    },
    createFormData(addFile = true) {
      var formData = new FormData()
      if (addFile) {
        formData.append("file", this.fileAdded)
      }
      let {parserVars, ...metaData} = this.value
      let newPV = {}
      switch (this.value.load_set_type) {
        case "Damage Equivalent":
          newPV.reference_cycles = parserVars.reference_cycles
          newPV.slope = parserVars.slope
          break
        case "Buckling":
          newPV.f_includes_tower_mass = parserVars.f_includes_tower_mass
          newPV.m_includes_tilt_moment = parserVars.m_includes_tilt_moment
          newPV.unit_overrides = {...parserVars.unit_overrides}
          break
        default:
          break
      }
      formData.append("parserVars", JSON.stringify(newPV))
      formData.append("metaData", JSON.stringify(metaData))
      return formData
    },
    handleUpload() {
      // Creates and uploads metadata to dynamoDB
      // and s3 loadset .twrls file
      this.uploading = true
      this.$store.dispatch("clearErrors")
      let formData = this.createFormData()
      return this.$store.dispatch("uploadLoadSetFile", formData)
        .then((response) => {
          if (typeof response !== "undefined") {
            this.$router.push({
              path: `/load-set/${response}`
            })
          }
          this.uploading = false
          this.resetFileField()
        })
    },
    filesChange(files) {
      // Changes file_name if new csv file added
      let file = files[0]
      if (!file?.name) {
        this.file_name = null
      } else {
        this.file_name = file.name
      }
      this.fileAdded = file
      this.checkValidForm()
    },
    handleSave() {
      // Saves metadata to DynamoDB if no file
      // if there is a file, it also will save
      // the file to S3
      this.saving = true
      let addFile = false
      this.$store.dispatch("clearErrors")
      if (this.reuploadFile) {
        addFile = true
      }
      let formData = this.createFormData(addFile)
      return this.$store.dispatch("updateLoadSet", {
        uuid: this.$route.params.uuid,
        obj: formData
      })
        .then((response) => {
          if (response.status === 200) {
            this.$router.go()
          }
          this.saving = false
          this.resetFileField()
        })
    },
    resetFileField() {
      // Reset file input field
      this.fileAdded = null
      if (this.$refs.fileField) {
        this.$refs.fileField.reset()
      }
    },
    handleDownloadCSV() {
      let loadType = this.value.load_set_type
      const fileName = `${loadType}.csv`.toLowerCase()
      let defaultHeaders = ""
      switch(loadType) {
        case "Buckling":
          defaultHeaders = "elevation,f_x,f_y,f_z,m_x,m_y,m_z,windspeed,partial_load_factor,load_case"
          break
        case "Damage Equivalent":
          defaultHeaders = "elevation,moment_delta"
          break
        case "Markov":
          defaultHeaders = "elevation,width,mean,n"
          break
        case "Thickness":
          defaultHeaders = "elevation,min_thickness"
          break
        default:
          break
      }
      const a = document.createElement("a")
      document.body.appendChild(a)
      a.style = "display: none"
      const blob = new Blob([defaultHeaders], {type: "octet/stream"}),
          url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    },
    setParserVars() {
      if (['Damage Equivalent', 'Buckling'].includes(this.value.load_set_type)) {
        let valueCopy = _.cloneDeep(this.value)
        let oem = this.$store.state.turbines.find((item) => item.uuid === this.value.turbine)?.data?.oem
        switch (this.value.load_set_type) {
          case "Damage Equivalent":
            // Default in towerdesign>loads>parsers>csv.py
            valueCopy.parserVars.reference_cycles = 1e7 // default
            valueCopy.parserVars.slope = 4 //default
            if (oem === "GE") {
              valueCopy.parserVars.reference_cycles = 4.74e8
            }
            break
          case "Buckling":
            valueCopy.parserVars.f_includes_tower_mass = true
            valueCopy.parserVars.m_includes_tilt_moment = false
            valueCopy.parserVars.unit_overrides.elevation = "m"
            valueCopy.parserVars.unit_overrides.f_x = "kN"
            valueCopy.parserVars.unit_overrides.f_y = "kN"
            valueCopy.parserVars.unit_overrides.f_z = "kN"
            valueCopy.parserVars.unit_overrides.m_x = "kNm"
            valueCopy.parserVars.unit_overrides.m_y = "kNm"
            valueCopy.parserVars.unit_overrides.m_z = "kNm"
            break
          default:
            break
        }
        this.$emit("input", valueCopy)
      } else {
        this.resetParserVars()
      }
    },
    resetParserVars() {
      // Resets all parserVars to default undefined state
      let valueCopy = _.cloneDeep(this.value)
      let setAll = (obj, v) => Object.keys(obj).forEach((k) => {
        if (k !== "unit_overrides") {
          return obj[k] = v
        }
      })
      setAll(valueCopy.parserVars.unit_overrides, undefined)
      setAll(valueCopy.parserVars, undefined)
      this.$emit("input", valueCopy)
    },
    checkValidForm() {
      let disabledSave = false
      if (this.editLoadset) {
        disabledSave = !this.isValidForm || this.fileNameProvided
      } else {
        disabledSave = !this.isValidForm || this.file_name === null
      }
      this.$emit("validityChange", disabledSave)
    },
    setPVOnTypeChange() {
      if (this.createLoadSetRoute && this.value.load_set_type) {
        this.setParserVars()
      }
    },
  }
}
</script>
