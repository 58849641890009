<template>
  <v-container fluid>
    <v-card
      width="100%"
      class="analyzers"
      elevation="5"
    >
      <v-card-title>
        {{ value.type }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-text-field
              :value="value.name"
              label="Name"
              :disabled="disabled"
              :rules="[formRules.required]"
              @input="updateValue('name', $event, value)"
            />
          </v-col>
          <v-col>
            <v-select
              label="Analyzer type"
              :items="analyzerSchemas.map((analyzer) => analyzer.type)"
              :value="value.type"
              :disabled="disabled"
              :rules="[formRules.required]"
              @input="updateType($event)"
            />
          </v-col>
          <v-btn
            class="align-self-center"
            :disabled="disabled"
            icon
            @click="$emit('delete')"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-row>
        <v-row class="d-flex flex-column mb-3">
          <v-col>
            <elevation-mapping
              :value="value.gov_srf_map"
              :name="'Governing SRF Map'"
              :disabled="disabled"
              :factor="'srf'"
              @input="updateValue('gov_srf_map', $event, value)"
            />
          </v-col>
        </v-row>

        <ec3-buckling-analyzer
          v-if="value.type === 'EC3BucklingAnalyzer'"
          :value="value"
          :is-global="disabled"
          @input="$emit('input', $event)"
        />
        <iec-flange-analyzer
          v-if="value.type === 'IECFlangeAnalyzer'"
          :value="value"
          :is-global="disabled"
          @input="$emit('input', $event)"
        />
        <ec3-damage-analyzer
          v-if="value.type === 'EC3DamageAnalyzer'"
          :value="value"
          :is-global="disabled"
          @input="$emit('input', $event)"
        />
        <damage-equivalent-analyzer
          v-if="value.type === 'DamageEquivalentAnalyzer'"
          :value="value"
          :is-global="disabled"
          @input="$emit('input', $event)"
        />
        <markov-equivalent-analyzer
          v-if="value.type === 'MarkovEquivalentAnalyzer'"
          :value="value"
          :is-global="disabled"
          @input="$emit('input', $event)"
        />
        <markov-analyzer
          v-if="value.type === 'MarkovAnalyzer'"
          :value="value"
          :is-global="disabled"
          @input="$emit('input', $event)"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { schemaAdjust } from "@/utils/fp";
import MarkovAnalyzer from "@/components/design/MarkovAnalyzer";
import MarkovEquivalentAnalyzer from "@/components/design/MarkovEquivalentAnalyzer";
import DamageEquivalentAnalyzer from "@/components/design/DamageEquivalentAnalyzer";
import Ec3DamageAnalyzer from "@/components/design/Ec3DamageAnalyzer";
import IecFlangeAnalyzer from "@/components/design/IecFlangeAnalyzer";
import Ec3BucklingAnalyzer from "@/components/design/Ec3BucklingAnalyzer";
import ElevationMapping from "@/components/design/ElevationMapping";

export default {
  name: "DesignAnalyzer",
  components: {
    MarkovAnalyzer,
    MarkovEquivalentAnalyzer,
    DamageEquivalentAnalyzer,
    Ec3DamageAnalyzer,
    IecFlangeAnalyzer,
    Ec3BucklingAnalyzer,
    ElevationMapping,
  },
  props: {
    value: {
      default() {
        return { name: undefined, type: undefined, gov_srf_map: [] };
      },
      type: Object,
    },
    disabled: Boolean,
  },
  computed: {
    analyzerSchemas() {
      return [
        {
          type: "DamageEquivalentAnalyzer",
          name: undefined,
          sn_slope: undefined,
          detail_category: undefined,
          knockdowns: { scf_rule: {} },
          trait_types: [],
          gov_srf_map: [],
        },
        {
          type: "EC3BucklingAnalyzer",
          trait_types: [],
          name: undefined,
          revision: undefined,
          misalignment: undefined,
          tower_mass_partial_load_factor: undefined,
          safety_factor: undefined,
          use_force_parallel_for_shear_interaction: undefined,
          allow_aggressive_squash_limit: undefined,
          quality_class: undefined,
          beta_w: undefined,
          use_hoop: undefined,
          regional_safety_factors: [],
          gov_srf_map: [],
        },
        {
          type: "EC3DamageAnalyzer",
          name: undefined,
          detail_category: { stress_concentration_rule: { factor: undefined } },
          trait_types: [],
          gov_srf_map: [],
        },
        {
          type: "IECFlangeAnalyzer",
          name: undefined,
          safety_factor: undefined,
          safety_factor_bolt: undefined,
          tower_partial_load_factor_favorable: undefined,
          misalignment: undefined,
          gov_srf_map: [],
        },
        { type: "ThicknessAnalyzer", name: undefined, gov_srf_map: [] },
        {
          type: "MarkovEquivalentAnalyzer",
          name: undefined,
          sn_curve: {},
          knockdowns: { scf_rule: {} },
          regional_safety_factors: [],
          trait_types: [],
          sources: [],
          gov_srf_map: [],
        },
        {
          type: "MarkovAnalyzer",
          name: undefined,
          sn_curve: {},
          knockdowns: { scf_rule: {} },
          trait_types: [],
          gov_srf_map: [],
        },
      ];
    },
  },
  methods: {
    updateType(newType) {
      const schema = this.analyzerSchemas.find((a) => a.type == newType);
      const newObj = { ...this.value, type: newType };
      this.$emit("input", schemaAdjust(newObj, schema));
    },
  },
};
</script>
