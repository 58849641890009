var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "400"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "tile": ""
    }
  }, [_c('v-app-bar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "text-h6 black--text pl-0"
  }, [_vm._v(" Tower Release Dates ")]), _c('v-spacer'), _c('status-change-button', {
    attrs: {
      "status-dates": _vm.statusDates,
      "tower-id": _vm.towerId
    },
    on: {
      "statusChange": function statusChange($event) {
        return _vm.$emit('statusChange', true);
      }
    }
  })], 1), _c('v-card-text', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "font-weight-bold ml-8 mb-2"
  }, [_vm._v(" Status ")]), _c('v-timeline', {
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(_vm.formattedDates, function (value, key) {
    return _c('v-timeline-item', {
      key: value + key,
      attrs: {
        "small": ""
      }
    }, [_c('div', [_c('div', {
      staticClass: "font-weight-normal"
    }, [_c('strong', [_vm._v(_vm._s(key.charAt(0).toUpperCase() + key.slice(1)))])]), _c('div', [_vm._v(_vm._s(value))])])]);
  }), 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.show = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }