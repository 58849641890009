<template>
  <v-container fluid>
    <v-navigation-drawer
      app
      clipped
      permanent
    >
      <new-tower-dialog />
      <nav-list
        :objects="$store.state.turbines"
        :sublist="$store.state.modelNumbers"
        group-by="data.oem"
      />
    </v-navigation-drawer>
    <template v-if="towerListRoute">
      <h2 class="mb-2">
        <span class="mr-2">{{ turbineName }}</span>
        <bordered-text-span
          v-if="!isOnlyTurbineRoute"
          :text="String(modelName)"
          padding="3px"
        />
        <bordered-text-span
          v-else
          text="Other"
          padding="3px"
        />
      </h2>
      <template v-if="!isOnlyTurbineRoute">
        <!-- Tower Releases Card -->
        <tower-list-data-card
          title="Tower Releases"
          :headers="towerReleaseHeaders"
          :expandedHeaders="towerListHeaders"
          :items="towerReleaseItems"
          :fetching="fetching"
          :optional-props="{
            'show-expand': true,
          }"
        />
        <!-- Current Tower Drafts Card -->
        <tower-list-data-card
          title="Tower Drafts"
          :headers="towerListHeaders"
          :items="towerDrafts"
          :showRevisionSubtitle="true"
          :removeTableRevision="true"
          :variableStatus="true"
          :fetching="fetching"
          :optional-props="{
            'hide-default-header': false,
          }"
        />
      </template>
      <template v-else>
        <!-- All Towers of Specific Turbine -->
        <tower-list-data-card
          :title="`All Towers with ${turbineName}`"
          :headers="towerListHeaders"
          :items="$store.state.towers"
          :fetching="fetching"
        />
      </template>
    </template>
  </v-container>
</template>

<script>
import NewTowerDialog from "@/components/intent/dialogs/NewTowerDialog"
import NavList from "@/components/NavList"
import TowerListDataCard from "@/components/TowerListDataCard"
import BorderedTextSpan from "@/components/common/BorderedTextSpan"

export default {
  name: "TowerList",
  components: {
    NewTowerDialog,
    NavList,
    TowerListDataCard,
    BorderedTextSpan,
  },
  data() {
    return {
      fetching: true,
      towerListHeaders: [
        { text: "", value: "data-table-expand", sortable: false },
        { text: "Tower", value: "name", sortable: false },
        { text: "", value: "status", sortable: false },
        { text: "Revision", align: "start", value: "revision", sortable: false },
        { text: "Sections", value: "num_sections", sortable: false },
        { text: "Hub Height", value: "hub_height", sortable: false },
        {
          text: "Last Modified",
          value: "lastmodified",
          sort: (a, b) => a.localeCompare(b),
        },
        { text: '', value: 'actions', sortable: false },
      ],
      towerReleaseHeaders: [
        { text: "", value: "data-table-expand", sortable: false },
        { text: "Tower", value: "name", sortable: false },
        { text: "", value: "status", sortable: false },
        { text: "Revision", align: "start", value: "revision", sort: (a,b) => a.localeCompare(b) },
        { text: "Sections", value: "num_sections", sortable: false },
        { text: "Hub Height", value: "hub_height", sortable: false },
        { text: "Release Date", value: "lastmodified", sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    towerListRoute() {
      // Checks if route is the tower list route
      return this.$route.path.slice(0,12) === '/towers/list'
    },
    towerReleaseItems() {
      // Tower list of released towers for 
      // tower-list-data-card
      return this.$store.state.towers.filter((tower) => {
        let status =  this.currentStatus(tower.status_dates).status
        return (status === "Released") || (status === "Approved")
      })
    },
    towerDrafts() {
      // Tower list of drafts for tower-list-
      // data-card
      let towers = this.$store.state.towers
      let maxRevision = Math.max(
        ...towers.map(tower => tower.revision),
        1
      )
      return towers.filter((tower) => {
        let status = this.currentStatus(tower.status_dates).status
        return (tower.revision === maxRevision)
          && (status === "Draft" || status === "Candidate")
      })
    },
    isOnlyTurbineRoute() {
      // Checks if turbine & model number is present in route
      let params = this.checkParams
      if ('turbine' in params) {
        if ('model_number' in params) {
          return false
        }
        return true
      } else { return false }
    },
    checkParams() {
      // Checks if params need to be pulled from $route.params
      // or $route.query; then returns params
      let params = ("turbine" in this.$route.params) ? this.$route.params : this.$route.query
      if (params.model_number) {
        params.model_number = Number(params.model_number)}
      return params
    },
    modelName() {
      // Model Number's Name for h2 tag
      let params = this.checkParams
      return this.$store.state.modelNumbers.find((modNum) => {
        return (modNum.model_number === params.model_number)
          && (modNum.turbine.split('#')[1] === params.turbine)
      })?.model_name
    },
    turbineName() {
      // Turbine name for h2 tag
      let params = this.checkParams
      return this.$store.state.turbines.find((turbine) => {
        return turbine.uuid === params.turbine
      })?.data?.name
    },
  },
  mounted() {
    this.$store.dispatch("fetchTurbines")
    this.fetching = true
    this.decideFetchTowers()
    this.$store.dispatch("fetchModelNumbers")
      .then(() => this.fetching = false)
  },
  watch: {
    $route (){
      // Fetch towers on route changes
      this.decideFetchTowers()
    }
  },
  methods: {
    decideFetchTowers() {
      // Fetches tower list by route params
      if (this.$route.path.slice(0,12) === '/towers/list') {
        this.fetching = true
        let params = this.checkParams
        this.$store.dispatch("fetchTowers", params)
          .then(() => this.fetching = false)
      }
    },
  },
};
</script>
