<template>
  <v-row>
    <v-col>
      <v-progress-circular
        v-if="!value.name && $route.name !== 'create-turbine'"
        indeterminate
        width="3"
        size="30"
        color="primary"
        style="width:100%"
        class="d-flex justify-center my-2"
      >
        Loading General Properties...
      </v-progress-circular>
      <v-card
        v-else
        elevation="24"
        class="my-1"
      >
        <v-card-title class="headline v-subheader justify-center">
          General Properties
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row align="end">
                <v-col cols="2">
                  <v-select
                    class="mb-1"
                    label="OEM"
                    :disabled="disabled"
                    :items="oems"
                    item-text="text"
                    item-value="value"
                    :value="value.oem"
                    dense
                    @change="updateValue('oem', $event, value)"
                  />
                </v-col>    
                <v-col cols="7">
                  <v-text-field
                    label="Name"
                    :value="value.name"
                    class="mx-auto"
                    :disabled="disabled"
                    :rules="[formRules.required]"
                    @input="updateValue('name', $event, value)"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="9">
                  <notes
                    :value="handleInternalNotes(value?.notes, value?.internal_notes)"
                    label="Internal Notes"
                    notes-info="Internal Notes are never added to reports"
                    @input="updateValue('internal_notes', $event, value)"
                    :disabled="disabled"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <numeric-text-field
                    class="mx-auto"
                    label="Power Rating"
                    units="kW"
                    :value="value.power_rating"
                    :disabled="disabled"
                    dense
                    @input="updateValue('power_rating', $event, value)"
                  />
                </v-col>
                <v-col cols="3">
                  <numeric-text-field
                    class="mx-auto"
                    label="Rotor Diameter"
                    units="m"
                    :value="value.rotor_diameter"
                    :disabled="disabled"
                    dense
                    @input="updateValue('rotor_diameter', $event, value)"
                  />
                </v-col>
                <v-col cols="3">
                  <numeric-text-field
                    class="mx-auto"
                    label="Flange top to HH"
                    units="m"
                    :value="value.flange_to_hub_height"
                    :disabled="disabled"
                    dense
                    @input="updateValue('flange_to_hub_height', $event, value)"
                  />
                </v-col>
              </v-row>
              <v-row align="end">
                <v-col cols="3">
                  <numeric-text-field
                    class="mx-auto"
                    label="Max Diameter at Blade Tip"
                    units="m"
                    :value="value.blade_tip_diameter"
                    :disabled="disabled"
                    placeholder="4"
                    dense
                    @input="updateValue('blade_tip_diameter', $event, value)"
                  />
                </v-col>
                <v-col cols="3">
                  <numeric-text-field
                    class="mx-auto"
                    label="Blade Camber Adjust"
                    units="mm"
                    :value="value.blade_camber_adjust"
                    :disabled="disabled"
                    placeholder="0"
                    dense
                    @input="updateValue('blade_camber_adjust', $event, value)"
                  />
                </v-col>
                <v-col cols="3">
                  <center-of-gravity
                    :value="value.center_of_gravity"
                    :disabled="disabled"
                    @input="updateValue('center_of_gravity', $event, value)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <numeric-text-field
                    label="Nominal Head Mass"
                    units="kg"
                    :value="value.head_mass"
                    :disabled="disabled"
                    dense
                    @input="updateValue('head_mass', $event, value)"
                  />
                </v-col>
                <v-col cols="3">
                  <numeric-text-field
                    label="Min Head Mass"
                    units="kg"
                    :value="value.min_head_mass"
                    :disabled="disabled"
                    clearable
                    :placeholder="'-5% = ' + (value.head_mass*0.95).toFixed(2).toString()"
                    dense
                    :rules="lessThan(value.head_mass)"
                    @input="updateValue('min_head_mass', $event, value)"
                  />
                </v-col>
                <v-col cols="3">
                  <numeric-text-field
                    label="Max Head Mass"
                    units="kg"
                    :value="value.max_head_mass"
                    :disabled="disabled"
                    clearable
                    :placeholder="'+5% = ' + (value.head_mass*1.05).toFixed(2).toString()"
                    dense
                    :rules="greaterThan(value.head_mass)"
                    @input="updateValue('max_head_mass', $event, value)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        elevation="24"
        class="my-2"
      >
        <v-card-title class="headline v-subheader justify-center">
          Excluded Frequencies
        </v-card-title>
        <v-card-text>
          <custom-data-table
            :headers="excludedFrequencyHeaders"
            :items="computedFrequencyRanges"
            :value-units="excludedFrequencyValueUnits"
            :data-empty-row="excludedFrequencyEmptyRow"
            :optional-props="{
              'disable-pagination': true,
              'hide-default-footer': true,
              'dense': true,
            }"
            :disabled="disabled"
            @input="updateValue('excluded_frequency_ranges', $event, value)"
          />
        </v-card-text>
      </v-card>

      <v-card
        elevation="24"
        class="my-2"
      >
        <v-card-title class="v-subheader headline justify-center">
          Tower Top Flange
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="3" class="pb-0">
              <!-- Min Thickness at Tower Top -->
              <v-checkbox
                v-model="enableMinThickness"
                :disabled="disabled"
                label="Enable Min Thickness Constraint?"
                @change="!$event ? updateValue('min_thickness', null, value) : null"
              />
            </v-col>
            <v-col md="5" lg="3" class="pb-0">
              <numeric-text-field
                v-if="enableMinThickness"
                :disabled="disabled"
                label="Min. Thickness"
                units="mm"
                :value="value.min_thickness"
                :placeholder="value.flange?.profile?.neck_thickness.toString()"
                @input="updateValue('min_thickness', $event, value)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select
                label="Anchor"
                :value="value.flange?.anchor"
                @input="updateValue('flange.anchor', $event, value)"
                :items="[{text:'Top',value:'TOP'},{text:'Base',value:'BASE'}]"
                :disabled="disabled"
              />
            </v-col>
            <v-col cols="3">
              <numeric-text-field
                label="Shell Diameter"
                units="m"
                :value="value.flange?.shell_diameter"
                :disabled="disabled"
                required
                @input="updateValue('flange.shell_diameter', $event, value)"
              />
            </v-col>
          </v-row>
          <bolt-circle
            :value="value.flange?.bolt_circle"
            :disabled="disabled"
            @input="updateValue('flange.bolt_circle', $event, value)"
          />
          <flange-profile
            :value="value.flange?.profile"
            :disabled="disabled"
            @input="updateValue('flange.profile', $event, value)"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash"
import NumericTextField from "@/components/common/NumericTextField"
import BoltCircle from "@/components/BoltCircle"
import FlangeProfile from "@/components/FlangeProfile"
import CenterOfGravity from "@/components/CenterOfGravity"
import CustomDataTable from "@/components/common/CustomDataTable"
import Notes from "@/components/Notes"

export default {
  name: "TowerTurbine",
  components: {
    NumericTextField,
    BoltCircle,
    FlangeProfile,
    CenterOfGravity,
    CustomDataTable,
    Notes,
  },
  data() {
    return {
      enableMinThickness: false,
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      if (this.value.min_thickness) {
        this.enableMinThickness = true
      }
    }
  },
  computed: {
    oems() {
      return [
        { text: "GE", value: "GE"},
        { text: "VESTAS", value: "VESTAS"},
        { text: "NORDEX", value: "NORDEX"},
        { text: "SGRE", value: "GAMESA"},
      ]
    },
    bolts() {
      return this.$store.state.bolts.map((item) => {
        return { text: item.data.name, value: item.data }
      })
    },
    mapHeaders () {
      return [
        { text: "", value: "name" },
        { text: "Offset", value: "offset" },
        { text: "Stress Concentration Factor", value: "scf" },
      ]
    },
    lessThan () {
      return (target) => {
        return [
          (v) => {
            const n = parseFloat(v)
            return isNaN(target)
              || isNaN(n)
              || n <= target
              || "Must be <= norminal head mass"
          }
        ]
      }
    },
    greaterThan () {
      return (target) => {
        return [
          (v) => {
            const n = parseFloat(v)
            return isNaN(target)
              || isNaN(n)
              || n >= target
              || "Must be >= norminal head mass"
          }
        ]
      }
    },
    computedFrequencyRanges() {
      // Frequencies are fetched from dynamodb as Strings
      // This computed converts them to Numbers for display
      if (this.value.excluded_frequency_ranges) {
        return _.map(this.value.excluded_frequency_ranges, function (freq) {
          freq.min_frequency = Number(freq.min_frequency)
          freq.max_frequency = Number(freq.max_frequency)
          return freq
        })
      } else {
        return []
      }
    },
    excludedFrequencyHeaders() {
      return [
        { text: "", value: "add_row", sortable: false, width: "1px" },
        { text: "Name", value: "name" },
        { text: "Min Frequency (Hz)", value: "min_frequency" },
        { text: "Max Frequency (Hz)", value: "max_frequency" },
        { text: "Warn Only", value: "warn_only" },
        { text: "", value: "action", sortable: false },
      ]
    },
    excludedFrequencyValueUnits() {
      return [
        { value: "min_frequency" },
        { value: "max_frequency" },
      ]
    },
    excludedFrequencyEmptyRow() {
      return {
        name: "",
        min_frequency: null,
        max_frequency: null,
        warn_only: false,
      }
    },
  },
}
</script>

<style scoped></style>
