<template>
  <div>
    <v-container>
      <v-card elevation="4" max-width="1200px" style="margin:0 auto;">
        <v-card-title class="pb-2 mb-2">
          Load Sets
        </v-card-title>
        <v-card-subtitle v-if="currentTurbineSelected">
          Filtered by Turbine: {{ turbineName }}
        </v-card-subtitle>
        <v-card-subtitle v-else>
          Filtered by Other
        </v-card-subtitle>
        <v-data-table
          :headers="headers"
          :items="filteredLoadSetsByTurbine"
          sort-by="lastmodified"
          sort-desc
          disable-pagination
          hide-default-footer
          item-key="uuid"
          :loading="fetching || deletingLoadset"
        >
          <template #[`item.name`]="{ item }">
            <router-link
              :to="{
                name: 'edit-load-set',
                params: { uuid: item.uuid },
              }"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template #[`item.lastmodified`]="{ item }">
            <time-distance :time-str="item.lastmodified" />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              icon
              @click="deleteLoadset(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash"
import TimeDistance from "@/components/common/TimeDistance"

export default {
  name: "LoadSetList",
  components: {
    TimeDistance,
  },
  data() {
    return {
      loadSets: [],
      fetching: false,
      deletingLoadset: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Buckling", value: "buckling" },
        { text: "Damage Equivalent", value: "damage_equivalent" },
        { text: "Markov", value: "markov" },
        { text: "Thickness", value: "thickness" },
        {
          text: "Last Modified",
          value: "lastmodified",
          sort: (a, b) => a.localeCompare(b),
        },
        { text: "", value: "actions" },
      ],
    }
  },
  mounted() {
    this.loadLoadSets()
    this.$store.dispatch("fetchTurbines")
  },
  computed: {
    currentTurbineSelected() {
      return this.$route.query?.sortBy ?? null
    },
    filteredLoadSetsByTurbine() {
      if (this.currentTurbineSelected) {
        return this.loadSets.filter((loadSet) => {
          return loadSet.turbine === this.currentTurbineSelected
        })
      } else {
        return this.loadSets.filter((loadSet) => {
          return loadSet.turbine === undefined || loadSet.turbine === null
        })
      }
    },
    turbineName() {
      // Given turbine uuid, return name
      if (!_.isEmpty(this.$store.state.turbines)) {
        let turbine = this.$store.state.turbines.find((turbine) => {
          return turbine.uuid === this.currentTurbineSelected
        })
        return turbine.data.name
      } else {
        return 'Fetching name...'
      }
    },
  },
  methods: {
    loadLoadSets() {
      this.$store.dispatch("clearErrors")
      // Order LoadSets from dispatch response to
      // comply with v-data-table items format
      this.loadSets = []
      this.fetching = true
      this.$store.dispatch("fetchLoadSets")
        .then((response) => {
          for (let load of response) {
            this.loadSets.push({
              name: load.data?.name,
              uuid: load?.uuid,
              buckling: load.data.load_case_counts?.BucklingLoadCase,
              damage_equivalent: load.data.load_case_counts?.DamageEquivalentLoadCase,
              markov: load.data.load_case_counts?.MarkovLoadCase,
              thickness: load.data.load_case_counts?.ThicknessLoadCase,
              lastmodified: load?.lastmodified,
              turbine: load.data?.turbine
            })
          }
        })
        .finally(() => {
          this.fetching = false
        })
    },
    popDeletedLoadset(uuid) {
      this.loadSets = this.loadSets.filter(loadset => loadset.uuid !== uuid)
    },
    deleteLoadset(item) {
      this.$confirm("Do you really want to delete this LoadSet?").then((res) => {
        if (res) {
          this.deletingLoadset = true
          this.$store.dispatch("deleteLoadSet", item.uuid)
            .then(() =>
              this.popDeletedLoadset(item.uuid)
            )
            .finally(() => this.deletingLoadset = false)
        }
      })
    },
  },
}
</script>

<style scoped>
  .v-data-table >>> tr:hover {
    background: transparent !important;
  }
  </style>
