<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <numeric-text-field
            label="Consequence of failure safety factor"
            :value="value.consequence_of_failure_safety_factor"
            :disabled="disabled"
            @input="updateValue('consequence_of_failure_safety_factor', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Material safety factor"
            :value="value.material_safety_factor"
            :disabled="disabled"
            @input="updateValue('material_safety_factor', $event, value)"
          />
        </v-col>
        <v-col>
          <v-checkbox
            label="Has size effect"
            :input-value="value.has_size_effect"
            :disabled="disabled"
            @change="updateValue('has_size_effect', $event, value)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <numeric-text-field
            label="Slope"
            :value="value.slope"
            :disabled="disabled"
            @input="updateValue('slope', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Slope intercept"
            :value="value.slope_intercept"
            :disabled="disabled"
            @input="updateValue('slope_intercept', $event, value)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            label="Stress concentration type"
            :items="stressConcentrationTypes"
            :value="value.stress_concentration_rule.type"
            :disabled="disabled"
            @input="updateValue('stress_concentration_rule.type', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Stress concentration factor"
            :value="value.stress_concentration_rule.factor"
            :disabled="disabled"
            @input="updateValue('stress_concentration_rule.factor', $event, value)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "DetailCategory",
  components: {
    NumericTextField,
  },
  props: ["value", "disabled"],
  computed: {
    stressConcentrationTypes () {
        return [
          "ConstantStressConcentrationRule",
          "TaperChangeStressConcentrationRule",
        ]
    }
  },
}
</script>
