<template>
  <v-row>
    <v-col cols="4">
      <v-select
        label="Trait type"
        :value="value.type"
        :items="traitSchemas.map((trait) => trait.type)"
        @input="updateType(traitSchemas, $event)"
        :disabled="disabled"
      />
    </v-col>
    <v-col
      v-if="value.type === 'BracketWeld'"
      cols="4"
    >
      <v-text-field
        label="Name"
        :value="value.name"
        :rules="[formRules.required]"
        @input="updateValue('name', $event, value)"
        :disabled="disabled"
      />
    </v-col>
    <v-col
      v-if="value.type === 'SubmarineDoor'"
      cols="4"
    >
      <numeric-text-field
        label="Width"
        :value="value.width"
        units="m"
        required
        @input="updateValue('width', $event, value)"
        :disabled="disabled"
      />
    </v-col>
    <v-col
      v-if="value.type === 'SubmarineDoor'"
      cols="4"
    >
      <numeric-text-field
        label="Height"
        :value="value.height"
        units="m"
        required
        @input="updateValue('height', $event, value)"
        :disabled="disabled"
      />
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "IntentTrait",
  components: {
    NumericTextField,
  },
  props: ["value", "disabled"],
  computed: {
    traitSchemas () {
      return [
        { type: "FlameCutEdge" },
        { type: "BracketWeld", name: null },
        { type: "ThroughHole" },
        { type: "Stiffener" },
        { type: "SubmarineDoor", width: null, height: null },
        { type: "GroundStud" },
      ]
    },
  },
}
</script>
