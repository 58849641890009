var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "my-2",
    attrs: {
      "elevation": "24"
    }
  }, [_c('v-card-title', {
    staticClass: "headline v-subheader justify-center"
  }, [_vm._v(" Material ")]), _c('v-card-text', [_c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "value": _vm.value.name,
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.required]
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('name', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Tensile Strength",
      "value": _vm.value.tensile_strength,
      "units": "Mpa",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('tensile_strength', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Yield Strength",
      "value": _vm.value.yield_strength,
      "units": "Mpa",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('yield_strength', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Density",
      "value": _vm.value.density,
      "units": "kg/m^3",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('density', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Elastic Modulus",
      "value": _vm.value.elastic_modulus,
      "units": "Gpa",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('elastic_modulus', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Poisson's Ratio",
      "value": _vm.value.poissons_ratio,
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('poissons_ratio', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    staticClass: "mx-auto",
    attrs: {
      "label": "Shear Modulus",
      "value": _vm.value.shear_modulus,
      "units": "Gpa",
      "disabled": _vm.disabled,
      "required": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('shear_modulus', $event, _vm.value);
      }
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }