<template>
  <v-dialog v-model="show" max-width="400">
    <v-card class="mx-auto" tile>
      <v-app-bar flat>
        <v-toolbar-title class="text-h6 black--text pl-0">
          Tower Release Dates
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <status-change-button
          :status-dates="statusDates"
          :tower-id="towerId"
          @statusChange="$emit('statusChange', true)"
        />
      </v-app-bar>
      <v-card-text class="mt-5">
        <div class="font-weight-bold ml-8 mb-2">
          Status
        </div>

        <v-timeline
          align-top
          dense
        >
          <v-timeline-item
            v-for="(value, key) in formattedDates"
            :key="value + key"
            small
          >
            <div>
              <div class="font-weight-normal">
                <strong>{{ key.charAt(0).toUpperCase() + key.slice(1) }}</strong>
              </div>
              <div>{{ value }}</div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text color="primary" @click.stop="show = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment"
import StatusChangeButton from "@/components/StatusChangeButton"
export default {
  name: "StatusModal",
  components: {
    StatusChangeButton
  },
  props: ["value", "statusDates", "towerId"],
  watch: {
    statusDates() {
      if (this.status.status == "Draft") {
        this.show = false
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    status() {
      return this.currentStatus(this.statusDates)
    },
    formattedDates() {
      let dates = this.statusDates
      if (this.statusDates) {
        for (let key in dates) {
          let momentTime = moment(dates[key]).format("dddd, MMMM Do YYYY")
          dates[key] = momentTime
        }
        return dates
      } else {
        return "Tower Status is Draft"
      }
    },
  },
}
</script>
