var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "clipped": "",
      "permanent": ""
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "slot": "prepend",
      "dark": "",
      "color": "primary",
      "to": {
        name: 'create-' + _vm.route
      }
    },
    slot: "prepend"
  }, [_vm._v(" New " + _vm._s(_vm.displayName) + " ")]), _c('v-subheader', [_vm._v("Local")]), _c('nav-list', {
    attrs: {
      "route": 'edit-' + _vm.route,
      "objects": _vm.localObjects,
      "group-by": _vm.groupBy,
      "add-other-option": _vm.loadSetRoute ? true : false
    }
  }), _c('v-subheader', [_vm._v("Global")]), _c('nav-list', {
    attrs: {
      "route": 'edit-' + _vm.route,
      "objects": _vm.globalObjects,
      "group-by": _vm.groupBy
    }
  })], 1), _vm.isEditing || _vm.isCreating || _vm.loadSetRoute ? [_vm.isEditing || _vm.isCreating ? _c('v-toolbar', {
    staticClass: "mt-2",
    style: {
      'position': 'sticky',
      'z-index': 20,
      'top': _vm.toolbarBreakpoint
    },
    attrs: {
      "elevation": "3"
    }
  }, [_vm.isLocked ? _c('v-alert', {
    staticClass: "ml-2",
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "dense": "",
      "type": "info",
      "icon": "mdi-lock"
    }
  }, [_vm._v(" Item Locked - Used in Locked Tower(s) ")]) : _vm.isGlobal && !_vm.isLocked ? _c('v-alert', {
    staticClass: "ml-2",
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "dense": "",
      "type": "info",
      "icon": "mdi-earth"
    }
  }, [_vm._v(" Item Locked - 'Global' Org. ID ")]) : _c('v-btn', {
    attrs: {
      "disabled": _vm.unedited || _vm.isGlobal || !_vm.isValid || _vm.saving || _vm.componentInvalid || _vm.isLocked,
      "loading": _vm.saving,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.saveObject();
      }
    }
  }, [_vm._v(" Save ")]), _c('v-spacer'), _vm.original.tower_references ? _c('usage-modal', {
    attrs: {
      "references": _vm.original.tower_references,
      "num-refs": _vm.numRefs,
      "object-name": _vm.original.data.name
    },
    model: {
      value: _vm.showUsageModal,
      callback: function callback($$v) {
        _vm.showUsageModal = $$v;
      },
      expression: "showUsageModal"
    }
  }) : _vm._e(), _vm.original.history ? _c('history-modal', {
    attrs: {
      "history": _vm.original.history,
      "object-name": _vm.original.data.name
    },
    model: {
      value: _vm.showHistoryModal,
      callback: function callback($$v) {
        _vm.showHistoryModal = $$v;
      },
      expression: "showHistoryModal"
    }
  }) : _vm._e(), _c('v-menu', {
    attrs: {
      "open-on-hover": "",
      "bottom": "",
      "rounded": "lg",
      "offset-y": true
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "large": _vm.$vuetify.breakpoint.mdAndUp
          }
        }, [_vm._v(" mdi-menu ")])], 1)];
      }
    }], null, false, 1769170323)
  }, [_c('v-list', [_c('v-list-item-group', {
    model: {
      value: _vm.selection,
      callback: function callback($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  }, [_c('v-list-item', {
    attrs: {
      "disabled": _vm.isCreating || _vm.isLoadSetRoute
    },
    on: {
      "click": function click($event) {
        return _vm.copyObject(_vm.$route.params.uuid);
      }
    }
  }, [_c('v-list-item-icon', [!_vm.copying ? _c('v-icon', [_vm._v("mdi-content-copy")]) : _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "width": 3,
      "size": 25,
      "color": "primary"
    }
  })], 1), _c('v-list-item-content', [!_vm.copying ? _c('v-list-item-title', [_vm._v("Copy")]) : _c('v-list-item-title', [_vm._v("Copying...")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "disabled": _vm.isGlobal || _vm.isCreating || _vm.isLoadSetRoute
    },
    on: {
      "click": function click($event) {
        return _vm.archiveObject(_vm.$route.params.uuid);
      }
    }
  }, [_c('v-list-item-icon', [!_vm.archiving ? _c('v-icon', [_vm._v("mdi-archive-lock-outline")]) : _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "width": 3,
      "size": 25,
      "color": "primary"
    }
  })], 1), _c('v-list-item-content', [!_vm.archiving ? _c('v-list-item-title', [_vm._v("Archive")]) : _c('v-list-item-title', [_vm._v("Archiving...")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "disabled": _vm.isGlobal || _vm.isCreating || _vm.numRefs !== 0 || _vm.isLocked
    },
    on: {
      "click": function click($event) {
        return _vm.deleteObject(_vm.$route.params.uuid);
      }
    }
  }, [_c('v-list-item-icon', [!_vm.deleting ? _c('v-icon', [_vm._v("mdi-delete-outline")]) : _c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "width": 3,
      "size": 25,
      "color": "primary"
    }
  })], 1), _c('v-list-item-content', [!_vm.deleting ? _c('v-list-item-title', [_vm._v("Delete")]) : _c('v-list-item-title', [_vm._v("Deleting...")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "disabled": _vm.numRefs === 0 || _vm.isCreating
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.showUsageModal = true;
      }
    }
  }, [_c('v-list-item-icon', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-wind-turbine")])], 1), _c('v-list-item-content', [_c('div', {
    staticStyle: {
      "display": "flex",
      "width": "80px",
      "height": "40px",
      "align-items": "center"
    }
  }, [_c('v-badge', {
    attrs: {
      "bordered": "",
      "color": "primary",
      "content": _vm.numRefs,
      "value": _vm.numRefs
    }
  }, [_vm._v(" Usages ")])], 1)])], 1), _c('v-list-item', {
    attrs: {
      "disabled": !_vm.original.history
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.showHistoryModal = true;
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-history")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("History")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "rounded": "",
      "color": "primary",
      "width": "100%"
    }
  }) : _vm._e(), _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_vm.$route.name === 'list-load-set' ? _c(_vm.list, {
    tag: "component"
  }) : _vm.isCreating || _vm.localObject ? _c(_vm.edit, {
    ref: "crudChildRef",
    tag: "component",
    attrs: {
      "disabled": _vm.isGlobal || _vm.isLocked,
      "extraData": _vm.extraData,
      "warnings": _vm.warnings
    },
    on: {
      "validityChange": function validityChange($event) {
        _vm.componentInvalid = $event;
      }
    },
    model: {
      value: _vm.localObject,
      callback: function callback($$v) {
        _vm.localObject = $$v;
      },
      expression: "localObject"
    }
  }) : _vm._e()], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }