<template>
  <custom-data-table
    :title="name || 'Elevation mapping'"
    :headers="elevationHeaders"
    :items="value"
    :value-units="elevationValueUnits"
    :data-empty-row="dataEmptyRow"
    :ordered-row-items="['elevation', 'srf']"
    :optional-props="{
      'disabled-pagination': true,
      'hide-default-footer': true,
      'dense': true,
      'disable-sort': true,
      class: 'mb-4'
    }"
    showAddCsvBtn
    :disabled="disabled"
    @input="$emit('input', $event)"
  />
</template>

<script>
import CustomDataTable from "@/components/common/CustomDataTable"
import { dropIndex } from "@/utils/fp";

export default {
  name: "ElevationMapping",
  components: {
    CustomDataTable,
  },
  props: {
    value: {
      default() {
        return [];
      },
      type: Array,
    },
    disabled: Boolean,
    name: { type: String, default: undefined },
    factor: { type: String, default: undefined },
  },
  computed: {
    elevationHeaders() {
      return [
        { text: "", value: "add_row", sortable: false, width: "1px" },
        { text: "Elevation (m)", value: "elevation" },
        { text: "Factor", value: this.getFactor.toString() },
        { text: "", value: "action" },
      ]
    },
    elevationValueUnits() {
      return [
        { value: "elevation", units: "m" },
        { value: this.getFactor.toString() },
      ]
    },
    getFactor() {
      return this.factor || "scf";
    },
    dataEmptyRow() {
      return {
        elevation: null,
        [this.getFactor.toString()]: null,
      }
    },
  },
  methods: {
    deleteElevation(idx) {
      this.$emit("input", dropIndex(this.value, idx));
    },
  },
};
</script>
