<template>
  <v-card
    width="100%"
    elevation="3"
  >
    <v-card-title class="subtitle-1">
      Fatigue Knockdowns
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          sm="12"
          md="4"
        >
          <numeric-text-field
            label="Material safety factor"
            :value="value.material_safety_factor"
            :disabled="isGlobal"
            @input="updateValue('material_safety_factor', $event, value)"
          />
        </v-col>
        <v-col
          sm="12"
          md="4"
        >
          <numeric-text-field
            label="Consequence of failure"
            :value="value.consequence_of_failure_safety_factor"
            :disabled="isGlobal"
            @input="updateValue('consequence_of_failure_safety_factor', $event, value)"
          />
        </v-col>
        <v-col
          sm="12"
          md="4"
        >
          <v-checkbox
            :input-value="value.has_size_effect"
            label="Has size effect"
            :disabled="isGlobal"
            @change="updateValue('has_size_effect', $event, value)"
          />
        </v-col>
      </v-row>
      <scf-rule
        :value="value.scf_rule"
        :disabled="isGlobal"
        @input="updateValue('scf_rule', $event, value)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"
import ScfRule from "@/components/design/ScfRule"

export default {
  name: "DesignKnockdowns",
  components: {
    NumericTextField,
    ScfRule,
  },
  props: ["value", "isGlobal"],
}
</script>
