var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "width": "100%",
      "elevation": "3"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v(" Fatigue Knockdowns ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Material safety factor",
      "value": _vm.value.material_safety_factor,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('material_safety_factor', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Consequence of failure",
      "value": _vm.value.consequence_of_failure_safety_factor,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('consequence_of_failure_safety_factor', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "input-value": _vm.value.has_size_effect,
      "label": "Has size effect",
      "disabled": _vm.isGlobal
    },
    on: {
      "change": function change($event) {
        return _vm.updateValue('has_size_effect', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('scf-rule', {
    attrs: {
      "value": _vm.value.scf_rule,
      "disabled": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('scf_rule', $event, _vm.value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }