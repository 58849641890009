import axios from "axios";
import { Auth } from "aws-amplify";

const withAuth = (method, url, data, param_args={}, kwargs={}) => {
    return Auth.currentSession().then((session) => {
        var token = session.getIdToken().getJwtToken();
        return axios({
            method,
            url: process.env.VUE_APP_API_HOST + url,
            data,
            params: param_args,
            headers: { Authorization: `Bearer ${token}` },
            ...kwargs,
        });
    });
};

export default {
    get: (url, params, kwargs) => withAuth("get", url, {}, params, kwargs),
    post: (url, obj) => withAuth("post", url, obj),
    put: (url, obj) => withAuth("put", url, obj),
    delete: (url) => withAuth("delete", url),
};
