<template>
  <v-progress-circular
    v-if="!value.name && $route.name !== 'create-design-basis'"
    indeterminate
    width="3"
    size="30"
    color="primary"
    style="width:100%"
    class="d-flex justify-center my-2"
  >
    Loading General Properties...
  </v-progress-circular>
  <v-card v-else class="ma-2">
    <v-alert
      v-if="isEmptyAnalyzers"
      text
      type="info"
      color="info"
    >
      You must have at least one analyzer
    </v-alert>
    <v-card>
      <v-card-title class="headline v-subheader justify-center">
        General Properties
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Name"
          :value="value.name"
          class="mx-auto"
          :disabled="disabled"
          :rules="[formRules.required]"
          @input="updateValue('name', $event, value)"
        />
        <notes
          :value="handleInternalNotes(value?.notes, value?.internal_notes)"
          label="Internal Notes"
          notes-info="Internal Notes are never added to reports"
          @input="updateValue('internal_notes', $event, value)"
          :disabled="disabled"
        />
        <notes
          :value="value.blueprint_notes"
          label="Blueprint Report Notes"
          notes-info="Default Blueprint Design Basis Notes for pg. 1 'Tower Design Basis'"
          :disabled="disabled"
          @input="updateValue('blueprint_notes', $event, value)"
        />
        <notes
          :value="value.certification_notes"
          label="Certification Report Notes"
          notes-info="Default Certification Report Notes for pg. 3 'Design Notes'"
          :disabled="disabled"
          @input="updateValue('certification_notes', $event, value)"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="headline v-subheader justify-center">
        Analyzers
      </v-card-title>
      <v-card-actions class="pt-0">
        <v-btn
          rounded
          :disabled="disabled"
          @click="addAnalyzer()"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Add Analyzer
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-row>
          <analyzer
            v-for="(analyzer, idx) in value.analyzers"
            :key="idx"
            :value="analyzer"
            :disabled="disabled"
            @input="updateValue(['analyzers', idx], $event, value)"
            @delete="deleteAnalyzer(idx)"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import _ from "lodash/fp"
import { dropIndex } from "@/utils/fp";
import Analyzer from "@/components/design/Analyzer";
import Notes from "@/components/Notes"

export default {
  name: "DesignBasis",
  components: {
    Analyzer,
    Notes,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEmptyAnalyzers() {
      return _.isEmpty(this.value.analyzers)
    },
  },
  watch: {
    isEmptyAnalyzers() {
      this.$emit("validityChange", this.isEmptyAnalyzers)
    },
  },
  methods: {
    addNote() {
      this.$emit("input", {
        ...this.value,
        notes: (this.value.notes??[]).concat(""),
      });
    },
    deleteNote(idx) {
      this.$emit("input", {
        ...this.value,
        notes: dropIndex(this.value.notes, idx),
      });
    },
    addAnalyzer() {
      this.$emit("input", {
        ...this.value,
        analyzers: (this.value.analyzers??[]).concat({}),
      });
    },
    deleteAnalyzer(idx) {
      this.$emit("input", {
        ...this.value,
        analyzers: dropIndex(this.value.analyzers, idx),
      });
    },
  },
};
</script>
