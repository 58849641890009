<template>
  <v-row>
    <v-col>
      <v-row>
        <detail-category
          :value="value.detail_category"
          :disabled="isGlobal"
          @input="updateValue('detail_category', $event, value)"
        />
      </v-row>
      <v-row>
        <traits
          :value="value.trait_types"
          :is-global="isGlobal"
          @input="updateValue('trait_types', $event, value)"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Traits from "@/components/design/Traits"
import DetailCategory from "@/components/design/DetailCategory"

export default {
  name: "Ec3DamageAnalyzer",
  components: {
    DetailCategory,
    Traits,
  },
  props: ["value", "isGlobal"],
}
</script>
