<template>
  <v-row>
    <v-col cols="4">
      <v-select
        label="Radial pattern"
        :items="patternTypes"
        :value="value.type"
        @input="updateType(patternSchemas, $event)"
        :disabled="disabled"
      />
    </v-col>
    <v-col cols="4">
      <comma-separated-list
        label="Orientations"
        units="deg"
        :value="value.orientations"
        @input="updateValue('orientations', $event, value)"
        :disabled="disabled"
      />
    </v-col>
    <v-col
      v-if="value.type === 'ConstWidthPattern'"
      cols="4"
    >
      <numeric-text-field
        label="Width"
        units="m"
        :value="value.width"
        @input="updateValue('width', $event, value)"
        :disabled="disabled"
      />
    </v-col>
  </v-row>
</template>

<script>
import CommaSeparatedList from "@/components/common/CommaSeparatedList"
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "RadialPattern",
  components: {
    CommaSeparatedList,
    NumericTextField,
  },
  props: ["value", "disabled"],
  computed: {
    patternSchemas () {
      return [
        { type: "AnglePattern", orientations: [] },
        { type: "ConstWidthPattern", width: null, orientations: [] },
      ]
    },
    patternTypes () {
      return [
        { text: "Angle pattern", value: "AnglePattern" },
        { text: "Constant width pattern", value: "ConstWidthPattern" },
      ]
    },
  },
}
</script>
