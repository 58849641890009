var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Bolt",
      "items": _vm.boltNames,
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.bolt,
      callback: function callback($$v) {
        _vm.bolt = $$v;
      },
      expression: "bolt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Bolt circle count",
      "value": _vm.value.count,
      "disabled": _vm.disabled,
      "rules": [_vm.formRules.positiveWholeNumber]
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('count', $event, _vm.value);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('numeric-text-field', {
    attrs: {
      "label": "Bolt circle offset",
      "units": "mm",
      "value": _vm.value.offset,
      "disabled": _vm.disabled,
      "placeholder": _vm.defaultOffset,
      "clearable": ""
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('offset', $event, _vm.value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }