<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="7" class="align-center">
            <v-col>
              {{ $route.query.report_type }}
              <p class="text-subtitle-2 text--disabled">{{ csvFilename }}</p>
            </v-col>
          </v-col>
          <v-col cols="5" class="align-self-end">
            <v-text-field
              v-if="items"
              v-model="search"
              dense
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-progress-linear
        v-if="items === null"
        indeterminate
        color="primary"
        style="width:100%"
      />
      <v-alert
        v-else-if="items === undefined"
        type="info"
      >
        Click button below to download file
      </v-alert>
      <v-data-table
        v-else-if="items.length !== 0"
        :items="items"
        :headers="computedHeaders"
        :search="search"
        :items-per-page="5"
        no-data-text="No items were retrieved."
        no-results-text="No results were found."
        :loading="!items"
        loading-text="Loading CSV Data... Please wait"
        class="elevation-1 px-4"
        disable-pagination
      />
      <v-alert v-else-if="items.length === 0" type="info">
        There are no values to parse in this CSV file.
      </v-alert>
    </v-card>
    <v-btn
      color="primary"
      dark
      absolute
      bottom
      right
      fab
      style="position:absolute"
      :href="$route.query.url"
      target="_blank"
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        csvFilename: this.$route.params.filename + '.csv',
        items: null,
        search: '',
      }
    },
    computed: {
      computedHeaders() {
        let headers = []
        if (this.items) {
          let itemKeys = Object.keys(this.items[0])
          for (let key of itemKeys) {
            headers.push({
              text: key,
              value: key
            })
          }
          return headers
        } else {
          return []
        }
      },
    },
    mounted() {
      this.$store.dispatch("getCsvAsJson", this.csvFilename)
        .then((response) => {
          this.items = response
        })
    },
  }
</script>