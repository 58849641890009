<template>
  <v-textarea
    v-model="displayText"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: "TextareaList",
  props: ["value"],
  computed: {
    displayText: {
      get () {
        return (this.value??[]).join("\n")
      },
      set (v) {
        this.$emit("input", (v??"").split("\n"))
      },
    },
  }
}
</script>
