<template>
  <v-card 
    width="100%"
    elevation="3"
  >
    <v-card-title
      class="subtitle-1"
      style="padding-bottom: 0"
    >
      Traits
    </v-card-title>
    <v-card-actions>
      <v-btn
        rounded
        small
        :disabled="isGlobal"
        @click="addTrait()"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Add Trait
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-row
        v-for="(trait, idx) in value"
        :key="idx"
        dense
      >
        <v-col
          md="11"
          class="p-0 m-0"
        >
          <v-select
            label="Trait"
            :items="traits"
            :value="value[idx]"
            :disabled="isGlobal"
            :rules="[formRules.required]"
            width="30%"
            @input="updateValue([idx], $event, value)"
          />
        </v-col>
        <v-col
          md="1"
          cols="auto"
          class="align-self-center"
        >
          <v-btn
            icon
            :disabled="isGlobal"
            class="overflow-auto"
            @click="deleteTrait(idx)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {dropIndex} from "@/utils/fp"

export default {
  name: "DesignTraits",
  props: ["value", "isGlobal"],
  computed: {
    traits () {
      return [
        "CornerWeld",
        "CircWeld",
        "FlameCutEdge",
        "BracketWeld",
        "ThroughHole",
        "Stiffener",
        "SubmarineDoor",
        "GroundStud",
        "FlangeCircWeld",
      ]
    },
  },
  methods: {
    addTrait() {
      this.$emit("input", this.value.concat(null))
    },
    deleteTrait (idx) {
      this.$emit("input", dropIndex(this.value,idx))
    },
  },
}
</script>
