var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_vm._v(_vm._s(_vm.datetimeToDistance(_vm.timeStr)))])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(new Date(_vm.timeStr).toLocaleDateString(undefined, {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  })))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }