<template>
  <v-row no-gutters>
    <v-col>
      <v-card
        elevation="24"
        class="my-2"
      >
        <v-card-title class="headline v-subheader justify-center">
          Bolt
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Name"
                :value="value.name"
                class="mx-auto"
                :disabled="disabled"
                :rules="[formRules.required]"
                @input="updateValue('name', $event, value)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <numeric-text-field
                label="Head Height"
                :value="value.head_height"
                class="mx-auto"
                units="mm"
                :disabled="disabled"
                required
                @input="updateValue('head_height', $event, value)"
              />
            </v-col>
            <v-col cols="3">
              <numeric-text-field
                label="Hole Diameter"
                :value="value.hole_diameter"
                class="mx-auto"
                units="mm"
                :disabled="disabled"
                required
                @input="updateValue('hole_diameter', $event, value)"
              />
            </v-col>
            <v-col cols="3">
              <numeric-text-field
                label="Major Diameter"
                :value="value.major_diameter"
                class="mx-auto"
                units="mm"
                :disabled="disabled"
                required
                @input="updateValue('major_diameter', $event, value)"
              />
            </v-col>
            <v-col cols="3">
              <numeric-text-field
                label="Pitch"
                :value="value.pitch"
                class="mx-auto"
                units="mm"
                :disabled="disabled"
                required
                @input="updateValue('pitch', $event, value)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        elevation="24"
        class="my-2"
      >
        <v-card-title class="headline v-subheader justify-center">
          Hex Nut
        </v-card-title>
        <v-card-text>
          <v-col>
            <v-row>
              <v-col cols="3">
                <numeric-text-field
                  label="Max Diameter"
                  :value="value.nut?.max_diameter"
                  class="mx-auto"
                  units="mm"
                  :disabled="disabled"
                  required
                  @input="updateValue('nut.max_diameter', $event, value)"
                />
              </v-col>
              <v-col cols="3">
                <numeric-text-field
                  label="Min Diameter"
                  :value="value.nut?.min_diameter"
                  class="mx-auto"
                  units="mm"
                  :disabled="disabled"
                  required
                  @input="updateValue('nut.min_diameter', $event, value)"
                />
              </v-col>
              <v-col cols="3">
                <numeric-text-field
                  label="Height"
                  :value="value.nut?.height"
                  class="mx-auto"
                  units="mm"
                  :disabled="disabled"
                  required
                  @input="updateValue('nut.height', $event, value)"
                />
              </v-col>
              <v-col cols="3">
                <numeric-text-field
                  label="Socket Outer Diameter"
                  :value="value.nut?.socket_outer_diameter"
                  class="mx-auto"
                  units="mm"
                  :disabled="disabled"
                  required
                  @input="updateValue('nut.socket_outer_diameter', $event, value)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
      <v-card
        elevation="24"
        class="my-2"
      >
        <v-card-title class="headline v-subheader justify-center">
          Washer
        </v-card-title>
        <v-card-text>
          <v-col>
            <v-row>
              <v-col cols="3">
                <numeric-text-field
                  label="Inner Diameter"
                  :value="value.washer?.inner_diameter"
                  class="mx-auto"
                  units="mm"
                  :disabled="disabled"
                  required
                  @input="updateValue('washer.inner_diameter', $event, value)"
                />
              </v-col>
              <v-col cols="3">
                <numeric-text-field
                  label="Outer Diameter"
                  :value="value.washer?.outer_diameter"
                  class="mx-auto"
                  units="mm"
                  :disabled="disabled"
                  required
                  @input="updateValue('washer.outer_diameter', $event, value)"
                />
              </v-col>
              <v-col cols="3">
                <numeric-text-field
                  label="Thickness"
                  :value="value.washer?.thickness"
                  class="mx-auto"
                  units="mm"
                  :disabled="disabled"
                  required
                  @input="updateValue('washer.thickness', $event, value)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
      <material
        :value="value.grade"
        :disabled="disabled"
        @input="updateValue('grade', $event, value)"
      />
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"
import Material from "@/components/Material"

export default {
  name: "EditBolt",
  components: {
    NumericTextField,
    Material,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
