<template>
  <v-row>
    <v-col>
      <sn-curve
        :value="value.sn_curve"
        :is-global="isGlobal"
        @input="updateValue('sn_curve', $event, value)"
      />
      <knockdowns
        :value="value.knockdowns"
        :is-global="isGlobal"
        @input="updateValue('knockdowns', $event, value)"
      />
      <traits
        :value="value.trait_types"
        :is-global="isGlobal"
        @input="updateValue('trait_types', $event, value)"
      />
    </v-col>
  </v-row>
</template>

<script>
import Traits from "@/components/design/Traits";
import Knockdowns from "@/components/design/Knockdowns";
import SnCurve from "@/components/design/SnCurve";

export default {
  name: "MarkovAnalyzer",
  components: {
    Traits,
    Knockdowns,
    SnCurve,
  },
  props: ["value", "isGlobal"],
}
</script>
