// ArrayOf Any -> NaturalNumber -> ArrayOfAny
// Accepts an array and returns a new array without the
// element at the given index. Original argument is left
// as is, not mutated. Remaining elements in the array
// share references with the original array.
exports.dropIndex = (array, idx) => {
    return array.slice(0,idx).concat(array.slice(idx+1))
}

// Obj -> Obj -> Obj
// Given an object, and a schema, delete all elements of
// the original object that are not in the schema. Add
// all values in the schema that are not in the original
// object. Leave overlaps as the same value. Return the 
// new object without mutating any originals.
// Note the slightly strange typeof comparison. The keys
// must both be objects or both not be objects to have the
// old value carry over successfully. detail_category can
// be a number or an object, for example. In this case,
// switching between the erase the detail_category value.
exports.schemaAdjust = (obj, schema) => {
    var newObj = {}
    Object.keys(obj).forEach(key => {
        if (key in schema
            && (typeof schema[key] === 'object')
                === (typeof obj[key] === 'object')) {
            newObj[key] = obj[key]
        }
    })
    return {...schema, ...newObj}
}
