<template>
  <v-row no-gutters>
    <v-col :cols="requireLink ? 11 : 12">
      <v-select 
        :label="label" 
        :items="items" 
        item-text="data.name"
        item-value="uuid" 
        :value="value"
        :rules="[formRules.required]"
        dense 
        :disabled="disabled"
        @change="$emit('input', $event)" 
      />
      <template #item="{item}">
        {{ displayName(item) }}
      </template>
      <template #selection="{item}">
        {{ displayName(item) }}
      </template>
    </v-col>
    <v-col
      v-if="requireLink"
      cols="1"
      class="align-self-center"
    >
      <v-btn 
        icon 
        @click="routeToLibrary(route, value)"
      >
        <v-icon color="primary">
          mdi-link-variant
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: "LibrarySelect",
    props: {
        value: {
            type: String,
            required: false,
        },
        items: {
            type: Array,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        route: {
            type: String,
            required: false,
        },
        requireLink: {
            type: Boolean,
            default: false,
            required: false,
        },
        disabled: {
          type: Boolean,
          required: false,
        },
    },
    methods: {
        displayName (libraryObject) {
            if (libraryObject.data.oem) {
                return libraryObject.data.oem + ' ' + libraryObject.data.name
            } else {
                return libraryObject.data.name
            }
        },
    },
}
</script>

<style scoped></style>
