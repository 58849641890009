<template>
  <div>
    <v-card>
      <v-card-title>
        Internals
        <v-spacer />
        <v-btn
          small
          dense
          right
          @click="editFeatures"
          :disabled="disabled"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-chip-group column>
          <v-chip
            v-for="traitType in chips"
            :key="traitType"
            :disabled="disabled"
          >
            {{ traitType }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="showDialog"
      persistent
      width="80%"
    >
      <v-card>
        <v-card-title>Internals</v-card-title>
        <v-card-text>
          <section-feature
            v-for="(feature, index) in value"
            :key="index"
            :value="feature"
            @input="updateValue([index], $event, value)"
            @delete="deleteFeature(index)"
            :disabled="disabled"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="addFeature"
            :disabled="disabled"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SectionFeature from "./SectionFeature"
import {dropIndex} from "@/utils/fp"

const emptyFeature = {
  trait: {},
  radial_pattern: {},
  vertical_offsets: [],
}

export default {
  name: "SectionFeatures",
  components: {
    SectionFeature,
  },
  props: ["value", "disabled"],
  data() {
    return {
      showDialog: false,
    }
  },
  computed: {
    chips() {
      if (!this.value) { return [] }
      return Array.from(
        new Set(this.value.map((feature) => feature.trait.type))
      )
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false
    },
    editFeatures() {
      this.showDialog = true
    },
    addFeature() {
      this.$emit("input", this.value.concat(emptyFeature))
    },
    deleteFeature (idx) {
      this.$emit("input", dropIndex(this.value, idx))
    },
  },
}
</script>
