<template>
  <v-container fluid>
    <v-col>
      <v-row>
        <v-col md="6">
          <v-select
            label="Quality class"
            :items="qualityClass"
            :value="value.quality_class"
            :disabled="isGlobal"
            @input="updateValue('quality_class', $event, value)"
          />
          <v-select
            label="Revision"
            :items="revision"
            :value="value.revision"
            :disabled="isGlobal"
            @input="updateValue('revision', $event, value)"
          />
        </v-col>
        <v-col md="3">
          <numeric-text-field
            label="Misalignment"
            :value="value.misalignment"
            units="m/m"
            :disabled="isGlobal"
            @input="updateValue('misalignment', $event, value)"
          />
          <numeric-text-field
            label="Safety factor"
            :value="value.safety_factor"
            :disabled="isGlobal"
            @input="updateValue('safety_factor', $event, value)"
          />
        </v-col>
        <v-col md="3">
          <numeric-text-field
            label="Tower mass partial load factor"
            :value="value.tower_mass_partial_load_factor"
            :disabled="isGlobal"
            @input="updateValue('tower_mass_partial_load_factor', $event, value)"
          />
          <numeric-text-field
            label="Beta W"
            :value="value.beta_w"
            :disabled="isGlobal"
            @input="updateValue('beta_w', $event, value)"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-row>
      <v-col md="4">
        <traits
          :value="value.trait_types"
          :is-global="isGlobal"
          @input="updateValue('trait_types', $event, value)"
        />
      </v-col>
      <v-col md="8">
        <v-card elevation="3" class="mb-2">
          <v-card-title 
            class="subtitle-1"
          >
            Set Limits
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-checkbox
                  dense
                  :input-value="value.allow_aggressive_squash_limit"
                  label="Allow aggressive squash limit"
                  :disabled="isGlobal"
                  @change="updateValue('allow_aggressive_squash_limit', $event, value)"
                />
                <v-spacer />
                <v-checkbox
                  dense
                  :input-value="value.use_force_parallel_for_shear_interaction"
                  label="Use force parallel for shear interaction"
                  :disabled="isGlobal"
                  @change="updateValue('use_force_parallel_for_shear_interaction', $event, value)"
                />
                <v-spacer />
                <v-checkbox
                  dense
                  :input-value="value.use_hoop"
                  label="Use hoop"
                  :disabled="isGlobal"
                  @change="updateValue('use_hoop', $event, value)"
                />
                <v-spacer />
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <safety-factors
          :value="value.regional_safety_factors"
          :is-global="isGlobal"
          @input="updateValue('regional_safety_factors', $event, value)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import NumericTextField from "@/components/common/NumericTextField"
import Traits from "@/components/design/Traits"
import SafetyFactors from "@/components/design/SafetyFactors"

export default {
  name: "Ec3BucklingAnalyzer",
  components: {
    Traits,
    NumericTextField,
    SafetyFactors,
  },
  props: ["value", "isGlobal"],
  computed: {
    qualityClass () {
      return ["A", "B", "C"]
    },
    revision () {
      return ["R2007", "R2017"]
    },
  },
}
</script>
