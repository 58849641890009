<template>
  <span
    class="
      blue-grey--text
      text--darken-4
    "
    :style="{
      'background-color': 'rgba(9,30,66,0.1)',
      'border-radius': '4px',
      'font-weight': fontWeight,
      'padding': padding,
    }"
  >
    <span>{{ text }}</span>
  </span>
</template>
<script>
export default {
  name: "BorderedTextSpan",
  props: {
    text: {
      type: String
    },
    fontWeight: {
      type: String,
      default: '500',
    },
    padding: {
      type: String,
      default: '1px',
    },
  },
}
</script>