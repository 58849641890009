<template>
  <v-row>
    <v-col cols="3">
      <v-select
        v-model="bolt"
        label="Bolt"
        :items="boltNames"
        :disabled="disabled"
      />
    </v-col>
    <v-col cols="3">
      <numeric-text-field
        label="Bolt circle count"
        :value="value.count"
        :disabled="disabled"
        :rules="[formRules.positiveWholeNumber]"
        @input="updateValue('count', $event, value)"
      />
    </v-col>
    <v-col cols="3">
      <numeric-text-field
        label="Bolt circle offset"
        units="mm"
        :value="value.offset"
        :disabled="disabled"
        :placeholder="defaultOffset"
        clearable
        @input="updateValue('offset', $event, value)"
      />
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "BoltCircle",
  components: {
    NumericTextField,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bolts() {
      return this.$store.state.bolts.map((item) => item.data)
    },
    boltNames () {
      return this.bolts.map((item) => item.name)
    },
    defaultOffset () {
      return this.value.bolt?.min_wall_spacing?.toString()
    },
    bolt: {
      get () {
        return this.value.bolt?.name
      },
      set (v) {
        const b = this.bolts.find((item) => item.name === v)
        this.updateValue('bolt', b, this.value)
      },
    },
  },
  mounted() {
    this.$store.dispatch("fetchBolts");
  },
}
</script>
