var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "\n    blue-grey--text\n    text--darken-4\n  ",
    style: {
      'background-color': 'rgba(9,30,66,0.1)',
      'border-radius': '4px',
      'font-weight': _vm.fontWeight,
      'padding': _vm.padding
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.text))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }