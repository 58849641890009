<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-spacer />
        <v-label>Center of Gravity (m)</v-label>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="4"
          class="px-2 pt-2"
        >
          <numeric-text-field
            label="X"
            :value="value.x"
            :disabled="disabled"
            placeholder="0"
            dense
            @input="updateValue('x', $event, value)"
          />
        </v-col>
        <v-col
          cols="4"
          class="px-2 pt-2"
        >
          <numeric-text-field
            label="Y"
            :value="value.y"
            :disabled="disabled"
            placeholder="0"
            dense
            @input="updateValue('y', $event, value)"
          />
        </v-col>
        <v-col
          cols="4"
          class="px-2 pt-2"
        >
          <numeric-text-field
            label="Z"
            :value="value.z"
            :disabled="disabled"
            placeholder="0"
            dense
            @input="updateValue('z', $event, value)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "CenterOfGravity",
  components: {
    NumericTextField,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
