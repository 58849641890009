<template>
  <v-row>
    <v-col v-if="reportType === 'SRF_MAP'">
      <comma-separated-list
        label="Excluded Sections"
        :value="value.excluded_sections"
        clearable
        @input="updateValue('excluded_sections', $event, value)"
      />
    </v-col>
    <v-col v-if="['CERTIFICATION', 'SUMMARY'].includes(reportType)">
      <v-row>
        <v-col>
          <v-text-field
            label="Document Number"
            :value="value.document_number"
            clearable
            @input="updateValue('document_number', $event, value)"
          />
        </v-col>
        <v-col>
          <comma-separated-list
            label="Excluded Sections"
            :value="value.excluded_sections"
            clearable
            @input="updateValue('excluded_sections', $event, value)"
          />
        </v-col>
        <v-col>
          <v-checkbox
            label="Truncated Report"
            :value="value.truncated_report"
            @change="updateValue('truncated_report', $event, value)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <textarea-list
            label="Design Notes"
            :value="value.design_notes"
            auto-grow
            clearable
            rows="1"
            @input="updateValue('design_notes', $event, value)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CommaSeparatedList from "@/components/common/CommaSeparatedList"
import TextareaList from "@/components/common/TextareaList"

export default {
  name: "ReportArgs",
  components: {
    CommaSeparatedList,
    TextareaList,
  },
  props: ["value", "reportType", "sections"],
  computed: {
    sectionNotes () {
      return this.value.section_notes ?? {}
    },
  },
  methods: {
    updateSectionNotes (idx, v) {
      let newNotes = {...this.sectionNotes}
      newNotes[idx] = v
      this.updateValue('section_notes', newNotes, this.value)
    },
  },
}
</script>
