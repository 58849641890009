<template>
  <v-dialog v-model="show" max-width="650">
    <v-card class="mx-auto" tile>
      <v-card-title>
        <p>
          <span>{{ objectName }}</span>
          <span style="white-space: pre;"><br />Changelog with </span>
          <bordered-text-span
            :text="String(items.length)"
            font-weight="bold"
            padding="2px"
          />
          &nbsp;Changes
        </p>
      </v-card-title>
      <v-expansion-panels v-model="panel">
        <v-expansion-panel v-for="item in items" :key="item.timestamp">
          <v-expansion-panel-header>
            <p>
              {{ item.operation }} by
              <span class="blue--text text--darken-2">@{{ item.username }}</span>
              on {{ item.timestamp }}
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul>
              <li
                v-for="op in item.jsonpatch"
                :key="item.timestamp + op.value + op.path"
              >
              <p>
                <bordered-text-span
                  :text="String(op.path)"
                  font-weight="bold"
                  padding="3px"
                />
                <span
                  v-if="op.op==='remove'"
                  class="red--text text--lighten-1"
                >
                  &nbsp;Removed
                </span>
                <ul v-if="op.op==='replace'">
                  <li v-if="op.old_value">
                    <span style="color:#EF5350;text-decoration:line-through;text-decoration-thickness: 1px;">
                      <span class="red--text text--lighten-1">{{ op.old_value }}</span>
                    </span>
                  </li>
                  <li>
                    <span class="green--text text--lighten-1">{{ op.value }}</span>
                  </li>
                </ul>
                <ul v-else-if="op.op==='add'">
                  <li>
                    <span class="green--text text--lighten-1">{{ op.value }}</span>
                  </li>
                </ul>
              </p>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="justify-end">
        <v-btn text color="primary" @click.stop="show = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment"
import BorderedTextSpan from "@/components/common/BorderedTextSpan"
export default {
  components: {
    BorderedTextSpan,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    history: {
      type: Array,
      required: true,
    },
    objectName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      panel: 0,
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
        this.$emit("input", value)
      },
    },
    items() {
      let items = this.history
      items = this.formatItems(items)
      return items.sort((item) => this.toUnixTime(item.timestamp)).reverse()
    }
  },
  methods: {
    toUnixTime(timestamp) {
      return moment(timestamp, "dddd, MMMM Do YYYY, h:mm:ss A").format('x')
    },
    toMomentTime(timestamp) {
      if (moment(timestamp).isValid()) {
        return moment(timestamp).format("dddd, MMMM Do YYYY, h:mm:ss A")
      } else {
        return undefined
      }
    },
    findLoadset(value) {
      return this.$store.state.loadSets.find(
        el => el.uuid === value
      )
    },
    formatItems(items) {
      let operations = {
        "create": "Created",
        "edit": "Edited",
      }
      for (let item of items) {
        let op = operations[item.operation]
        let timestamp = this.toMomentTime(item.timestamp)
        if (op && timestamp) {
          item.operation = op
          item.timestamp = timestamp
        }
        if (item.jsonpatch) {
          for (let patch of item.jsonpatch) {
            if (patch.path?.split('/')[1] === 'load_sets') {
              if (patch?.value) {
                let loadset = this.findLoadset(patch.value)
                if (loadset) { patch.value = loadset.data.name }
                }
              if (patch?.old_value) {
                let loadset = this.findLoadset(patch.old_value)
                if (loadset) { patch.old_value = loadset.data.name }
              }
            }
          }
        }
      }
      return items
    },
  }
}
</script>
