import Vue from "vue"
import { Auth } from "aws-amplify"
import api from "./api"
import Vuex from "vuex"

Vue.use(Vuex)

let formatError = (error) => {
    let data = error.response?.data
    let detail = error.response?.data?.detail
    if (!data) {
        return error
    }
    else if (detail) {
        return detail
    }
    else {
        // Check if data is in html form
        if (/<\/?[a-z][\s\S]*>/i.test(data)) {
            const parser = new DOMParser()
            const htmlDoc = parser.parseFromString(data, 'text/html')
            const message = htmlDoc.getElementsByTagName('p')[0].innerHTML
            console.log(message)
            return message
            // return parsed code & <p></p>
        } else {
            return data
        }
    }
}

export default new Vuex.Store({
    state: {
        user: null,
        gettingUser: false,
        towers: [],
        towerIntent: {},
        editTower: {},
        constraints: [],
        turbines: [],
        designBases: [],
        inventories: [],
        loadSets: [],
        bolts: [],
        materials: [],
        modelNumbers: [],
        showError: false,
        error: null,
    },
    mutations: {
        updateUser(state, value) {
            state.user = value;
        },
        updateGettingUser(state, value) {
            state.gettingUser = value;
        },
        updateTowers(state, value) {
            state.towers = value;
        },
        updateConstraints(state, value) {
            state.constraints = value;
        },
        updateTurbines(state, value) {
            state.turbines = value;
        },
        updateDesignBases(state, value) {
            state.designBases = value;
        },
        updateInventories(state, value) {
            state.inventories = value;
        },
        updateLoadSets(state, value) {
            state.loadSets = value;
        },
        updateBolts(state, value) {
            state.bolts = value;
        },
        updateMaterials(state, value) {
            state.materials = value;
        },
        updateModelNumbers(state, value) {
            state.modelNumbers = value;
        },
        appendModelNumber(state, value) {
            state.modelNumbers = [...state.modelNumbers, value];
        },
        displayError(state, error) {
            state.showError = true;
            state.error = error;
        },
        setTowerIntent (state, value) {
            state.towerIntent = value
        },
        setEditTower (state, value) {
            state.editTower = value
        },
    },
    actions: {
        getTower(context, uuid) {
            return api
                .get("/tower/" + uuid)
                .then((response) => {
                    return response
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error getting tower: " + formatError(err)
                    )
                })
        },
        saveTower(context, { uuid, obj }) {
            return api
                .put("/tower/" + uuid, obj)
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error saving tower: " + formatError(err)
                    )
                })
        },
        computeTower(context, uuid) {
            return api
                .put("/tower/" + uuid + "/compute", {})
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error computing tower: " + formatError(err)
                    )
                })
        },
        fetchTowers(context, params={}) {
            return api
                .get("/tower", params)
                .then((response) =>
                    context.commit("updateTowers", response.data.items)
                )
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error fetching towers: " + formatError(err)
                    )
                })
        },
        getReports(context, tower_id) {
            return api
                .get("/report/" + tower_id)
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error getting reports: " + formatError(err)
                    )
                })
        },
        fetchTurbines(context) {
            return api
                .get("/turbine")
                .then((response) => {
                    context.commit("updateTurbines", response.data.items)
                    return response.data.items
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error fetching turbines: " + formatError(err)
                    )
                })
        },
        fetchDesignBases(context) {
            return api
                .get("/design-basis")
                .then((response) => {
                    context.commit("updateDesignBases", response.data.items)
                    return response.data.items
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error fetching design bases: " + formatError(err)
                    )
                })
        },
        fetchLoadSets(context) {
            return api
                .get("/load-set")
                .then((response) => {
                    context.commit("updateLoadSets", response.data.items)
                    return response.data.items
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error fetching load sets: " + formatError(err)
                    )
                })
        },
        uploadLoadSetFile(context, payload) {
            return api
                .post("/load-set/upload", payload)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error uploading LoadSet file: " + formatError(err)
                    )
                })
        },
        updateLoadSet(context, { uuid, obj }) {
            return api
                .put("/load-set/" + uuid, obj)
                .then((response) => {
                    return response
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error updating LoadSet: " + formatError(err)
                    )
                })
        },
        deleteLoadSet(context, uuid) {
            return api
                .delete("/load-set/" + uuid)
                .then((response) => {
                    return response
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error deleting LoadSet: " + formatError(err)
                    )
                })
        },
        downloadLoadSetCSV(context, uuid) {
            return api
                .get("/load-set/" + uuid + "/download-csv")
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error downloading LoadSet CSV: " + formatError(err)
                    )
                })
        },
        getDynamoLoadSet(context, uuid) {
            return api
                .get("/load-set/" + uuid)
                .then((response) => {
                    return response
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error getting LoadSet: " + formatError(err)
                    )
                })
        },
        getS3LoadSet(context, uuid) {
            return api
                .get("/load-set/s3/" + uuid)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error getting LoadSet: " + formatError(err)
                    )
                })
        },
        fetchInventory(context) {
            return api
                .get("/inventory")
                .then((response) => {
                    context.commit("updateInventories", response.data.items)
                    return response.data.items
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error fetching inventory: " + formatError(err)
                    )
                })
        },
        saveInventory(context, { uuid, obj }) {
            return api
                .put("/inventory/" + uuid, obj)
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error saving inventory: " + formatError(err)
                    )
                })
        },
        copyInventory(context, uuid) {
            return api
                .post("/inventory/" + uuid + "/copy", {})
                // eslint-disable-next-line no-unused-vars
                .then(() => context.dispatch("fetchInventory"))
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error copying inventory: " + formatError(err)
                    )
                })
        },
        deleteInventory(context, uuid) {
            return api
                .delete("/inventory/" + uuid)
                // eslint-disable-next-line no-unused-vars
                .then(() => context.dispatch("fetchInventory"))
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error deleting inventory:" + formatError(err)
                    )
                })
        },
        fetchBolts(context) {
            return api
                .get("/bolt")
                .then((response) => {
                    context.commit("updateBolts", response.data.items)
                    return response.data.items
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error fetching bolts: " + formatError(err)
                    )
                })
        },
        fetchMaterials(context) {
            return api
                .get("/material")
                .then((response) => {
                    context.commit("updateMaterials", response.data.items)
                    return response.data.items
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error fetching materials: " + formatError(err)
                    )
                })
        },
        fetchModelNumbers(context) {
            // Returns an Object with the key/vals:
            //  'turbine': {String} uuid
            //  'model_name': {String}
            //  'model_number': {Number}
            return api
                .get("/model_number")
                .then((response) => {
                    context.commit("updateModelNumbers", response.data.items)
                })
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error fetching model numbers: " + formatError(err)
                    )
                })
        },
        createModelNumber(context, payload) {
            return api
                .post("/model_number", payload)
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error creating model number: " + formatError(err)
                    )
                })
        },
        getLibraries(context) {
            return context
                .dispatch("fetchTurbines")
                .then(() => context.dispatch("fetchDesignBases"))
                .then(() => context.dispatch("fetchInventory"))
                .then(() => context.dispatch("fetchLoadSets"))
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error getting libraries: " + formatError(err)
                    )
                })
        },
        createReport(context, payload) {
            return api
                .post("/report", payload)
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error creating report: " + formatError(err)
                    )
                })
        },
        deleteReport(context, uuid) {
            return api
                .delete("/report/" + uuid)
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error deleting report: " + formatError(err)
                    )
                })
        },
        getCsvAsJson(context, filename) {
            // filename is the AWS S3 Bucket CSV filename
            return api
                .get("/report/csv2json", { "filename": filename })
                .then((response) => {return response.data})
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error getting CSV as JSON: " + formatError(err)
                    )
                })
        },
        createTower(context, data) {
            return api
                .post("/tower", data)
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error creating tower: " + formatError(err)
                    )
                })
        },
        copyTower(context, { towerId, params={} }) {
            return api
                .post("/tower/" + towerId + "/copy", params)
                // eslint-disable-next-line no-unused-vars
                .then(() => context.dispatch("fetchTowers", params))
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error copying tower: " + formatError(err)
                    )
                })
        },
        deleteTower(context, towerId) {
            return (
                api.delete("/tower/" + towerId)
                    // eslint-disable-next-line no-unused-vars
                    .then(() => {
                        context.commit(
                            "updateTowers",
                            context.state.towers.filter(
                                tower => tower.uuid !== towerId
                            )
                        )
                    })
                    .catch((err) => {
                        context.commit(
                            "displayError",
                            "Error deleting tower: " + formatError(err)
                        )
                    })
            );
        },
        archiveTower(context, towerId, params={}) {
            return api
                .put("/tower/" + towerId + "/archive")
                .then(() => context.dispatch("fetchTowers", params))
                .catch((err) => {
                    context.commit(
                        "displayError",
                        "Error archiving tower: " + formatError(err)
                    )
                })
        },
        getCurrentUser(context) {
            context.commit("updateGettingUser", true)
            Auth.currentUserInfo()
                .then((user) => context.commit("updateUser", user))
                .finally(() => context.commit("updateGettingUser", false))
                .catch((err) => {
                    console.log("Error getting current user: ", formatError(err))
                })
        },
        signIn(context, { username, password }) {
            return new Promise((resolve, reject) => {
                Auth.signIn(username, password)
                    .then((user) => {
                        if (
                            user.challengeName === "SMS_MFA" ||
                            user.challengeName === "SOFTWARE_TOKEN_MFA"
                        ) {
                            console.log("Not implemented");
                        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                            context.commit("updateUser", user);
                            console.log("new pass");
                        } else if (user.challengeName === "MFA_SETUP") {
                            console.log("MFA_SETUP not implemented.");
                        } else {
                            context.commit("updateUser", user);
                        }
                    })
                    .catch((err) => reject(err));
            });
        },
        completeNewPassword(context, password) {
            return Auth.completeNewPassword(
                context.state.user,
                password
            ).then((loggedUser) => {
                context.commit("updateUser", loggedUser)
            }).catch((err) => {
                console.log("Error completing new password: ", formatError(err))
            })
        },
        signOut(context) {
            Auth.signOut().catch((err) => console.log(err));
            context.commit("updateUser", null);
        },
        clearErrors(context) {
            context.commit("displayError", null)
        },
    },
    getters: {
        isSignedIn: (state) => () => {
            return state.user && state.user.challengeName != "NEW_PASSWORD_REQUIRED";
        },
        needChangePassword: (state) => () => {
            return state.user && state.user.challengeName === "NEW_PASSWORD_REQUIRED";
        },
        findInventoryById: (state) => (uuid) => {
            return state.inventories.find((item) => item.uuid === uuid);
        },

        findLoadSetById: (state) => (uuid) => {
            return state.loadSets.find((item) => item.uuid === uuid);
        },
    },
});
