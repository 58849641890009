import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Amplify, * as AmplifyModules from "aws-amplify";
import VuetifyConfirm from "vuetify-confirm";
import numeral from "numeral";
import "./mixins/utils";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_mA48fVArA",
    userPoolWebClientId: "3l479vujdmtabi8glgflt581h2",
  },
});

Vue.use(/*AmplifyPlugin,*/ AmplifyModules);
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonFalseColor: "primary",
});

Vue.config.productionTip = false;

Vue.filter("formatNumber", (number) => {
  return numeral(number).format("0,0.0");
});

Vue.mixin({
  data: function () { return { attrs$: { "data-lpignore": "true" } } }
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
