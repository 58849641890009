<template>
  <div>
    <tower-nav>
      <template #actions>
        <status-change-button
          v-if="('data' in original)"
          :status-dates="towerStatuses"
          :tower-id="original.uuid"
          @statusChange="refreshOriginal"
        />
        <v-btn
          color="secondary"
          :disabled="unedited || saving || !isValid || !isTowerDraft"
          :loading="saving"
          class="ml-4"
          @click="saveTower()"
        >
          Save
        </v-btn>
        <v-menu
          open-on-hover
          bottom
          rounded="lg"
          :offset-y="true"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :large="$vuetify.breakpoint.mdAndUp">
                mdi-menu
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="selection">
              <!-- Force Recompute -->
              <v-list-item
                :disabled="!original || computing"
                @click="recomputeTower"
              >
                <v-list-item-icon>
                  <v-icon>mdi-reload</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Force Recompute</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- History -->
              <v-list-item
                :disabled="!original.history"
                @click.stop="showHistoryModal = true"
              >
                <v-list-item-icon>
                  <v-icon>mdi-history</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>History</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- Status Dates | Tower Release -->
              <v-list-item
                :disabled="disableTowerRelease"
                @click.stop="showStatusModal = true"
              >
                <v-list-item-icon>
                  <v-icon>mdi-list-status</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Tower Release</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- Copy Tower -->
              <v-list-item @click.stop="copyTower()">
                <v-list-item-icon>
                  <v-icon>mdi-content-copy</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Copy Tower</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- Delete Tower -->
              <v-list-item
                :disabled="!isTowerDraft"
                @click.stop="deleteTower()"
              >
                <v-list-item-icon>
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Delete Tower</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>
    </tower-nav>
    <v-progress-linear
      v-if="isComputing || !('data' in original) || refreshingOriginal"
      indeterminate
      color="primary"
      background-color="white"
    />
    <history-modal
      v-if="original.history"
      v-model="showHistoryModal"
      :history="original.history"
      :object-name="original.data.name"
    />
    <status-modal
      v-if="towerStatuses"
      v-model="showStatusModal"
      :status-dates="towerStatuses"
      :tower-id="original.uuid"
      @statusChange="refreshOriginal"
    />
    <v-container fluid>
      <v-row>
        <v-col>
          <v-alert
            v-if="isComputing"
            type="info"
            dense
          >
            <template #prepend>
              <v-icon class="mr-4">
                mdi-autorenew mdi-spin
              </v-icon>
            </template>
            Tower is Computing...
          </v-alert>
          <v-alert
            v-for="(error, index) in original.errors"
            :key="index"
            type="error"
            dismissible
          >
            {{ error }}
          </v-alert>
          <v-alert
            v-if="original.warnings && original.warnings.length > 0"
            type="warning"
            dismissible
          >
            Warnings
            <a
              v-if="showMoreWarnings"
              @click="showMoreWarnings = false"
            >
              show less
            </a>
            <a
              v-else
              @click="showMoreWarnings = true"
            >
              show more
            </a>
            <ul v-if="showMoreWarnings">
              <li
                v-for="(warning, index) in original.warnings"
                :key="index"
              >
                {{ warning }}
              </li>
            </ul>
            <div v-else>
              <ul>
                <li>
                  {{ original.warnings[0] }}
                </li>
                <li>
                  ...
                </li>
              </ul>
            </div>
          </v-alert>
  
          <v-form v-model="isValid">
            <tower-intent
              v-model="towerIntent"
              :model="original.model"
              ref="modelNumberRef"
              :revision="towerRevision"
              :disabled="!isTowerDraft"
            />
          </v-form>
        </v-col>
  
        <!-- Side View -->
  
        <v-col cols="3">
          <v-card elevation="24">
            <v-card-text>
              <v-row>
                <v-spacer />
                <v-col>
                  <div v-html="original.sketch" />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-text>
            <v-card-text>
              <unit-results :results="towerResults" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import TowerIntent from "@/components/intent/TowerIntent"
import TowerNav from "@/components/common/TowerNav"
import UnitResults from "@/components/common/UnitResults"
import HistoryModal from "@/components/HistoryModal"
import StatusModal from "@/components/StatusModal"
import ConfirmDialog from "@/components/ConfirmDialog"
import _ from "lodash"
import moment from "moment"
import StatusChangeButton from "@/components/StatusChangeButton"

export default {
  name: "IntentView",
  components: {
    TowerIntent,
    TowerNav,
    UnitResults,
    HistoryModal,
    StatusModal,
    ConfirmDialog,
    StatusChangeButton,
},
  beforeRouteLeave(to, from, next) {
    if (this.unedited) {
      clearInterval(this.updateCheckInterval)
      next(true)
    } else {
      this.$confirm(
        "You have unsaved changes which will be lost. Still leave?"
      ).then((res) => {
        if (res) {
          this.towerIntent = this.original.data
          clearInterval(this.updateCheckInterval)
        } 
        next(res)
      })
    }
  },
  data() {
    return {
      saving: false,
      computing: false,
      original: {},
      isValid: true,
      showMoreWarnings: false,
      showHistoryModal: false,
      showStatusModal: false,
      selection: null,
      refreshingOriginal: false,
      towerRevision: null,
    }
  },
  computed: {
    towerResults() {
      if (this.original.model) {
        return [
          { key: "Hub Height", value: this.original.model.hub_height.toFixed(3), units: "m" },
          { key: "Tower Mass", value: this.original.model.mass, units: "kg" },
          {
            key: "Soft Frequency",
            value: this.original.model.soft_frequency.toFixed(3),
            units: "Hz",
          },
          {
            key: "Stiff Frequency",
            value: this.original.model.stiff_frequency.toFixed(3),
            units: "Hz",
          },
        ];
      } else {
        return [];
      }
    },
    towerIntent: {
      get () {
        return this.$store.state.editTower
      },
      set (v) {
        this.$store.commit('setEditTower', v)
      },
    },
    unedited() {
      return _.isEqual(this.original.data, this.towerIntent)
    },
    isComputing () {
      const outdated = this.original.lastmodified > this.original.computed
      const notComputed = this.original.lastmodified
        && !this.original.computed
      const reasonablyRecent =
        moment().subtract(1, "minute")
        .isBefore(moment(this.original.lastmodified))
      return (notComputed || outdated) && reasonablyRecent
    },
    disableTowerRelease() {
      return _.isEmpty(this.towerStatuses)
    },
    isTowerDraft() {
      return this.checkIfTowerDraft(this.original.status_dates)
    },
    towerStatuses() {
      return this.original?.status_dates
    },
  },
  mounted() {
    this.$store.dispatch("getLibraries")
    this.$store.dispatch("getTower", this.$route.params.uuid)
      .then((response) => {
        this.towerRevision = response.data.revision
        this.original = response.data
        this.towerIntent = this.original.data
      })
    this.$store.dispatch("fetchModelNumbers")
    this.updateCheckInterval = setInterval(this.checkForUpdates, 5000)
  },
  watch: {
    selection() {
      this.$nextTick(() => {
        this.selection = null
      });
    }
  },
  methods: {
    copyTower() {
      this.$refs.confirm.open(
        "Copy Tower",
        "Are you sure you want to Copy this Tower?"
      ).then((result) => {
        if (result) {
          this.$store.dispatch("copyTower", { towerId: this.original.uuid })
        }
      }).finally(() => this.routeToTowerList())
    },
    deleteTower() {
      this.$refs.confirm.open(
        "Delete Tower",
        "Are you sure you want to Delete this Tower?"
      ).then((result) => {
        if (result) {
          this.$store.dispatch("deleteTower", this.original.uuid)
        }
      }).finally(() => this.routeToTowerList())
    },
    routeToTowerList() {
      this.$router.push({
        name: "towers-list-turbine-model-number",
        params: {
          turbine: this.original.turbine,
          model_number: this.original.model_number,
        }
      })
    },
    saveTower () {
      this.saving = true
      this.$refs.modelNumberRef.savingModelNumber()
        .then((result) => {
          _.set(this.towerIntent, "model_number", result)

          this.$store.dispatch("saveTower", {
            uuid: this.$route.params.uuid,
            obj: this.towerIntent,
          })
          .then((response) => {
            this.original = response.data
          })
          .finally(() => this.saving = false)
        })
    },
    checkForUpdates () {
      if (this.isComputing) {
        this.$store.dispatch("getTower", this.$route.params.uuid)
        .then((response) => this.original = response.data)
      }
    },
    refreshOriginal() {
      this.refreshingOriginal = true
      this.$store.dispatch("getTower", this.$route.params.uuid)
        .then((response) => this.original = response.data)
        .finally(() => this.refreshingOriginal = false)
    },
    recomputeTower() {
      this.computing = true
      this.$store.dispatch("saveTower", {
          uuid: this.$route.params.uuid,
          obj: this.towerIntent,
        })
        .then((response) => {
          this.original = response.data
        })
        .finally(() => this.computing = false)
    },
  }
}
</script>
