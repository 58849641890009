<template>
  <v-alert
    v-if="$store.state.error"
    v-model="$store.state.showError"
    type="error"
    dismissible
  >
    <p v-if="$store.state.error.detail">{{ $store.state.error.detail }}</p>
    <p v-else class="ma-0">{{ $store.state.error }}</p>

    <p v-if="$store.state.error.errors">
      {{ JSON.stringify($store.state.error.errors) }}
    </p>
    <div v-if="$store.state.error.references">
      <p>This resource is being referenced in the following places.</p>
      <ul>
        <li
          v-for="(ref, index) in $store.state.error.references"
          :key="index"
        >
          <router-link
            :to="{ name: resourceToRoute(ref[0]), params: { uuid: ref[1] } }"
          >
            {{ ref[1] }}
          </router-link>
        </li>
      </ul>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "MainAlert",
  methods: {
    resourceToRoute(resource) {
      const mapping = {
        tower: "tower-intent",
        turbine: "turbine",
      };
      return mapping[resource];
    },
  },
};
</script>
