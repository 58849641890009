<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="600px"
  >
    <template #activator="{ on }">
      <v-btn
        dark
        class="ma-2"
        color="primary"
        v-on="on"
        @click="show"
      >
        Create new tower
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">New Tower</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form v-model="isValid">
            <v-text-field
              v-model="name"
              label="Tower name"
              :rules="[formRules.required]"
            />
            <library-select 
              v-model="turbine"
              label="Turbine"
              :items="$store.state.turbines"
              item-text="data.name"
              item-value="uuid"
            />
            <model-number-select
              v-model="modelNumber"
              ref="modelNumberRef"
              :turbine="turbine"
            />
            <library-select
              v-model="designBasis"
              label="Design Bases"
              :items="$store.state.designBases"
              item-text="data.name"
              item-value="uuid"
            />
            <library-select
              v-model="canInventory"
              label="Can Materials"
              :items="$store.state.inventories"
              item-text="data.name"
              item-value="uuid"
            />
            <library-select
              v-model="spiralInventory"
              label="Spiral Materials"
              :items="$store.state.inventories"
              item-text="data.name"
              item-value="uuid"
            />
            <library-select
              v-model="loadSet"
              label="Load Set"
              :items="$store.state.loadSets"
              item-text="data.name"
              item-value="uuid"
            />
            <v-radio-group
              v-model="target"
              label="Target"
              mandatory
              row
            >
              <v-radio
                label="Hub Height"
                value="HUB"
              />
              <v-radio
                label="Tip Height"
                value="BLADE_TIP"
              />
            </v-radio-group>

            <numeric-text-field
              v-model="height"
              label="Max Height"
              units="m"
              required
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!isValid"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"
import LibrarySelect from "@/components/LibrarySelect"
import ModelNumberSelect from "@/components/ModelNumberSelect"

export default {
  name: "NewTowerDialog",
  components: {
    NumericTextField,
    LibrarySelect,
    ModelNumberSelect
  },
  props: [],
  data() {
    return {
      isValid: true,
      showDialog: false,
      name: "",
      designBasis: null,
      canInventory: null,
      spiralInventory: null,
      turbine: null,
      modelNumber: null,
      height: null,
      target: null,
      loadSet: null,
      modNumExists: true,
    };
  },
  computed: {
    newTower() {
      const defaultSection = {
        regions: [
          {
            type: "SpiralRegionIntent",
            base_diameter: 4,
            height: 20,
            slope_constraint: "FREE",
          },
        ],
        constraints: [],
        features: [],
      };
      const data = {
        name: this.name,
        turbine: this.turbine,
        load_sets: [this.loadSet],
        hub_height: {
          target: this.target,
          height: this.height,
        },
        can_inventory: this.canInventory,
        spiral_inventory: this.spiralInventory,
        design_basis: this.designBasis,
        sections: [defaultSection, defaultSection, defaultSection],
        foundation: { height: 1, stiffness: 100 },
        model_number: this.modelNumber
      };
      return data
    }
  },
  methods: {
    dispatchCreateTower(data) {
      this.$store
        .dispatch("createTower", data)
        .then((response) => {
          this.$router.push({
            name: "tower-intent",
            params: { uuid: response.data.uuid },
          });
        })
    },
    show() {
      this.$store.dispatch("fetchInventory");
      this.$store.dispatch("fetchDesignBases");
      this.$store.dispatch("fetchTurbines");
      this.$store.dispatch("fetchLoadSets");
      this.showDialog = true;
    },
    close() {
      this.name = "";
      this.design_basis = null;
      this.can_inventory = null;
      this.spiral_inventory = null;
      this.turbineInput = {};
      this.model_number = null;
      this.showDialog = false;
    },
    save() {
      this.$refs.modelNumberRef.saveModelNumber()
        .then(() => {
          this.$store
            .dispatch("createTower", this.newTower)
              .then((response) => {
                this.$router.push({
                  name: "tower-intent",
                  params: { uuid: response.data.uuid },
                })
              })
        })
    }
  },
}
</script>
