<template>
  <div>
    <tower-nav />
    <v-container fluid>
      <v-row class="align-center">
        <v-col cols="3">
          <v-select
            v-model="report_type"
            label="Report type"
            :items="report_types"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template #item="{ item, on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-icon>
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-btn
            color="primary"
            :loading="creatingReport"
            @click="createReport()"
          >
            Create report
          </v-btn>
        </v-col>
        <v-switch
          v-if="showOptions"
          v-model="showArgs"
          label="Options"
          class="ml-6"
        />
      </v-row>
  
      <v-row>
        <v-col cols="6">
          <report-args
            v-if="showArgs"
            v-model="report_args"
            :report-type="report_type"
            :sections="towerIntent.data.sections.length"
          />
        </v-col>
      </v-row>
  
      <v-data-table
        :headers="headers"
        :items="reports"
        disable-pagination
        sort-by="requested"
        sort-desc
        hide-default-footer
        :loading="loading"
        loading-text="Loading Reports..."
      >
        <template #[`item.action`]="{ item }">
          <v-btn-toggle
            v-model="toggleSelection"
            borderless
          >
            <v-btn
              v-if="item.filename || item.error_message || isTimedOut(item.requested)"
              icon
              @click="deleteReport(item.uuid)"
            >
              <v-icon>mdi-delete </v-icon>
            </v-btn>
            <v-btn
              v-if="item.filename"
              icon
              @click="downloadReport(item.filename, item.url, item.file_type)"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </v-btn-toggle>
        </template>
        <template #[`item.filename`]="{ item }">
          <router-link
            v-if="item.file_type === 'CSV'"
            :to="{
              name: 'tower-report-csv-viewer',
              params: { filename: item.filename },
              query: {
                url: item.url,
                report_type: item.report_type,
              }
            }"
            target="_blank"
          >
            {{ item.filename }}
          </router-link>
          <a
            v-else-if="item.file_type === 'ZIP'"
            @click="downloadReport(item.filename, item.url, item.file_type)"
          >
            {{ item.filename }}
          </a>
          <a v-else :href="item.url" target="_blank">
            {{ item.filename }}
          </a>
        </template>
        <template #[`item.requested`]="{ item }">
          <time-distance :time-str="item.requested" />
        </template>
        <template #[`item.age`]="{ item }">
          <v-chip
            v-if="isCurrent(item.requested)"
            color="green"
          >
            Current
          </v-chip>
          <v-chip
            v-else
            color="grey"
          >
            Outdated
          </v-chip>
        </template>
        <template #[`item.status`]="{ item }">
          <v-tooltip
            v-if="item.error_message"
            bottom
          >
            <template #activator="{ on }">
              <span v-on="on">Failed</span>
            </template>
            <span>{{ item.error_message }}</span>
          </v-tooltip>
          <span v-else-if="item.filename">Success</span>
          <span v-else-if="isTimedOut(item.requested)">Timed Out</span>
          <span v-else>Pending</span>
        </template>
        <template #[`item.file_type`]="{ item }">
          <v-chip
            v-if="item.file_type"
            small
            outlined
            :color="fileTypeColor(item.file_type)"
          >
            {{ item.file_type }}
          </v-chip>
          <v-progress-circular
            v-else-if="isPending(item)"
            indeterminate
            :width="3"
            color="primary"
          />
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash"
import TowerNav from "@/components/common/TowerNav"
import TimeDistance from "@/components/common/TimeDistance"
import ReportArgs from "@/components/intent/ReportArgs"
import moment from "moment"
import axios from "axios"
import fileDownload from "js-file-download"

export default {
  name: "TowerReports",
  components: {
    TowerNav,
    TimeDistance,
    ReportArgs,
  },
  data() {
    return {
      host: process.env.VUE_APP_REPORT_HOST,
      report_type: "BLUEPRINT",
      report_args: {},
      reports: [],
      creatingReport: false,
      showArgs: false,
      loading: false,
      toggleSelection: null,
    }
  },
  computed: {
    headers() {
      return [
        { text: "", value: "action", sortable: false },
        { text: "", value: "age" },
        { text: "Report Type", value: "report_type" },
        {
          text: "Requested",
          value: "requested",
          sort: (a, b) => a.localeCompare(b),
        },
        { text: "File Type", value: "file_type", sortable: false},
        { text: "File Name", value: "filename" },
        { text: "Status", value: "status", sortable: false },
      ];
    },
    report_types() {
      return [
        { text: "Addendum", value: "ADDENDUM", icon: "mdi-note-plus"},
        { text: "Arc Lengths", value: "ARC_LENGTH", icon: "mdi-rounded-corner" },
        { text: "Blueprint", value: "BLUEPRINT", icon: "mdi-map" },
        { text: "Buckling CSV", value: "BUCKLING_CSV", icon: "mdi-glass-fragile" },
        { text: "CAD Point Clouds", value: "CAD_POINT_CLOUDS", icon: "mdi-dots-grid" },
        { text: "Certification", value: "CERTIFICATION", icon: "mdi-check" },
        { text: "Corner Points", value: "CORNER_PTS_MESH_INFO", icon: "mdi-circle-small" },
        { text: "Costing Inputs", value: "COSTING", icon: "mdi-currency-usd" },
        { text: "Damage CSV", value: "DAMAGE_CSV", icon: "mdi-glass-fragile" },
        { text: "DXFs", value: "DXFS", icon: "mdi-pencil-ruler-outline" },
        { text: "Partial Frequency CSV", value: "PSFA_CSV", icon: "mdi-sine-wave" },
        { text: "Partial Frequency", value: "PSFA", icon: "mdi-sine-wave" },
        { text: "Quality", value: "QUALITY", icon: "mdi-alpha-q-circle-outline" },
        { text: "SRF Map Zip", value: "SRF_MAP", icon: "SRF" },
        { text: "Structural CSV Zip", value: "STRUCTURAL_ZIP", icon: "mdi-glass-fragile" },
        { text: "Summary", value: "SUMMARY", icon: "mdi-script-text-outline" },
        { text: "Trapezoid Specification", value: "TRAPEZOID", icon: "mdi-axis-arrow-info" },
        { text: "Trapezoid CSV", value: "TRAPEZOID_CSV", icon: "mdi-file-delimited-outline" },
        { text: "Unfolded Weld Map Layout", value: "UNFOLDED_LAYOUT", icon: "mdi-cube-unfolded" },
        { text: "Weld Map Lengths & Distances", value: "WELD_Mnote-plusAP_INFO", icon: "mdi-ruler" },
      ];
    },
    towerIntent () {
      return this.$store.state.towerIntent
    },
    showOptions() {
      return ["CERTIFICATION", "SUMMARY", "SRF_MAP"].includes(this.report_type)
    },
  },
  mounted() {
    this.isTowerIntent()
    this.loadReports()
  },
  watch: {
    toggleSelection() {
      this.$nextTick(() => {
        this.toggleSelection = null
      });
    },
  },
  methods: {
    loadReports() {
      this.loading = true
      this.$store
        .dispatch("getReports", this.$route.params.uuid)
        .then((response) => {
          this.reports = response.data.items
          const pendings = this.reports.filter(this.isPending)
          if (pendings.length > 0) {
            setTimeout(this.loadReports, 3000);
          }
        })
        .finally(() => {
          this.formatFiles(this.reports)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    formatFiles(reports) {
      for (let report of reports) {
        if (report.filename) {
          let fileType = report.filename.split(".").at(-1).toUpperCase()
          report["file_type"] = fileType

          let fileName = report.filename.split(".").slice(0, -1).join(".")
          report["filename"] = fileName
        }
      }
    },
    fileTypeColor(fileType) {
      switch(fileType) {
        case "PDF":
          return "deep-orange accent-2"
        case "CSV":
          return "blue accent-3"
        default:
          return "purple accent-3"
      }
    },
    createReport() {
      this.creatingReport = true
      this.loading = true
      this.$store.dispatch("createReport", {
        tower_id: this.$route.params.uuid,
        report_type: this.report_type,
        report_args: this.report_args,
      }).then(() => {
        this.report_args = {}
        this.loadReports()
      }).finally(() => {
        this.creatingReport = false
        this.loading = false
      })
    },
    downloadReport(filename, url, file_type) {
      let file = filename + `.${file_type.toLowerCase()}`
      axios.get(url, { responseType: "blob" })
        .then((response) => {
          fileDownload(response.data, file)
        })
        .catch((err) => {
          this.$store.commit(
            "displayError",
            "Error downloading report: " + err
          )
        })
    },
    popDeletedReport(uuid) {
      this.reports = this.reports.filter(report => report.uuid !== uuid)
    },
    deleteReport(uuid) {
      this.$confirm("Do you really want to delete this report?")
      .then((res) => {
        if (res) {
          this.$store.dispatch("deleteReport", uuid)
            .then(() => this.popDeletedReport(uuid))
        }
      })
    },
    isCurrent (timestamp) {
      return moment(timestamp).isAfter(moment(this.towerIntent.lastmodified))
    },
    isTimedOut (timestamp) {
      return moment().subtract(20, "minutes").isAfter(moment(timestamp))
    },
    isPending(report) {
      return !report.error_message
        && !report.filename
        && !this.isTimedOut(report.requested)
    },
    isTowerIntent() {
      if (_.isEmpty(this.$store.state.towerIntent)) {
        this.$store.dispatch("getTower", this.$route.params.uuid)
          .then(() => {
            return this.$store.state.towerIntent
          })
      } else {
        return this.$store.state.towerIntent
      }
    },
  },
}
</script>
