<template>
  <v-card elevation="24">
    <v-toolbar
      flat
      short
    >
      <v-toolbar-title>Section {{ sectionIndex + 1 }}</v-toolbar-title>
      <v-btn
        small
        icon
        @click="$emit('duplicate')"
        :disabled="disabled"
      >
        <v-icon>mdi-file-multiple</v-icon>
      </v-btn>
      <v-btn
        small
        icon
        :disabled="preventDelete || disabled"
        @click="$emit('delete')"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-spacer />
        <v-col cols="9">
          <section-features
            :value="value.features"
            @input="updateValue('features', $event, value)"
            :disabled="disabled"
          />
        </v-col>
        <v-col cols="3">
          <unit-results :results="sectionResults" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="reverseorder">
          <region-intent
            v-for="(region, regionIndex) in value.regions"
            :key="regionIndex"
            :value="region"
            :section-index="sectionIndex"
            :region-index="regionIndex"
            :prevent-delete="value.regions.length == 1"
            :shells="model?.shells"
            @input="updateValue(['regions', regionIndex], $event, value)"
            @duplicate="duplicateRegion(regionIndex)"
            @delete="deleteRegion(regionIndex)"
            :disabled="disabled"
          />
        </v-col>
      </v-row>

      <v-card>
        <v-card-title>Lower Flange</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col>
              <flange-spec
                :value="value.lower_flange"
                :disabled="disabled"
                @input="updateValue('lower_flange', $event, value)"
              />
            </v-col>
            <v-col cols="3">
              <unit-results :results="flangeResults" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Section Notes for blueprint report -->
      <v-card>
        <v-card-title>
          <v-tooltip
            bottom
            nudge-right="150"
          >
            <template #activator="{ on }">
              <v-icon
                color="primary"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            Notes added to each section on blueprint report
          </v-tooltip>
          <span class="mx-2">Section {{ sectionIndex + 1 }} Notes</span>
          <v-menu
            open-on-hover
            open-delay="200"
            offset-x
          >
            <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                >
                  mdi-eye
                </v-icon>
            </template>
            <v-card>
              <v-card-title>Preview of Final Section {{ sectionIndex + 1 }} Note</v-card-title>
              <v-card-text>
                <h3>Notes</h3>
                <span
                  v-for="(note, index) in previewSecNote.split('\n')"
                  :key="index"
                  class="body-2"
                >
                  {{ note }} <br />
                </span>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols=6>
              <v-text-field
                label="Flange Material"
                :value="value.flange_material"
                :disabled="disabled"
                @input="updateValue('flange_material', $event, value)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=6 class="pt-0">
              <v-text-field
                label="Shell Material"
                :value="value.shell_material"
                :disabled="disabled"
                @input="updateValue('shell_material', $event, value)"
              />
            </v-col>
          </v-row>
          <v-textarea
            :value="value.extra_blueprint_notes"
            auto-grow
            no-resize
            label="Extra Section Notes for Blueprint"
            @input="updateValue('extra_blueprint_notes', $event, value)"
          />
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import UnitResults from "@/components/common/UnitResults";
import FlangeSpec from "@/components/FlangeSpec";
import SectionFeatures from "./SectionFeatures";
import RegionIntent from "./RegionIntent";
import {dropIndex} from "@/utils/fp"

export default {
  name: "SectionIntent",
  components: {
    UnitResults,
    FlangeSpec,
    SectionFeatures,
    RegionIntent,
  },
  props: ["value", "sectionIndex", "model", "preventDelete", "disabled", "assemblyDrawingNum", "shellMaterial"],
  computed: {
    sectionResults() {
      if (this.model) {
        return [
          {
            key: "Top diameter",
            value: this.model.top_diameter.toFixed(3),
            units: "m",
          },
          {
            key: "Base diameter",
            value: this.model.base_diameter.toFixed(3),
            units: "m",
          },
          { key: "Height", value: this.model.height.toFixed(3), units: "m" },
          { key: "Mass", value: this.model.mass, units: "kg" },
        ]
      } else {
        return []
      }
    },
    flangeResults() {
      if (this.model) {
        return [
          {
            key: "Diameter",
            value: this.model.lower_flange.shell_diameter.toFixed(3),
            units: "m",
          },
          {
            key: "Transport Mass",
            value: this.model.lower_flange.transport_mass.toFixed(3),
            units: "kg",
          },
          {
            key: "Design Mass",
            value: this.model.lower_flange.design_mass.toFixed(3),
            units: "kg",
          },
          {
            key: "Neck Height",
            value: this.model.lower_flange.profile.neck_height.toFixed(3),
            units: "mm",
          },
        ]
      } else {
        return []
      }
    },
    extraSecNoteDefault() {
      return `\
        Schematic flange dimensions shown. Refer to fabrication drawings for details.
        Reference ${this.assemblyDrawingNum} Structural Notes for fabrication.`.replace(/^ +/gm, '')
    },
    previewSecNote() {
      let flangeNote = "Flange Material: 'Default calculated in backend'"
      let shellNote = "Shell Material: 'Default calculated in backend'"
      let extraNote = this.extraSecNoteDefault
      if (this.value.flange_material) {
        flangeNote = 'Flange Material: ' + this.value.flange_material
      }
      if (this.value.shell_material) {
        shellNote = 'Shell Material: ' + this.value.shell_material
      }
      if (this.value.extra_blueprint_notes) {
        extraNote = this.value.extra_blueprint_notes
      }
      return `\
        ${flangeNote.concat('\n', shellNote)}
        ${extraNote}`.trim().replace(/^ +/gm, '')
    },
    lowerFlangeMaterial() {
      return this.value.lower_flange.material.name
    }
  },
  methods: {
    deleteRegion (idx) {
      this.updateValue(
        'regions',
        dropIndex(this.value.regions, idx),
        this.value,
      )
    },
    duplicateRegion (idx) {
      this.updateValue(
        'regions',
        this.value.regions.slice(0,idx).concat(
          this.value.regions[idx],
          this.value.regions.slice(idx)),
        this.value,
      )
    },
  },
};
</script>

<style scoped>
.reverseorder {
  display: flex;
  flex-direction: column-reverse;
}
</style>
