<template>
  <v-combobox
    v-model="modelNameStatus"
    label="Tower Family"
    :items="modelNumberItems"
    item-text="model_name"
    item-value="model_name"
    :hint="
      !modNumExists
        ? `New Model Name Detected: ${modelName}`
        : ''"
    persistent-hint
    :class="{'mb-2': !modNumExists}"
    :return-object="false"
    :rules="[formRules.required, v => isNaN(v) || 'Value cannot be a number.']"
    :menu-props="{ bottom: true, offsetY: true }"
    dense
    hide-no-data
    :disabled="comboboxDisabled || disabled"
    :outlined="outlined"
  />
</template>

<script>
import _ from "lodash/fp"

export default {
  name: "ModelNumberSelect",
  data() {
    return {
      modNumExists: true,
      modelName: null,
    }
  },
  props: {
    value: [Number, String],
    turbine: String,
    outlined: Boolean,
    disabled: Boolean,
  },
  computed: {
    modelNameStatus: {
      get () {
        if (this.value && this.$store.state.modelNumbers) {
          if (typeof this.value === "number") {
            if (_.isEmpty(this.modelNumberItems)) { 
              this.setModelName("")
            }
            let modNum = this.findModelNumber(this.value)
            if (modNum) {
              return modNum
            } else {
              this.setModelName("")
            }
          } else if (typeof this.value === "string") {
            this.setModelName(this.value)
          } else {
            this.setModelName("")
          }
        }
        return this.modelName
      },
      set (val) {
        this.setModelName(_.trim(val))
        this.modNumExists = this.modelNumberExists(this.modelName)
        if (this.modNumExists) {
          this.$emit("input", this.findModelNumber(this.modelName))
        } else {
          this.$emit("input", this.modelName)
        }
      }
    },
    comboboxDisabled() {
      return !this.turbine
    },
    modelNumberItems() {
      let filterModelNumbers = this.$store.state.modelNumbers.filter(
        (el) => el.turbine.split('#')[1] === this.turbine
      )
      this.checkItems()
      return _.sortBy("model_name")(filterModelNumbers)
    }
  },
  methods: {
    setModelName(val) {
      this.modelName = val
    },
    checkItems() {
      if (this.turbine && this.value) {
        this.modNumExists = this.modelNumberExists(this.value)
      }
    },
    isModelNumber(arg) {
      // arg is either model_number(Number) or a model name(String)
      return !isNaN(arg)
    },
    findModelNumber(arg) {
      // If arg is model name -> return model number else null
      // if arg = model number -> return model name else null
      let isModNum = this.isModelNumber(arg)
      let modNum = this.$store.state.modelNumbers.find(
        el => (isModNum
          ? el.model_number === arg
          : el.model_name === arg)
          && el.turbine.split('#')[1] === this.turbine)
      if (isModNum) {
        try { return modNum.model_name } catch { return null }
      } else {
        try { return Number(modNum.model_number) } catch { return null }
      }
    },
    modelNumberExists(arg) {
      return this.$store.state.modelNumbers.some(
        el => (this.isModelNumber(arg)
            ? el.model_number === arg
            : el.model_name === arg)
          && el.turbine.split('#')[1] === this.turbine
      )
    },
    saveModelNumber() {
      if (!this.modelName) {
        this.modelName = this.findModelNumber(this.value)
      }
      if (!this.modelNumberExists(this.modelName)) {
        let modelNumberInfo = {
          'turbine': this.turbine,
          'name': this.modelName
        }
        return this.$store
          .dispatch("createModelNumber", modelNumberInfo)
          .then((result) => {
            let newModelNumber = Number(result.data.model_number)
            this.$emit('input', newModelNumber)
            return this.$store
              .dispatch("fetchModelNumbers")
              .then(() => {
                return newModelNumber
              })
          })
      } else {
          return Promise.resolve(this.findModelNumber(this.modelName))
        }
    }
  }
}
</script>

<style scoped>
  >>>.v-messages__message {
    color: blue !important;
  }
</style>