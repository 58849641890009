<template>
<v-menu
    v-model="menu"
    :close-on-content-click="false"
    :offset-y="typeof status.prevStatus != 'undefined'"
    :offset-x="!status.prevStatus"
    :nudge-left="status.prevStatus ? 50 : 0"
    :disabled="disabled"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        small
        outlined
        :disabled="disabled"
        :loading="loading"
        :color="`${status.color}`"
        style="display: flex; align-self: center"
      >
        <v-badge
          v-if="status.status !== 'Draft'"
          :color="status.color"
          icon="mdi-lock"
        >
          {{ status.status }}
        </v-badge>
        <div v-else class="text-button">
          {{ status.status }}
        </div>
      </v-btn>
    </template>
    <v-card>
      <v-card-actions class="pa-0 d-flex flex-row-reverse">
        <v-btn
          v-if="status.nextStatus"
          small
          tile
          elevation="2"
          :disabled="disabled"
          :color="`${status.nextColor}`"
          :class="!status.prevStatus ? 'ma-0 white--text' : 'ma-1 white--text'"
          @click="promptConfirm(status.nextStatus)"
        >
          <v-icon left>mdi-arrow-right-thick</v-icon>
          {{ status.nextStatus }}
        </v-btn>
        <v-btn
          v-if="status.prevStatus"
          small
          tile
          elevation="2"
          :disabled="disabled"
          :color="`${status.prevColor}`"
          class="ml-1 white--text"
          @click="promptConfirm(status.prevStatus)"
        >
          {{ status.prevStatus }}
          <v-icon right>mdi-arrow-left-thick</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm-dialog ref="confirm"/>
  </v-menu>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog"
export default {
  name: "StatusChangeButton",
  props: ["statusDates", "towerId", "disabled"],
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      menu: false,
      loading: false,
    }
  },
  computed: {
    status() {
      return this.currentStatus(this.statusDates)
    }
  },
  methods: {
    promptConfirm(status) {
      this.menu = false
      this.loading = true
      this.$refs.confirm.open(
        "Confirm Change Status",
        `Are you sure you want to change <br />Tower Status &#8594 ${status}?`
      ).then((result) => {
        let obj = { "update_status": status.toLowerCase() }
        if (result) {
          this.$store.dispatch("saveTower",
            {
              uuid: this.towerId,
              obj
            })
            .then(() => {
              this.$emit("statusChange", true)
              this.loading = false
            })
        } else {
          this.loading = false
        }
      })
    },
  }
}
</script>