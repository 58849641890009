<template>
  <v-card class="mb-4">
    <v-toolbar
      flat
      short
    >
      <v-toolbar-title>
        Region {{ sectionIndex + 1 }}.{{ regionIndex + 1 }}
      </v-toolbar-title>
      <v-btn
        small
        icon
        @click="$emit('duplicate')"
        :disabled="disabled"
      >
        <v-icon>mdi-file-multiple</v-icon>
      </v-btn>
      <v-btn
        small
        icon
        :disabled="preventDelete || disabled"
        @click="$emit('delete')"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters>
        <v-col>
          <v-row>
            <v-col>
              <v-row no-gutters>
                <v-radio-group
                  v-model="regionType"
                  mandatory
                  row
                  dense
                  :disabled="disabled"
                >
                  <v-radio
                    label="Can"
                    value="CanRegionIntent"
                    @click="minThickSwitch = false"
                  />
                  <v-radio
                    label="Spiral"
                    value="SpiralRegionIntent"
                    @click="minThickSwitch = false"
                  />
                  <v-radio
                    label="Tabular Can"
                    value="TabularCanRegionIntent"
                  />
                </v-radio-group>
              </v-row>

              <v-row v-if="regionType === 'TabularCanRegionIntent'">
                <custom-data-table
                  :title="dataTableTitle"
                  subtitle="Can Region Intent"
                  :headers="tabularCanRegionTableHeaders"
                  :items="value.cans"
                  :value-units="canRegionValueUnits"
                  :data-empty-row="{
                    base_diameter: null,
                    height: null,
                    thickness: null,
                  }"
                  :ordered-row-items="['base_diameter', 'height', 'thickness']"
                  :reverseData="true"
                  :optional-props="{
                    'custom-sort': customSort,
                    'disable-pagination': true,
                    'hide-default-footer': true,
                    'dense': true,
                    class: 'mb-4',
                  }"
                  showAddCsvBtn
                  :disabled="disabled"
                  @input="$emit('input', {
                    type: value.type,
                    cans: $event,
                  })"
                />
              </v-row>

              <v-row v-else>
                <v-col
                  v-if="regionType == 'SpiralRegionIntent'"
                  cols="3"
                >
                  <v-select
                    :items="slopeConstraints"
                    :value="value.slope_constraint"
                    @change="updateSlopeConstraint($event)"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col
                  v-if="value.slope_constraint == 'FREE'"
                  cols="3"
                >
                  <numeric-text-field
                    label="Base Diameter"
                    units="m"
                    :value="value.base_diameter"
                    required
                    @input="updateValue('base_diameter', $event, value)"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col cols="3">
                  <numeric-text-field
                    label="Height"
                    units="m"
                    :value="value.height"
                    required
                    @input="updateValue('height', $event, value)"
                    :disabled="disabled"
                  />
                </v-col>
              </v-row>
              <v-row v-if="regionType == 'SpiralRegionIntent'">
                <v-col cols="3">
                  <numeric-text-field
                    label="Shell Clocking"
                    units="deg"
                    :value="value.shell_clocking"
                    @input="updateValue('shell_clocking', $event, value)"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col cols="3">
                  <numeric-text-field
                    label="Cylinder Sheet Length"
                    units="m"
                    :value="value.cylinder_sheet_length"
                    @input="updateValue('cylinder_sheet_length', $event, value)"
                    :disabled="disabled"
                  />
                </v-col>
              </v-row>

              <template v-if="regionType !== 'TabularCanRegionIntent'">
                <v-switch
                  v-model="minThickSwitch"
                  label="Min. Thickness Constraints"
                  :disabled="disabled"
                />
                <v-btn
                  v-if="minThickSwitch"
                  icon
                  @click="addMinThickness"
                  :disabled="disabled"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-data-table
                  v-if="minThickSwitch"
                  :headers="minThickHeaders"
                  :items="value.min_thicknesses"
                  hide-default-footer
                  disable-pagination
                >
                  <template #[`item.min_thickness`]="{ item }">
                    <numeric-text-field
                      v-model="item.min_thickness"
                      units="mm"
                      :rules="[formRules.required]"
                      :disabled="disabled"
                    />
                  </template>
                  <template #[`item.anchor`]="{ item }">
                    <v-select
                      v-model="item.anchor"
                      :items="['TOP', 'BASE']"
                      :disabled="disabled"
                      :rules="[formRules.required]"
                    />
                  </template>
                  <template #[`item.vertical_offset`]="{ item }">
                    <numeric-text-field
                      v-model="item.vertical_offset"
                      units="m"
                      :rules="[formRules.required]"
                      :disabled="disabled"
                    />
                  </template>
                  <template #[`item.action`]="{ item }">
                    <v-icon
                      small
                      :disabled="disabled"
                      @click="deleteMinThickness(value.min_thicknesses.indexOf(item))"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <unit-results
            v-if="$store.state.towerIntent?.model?.sections"
            :results="regionResults"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import UnitResults from "@/components/common/UnitResults";

import can_tapered from "@/assets/can tapered.png";
import spiral_tapered from "@/assets/spiral tapered.png";

import NumericTextField from "@/components/common/NumericTextField";
import CustomDataTable from "@/components/common/CustomDataTable"
import { set } from "lodash/fp"

const emptyTabularCanRegion = () => {
  return {
    base_diameter: null,
    height: null,
    thickness: null,
  }
}

const emptyMinThickness = () => {
  return {
    min_thickness: null,
    anchor: null,
    vertical_offset: null,
  }
}

export default {
  name: "RegionIntent",
  components: {
    UnitResults,
    NumericTextField,
    CustomDataTable,
  },
  props: ["value", "regionIndex", "sectionIndex", "preventDelete", "shells", "disabled"],
  data() {
    return {
      showDialog: false,
      minThickSwitch: false,
    }
  },
  mounted() {
    let minTs = this.value?.min_thicknesses
    if (minTs == undefined) {
      this.minThickSwitch = false
    } else if (minTs?.length > 0) {
      this.minThickSwitch = true
    }
  },
  computed: {
    dataTableTitle() {
      return `Region ${this.sectionIndex + 1}.${this.regionIndex + 1}`
    },
    regionResults() {
      function getBottomTrapThickness(towerIntent, sectionIndex, regionIndex) {
        if (regionIndex > 0) {
          return {};
          } else {
            let index_counter = 0
            let base_trap_thickness = null
            for (let section = 0; section < Object.keys(towerIntent.model.sections).length; section++) {
              for (let shell = 0; shell < Object.keys(towerIntent.model.sections[section].shells).length; shell++) {
                if (section === sectionIndex && shell === regionIndex) {
                  let optimization_shell_index = index_counter
                  let optimization_shell = towerIntent.optimizations.shells[optimization_shell_index]
                  if ('thickness' in optimization_shell) {
                    // Shell is of type "CanOptimizations"
                    base_trap_thickness = optimization_shell.thickness
                  } else {
                    // Shell is of type "SpiralOptimizations"
                    base_trap_thickness = optimization_shell.thicknesses[0]
                  }
                  return {
                    key: "Base Trap Thickness",
                    value: base_trap_thickness,
                    units: "mm",
                  }
                } else {
                  ++index_counter
                }
              }
            }
          }
      }
      if (this.shells) {
        let regionHeight = 0
        let regionMass = 0
        for (let shell of this.shells) {
          regionHeight += shell["height"]
          regionMass += shell["mass"]
        }
        return [
          {
            key: "Top diameter",
            value: this.shells[this.shells.length - 1].top_diameter.toFixed(3),
            units: "m",
          },
          {
            key: "Base diameter",
            value: this.shells[0].base_diameter.toFixed(3),
            units: "m",
          },
          {
            key: "Height",
            value: regionHeight.toFixed(3),
            units: "m",
          },
          {
            key: "Mass",
            value: regionMass,
            units: "kg",
          },
          getBottomTrapThickness(this.$store.state.towerIntent, this.sectionIndex, this.regionIndex),
        ]
      } else {
        return []
      }
    },
    typeImg() {
      if (this.value.type === "SpiralRegionIntent") {
        return spiral_tapered;
      } else {
        return can_tapered;
      }
    },
    slopeConstraints () {
      return [
        { text: "Free", value: "FREE" },
        { text: "Linear taper", value: "LINEAR_TAPPER" },
      ]
    },
    regionType: {
      get() {
        return this.value.type;
      },
      set(val) {
        if (val === "TabularCanRegionIntent") {
          this.$emit("input", {
            type: val,
            cans: [emptyTabularCanRegion()],
          });
        } else {
          this.$emit("input", {
            type: val,
            base_diameter: this.value.base_diameter,
            height: this.value.height,
            slope_constraint: "FREE",
          })
        }
      },
    },
    minThickHeaders() {
      return [
        { text: "Min. Thickness (mm)", value: "min_thickness", width: "30%" },
        { text: "Anchor", value: "anchor", width: '20%' },
        { text: "Vertical Offset (m)", value: "vertical_offset", width: "30%" },
        { text: "", value: "action", sortable: false },
      ]
    },
    tabularCanRegionTableHeaders() {
      return [
        { text: "", value: "add_row", sortable: false, width: "1px" },
        { text: "Base Diameter (m)", value: "base_diameter", sortable: false },
        { text: "Height (m)", value: "height", sortable: false },
        { text: "Thickness (mm)", value: "thickness", sortable: false },
        { text: "", value: "action", sortable: false },
      ]
    },
    canRegionValueUnits() {
      return [
        { value: "base_diameter", units: "m" },
        { value: "height", units: "m" },
        { value: "thickness", units: "mm" },
      ]
    },
  },
  watch: {
    minThickSwitch(val) {
      if (!val) {
        this.updateValue('min_thicknesses', [], this.value)
      }
    }
  },
  methods: {
    deleteCan(index) {
      var temp = this.value.cans;
      temp.splice(index, 1);
      this.$emit("input", {
        type: this.value.type,
        cans: temp,
      })
    },
    addMinThickness() {
      if (!this.value.min_thicknesses) {
        this.updateValue('min_thicknesses', [emptyMinThickness()], this.value)
      } else {
        var minThicknesses = [...this.value.min_thicknesses, emptyMinThickness()]
        this.updateValue('min_thicknesses', minThicknesses, this.value)
      }
      
    },
    deleteMinThickness(index) {
      let temp = this.value.min_thicknesses
      temp.splice(index, 1)
      this.updateValue('min_thicknesses', temp, this.value)
    },
    customSort(items) {
      return items.reverse()
    },
    updateSlopeConstraint (v) {
      const newValue = set('slope_constraint', v, this.value)
      if (v == "LINEAR_TAPPER") {
        this.updateValue('base_diameter', null, newValue)
      } else {
        this.$emit("input", newValue)
      }
    },
  },
}
</script>

<style scoped>
.regionHeader {
}
.v-data-table >>> tr:hover {
  background: transparent !important;
}
</style>
