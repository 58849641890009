<template>
  <v-row no-gutters>
    <v-col cols=5>
      <v-switch
        v-model="specify"
        label="Coil Grouping"
        :disabled="disabled"
      />
    </v-col>
    <v-col cols=4>
      <v-text-field
        :value="value"
        label="Number of Thicknesses"
        v-if="specify"
        :rules="[formRules.required, formRules.positiveWholeNumber]"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NumThicknesses",
  components: {
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    specify: {
      get () {
        return !!this.value
      },
      set (v) {
        this.$emit('input', v ? 10 : null)
      },
    },
  },
}
</script>
