<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <numeric-text-field
            label="Misalignment"
            :value="value.misalignment"
            units="m/m"
            :disabled="isGlobal"
            @input="updateValue('misalignment', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Safety factor"
            :value="value.safety_factor"
            :disabled="isGlobal"
            @input="updateValue('safety_factor', $event, value)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <numeric-text-field
            label="Bolt safety factor"
            :value="value.safety_factor_bolt"
            :disabled="isGlobal"
            @input="updateValue('safety_factor_bolt', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Tower partial load factor favorable"
            :value="value.tower_partial_load_factor_favorable"
            :disabled="isGlobal"
            @input="updateValue('tower_partial_load_factor_favorable', $event, value)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "IecFlangeAnalyzer",
  components: {
    NumericTextField,
  },
  props: ["value", "isGlobal"],
}
</script>
