import Vue from "vue"
import moment from "moment"
import _ from 'lodash/fp'
import {schemaAdjust} from '@/utils/fp'

Vue.mixin({
    computed: {
        formRules() {
            return {
                required: (v) =>
                       (typeof v === 'string' && !!v.trim())
                    || (typeof v === 'number')
                    || (Array.isArray(v) && v.length > 0)
                    || "This field is required.",
                positiveWholeNumber: (v) =>
                    /^[0-9]+$/.test(v) || "Must be a positive whole number.",
                filename: (v) =>
                    /^[^\\/"]+$/.test(v) || "Must not contain any of / \\ \""
            };
        },
    },
    methods: {
        // String -> String
        // Turns an ISO8601 datetime into a human-readable time distance from
        // from now.
        // Example:
        //   datetimeToDistance('1981-05-16T16:13:00Z') == '38 years ago'
        datetimeToDistance: (str) => {
            if (str) {
                return moment(str).fromNow();
            } else {
                return "";
            }
        },
        // ArrayOf String | String ->  Any -> Array | Object -> undefined
        // Emits a message of input of the given object updated functionally
        // at the given deep path with the new value.
        updateValue (path, newValue, obj) {
            const newObj = newValue === null
                ? _.unset(path, obj)
                : _.set(path, newValue, obj)
            this.$emit("input", newObj)
        },
        updateType (schemas, newType) {
          const schema = schemas.find(a => a.type == newType)
          const newObj = {...this.value, type: newType}
          this.$emit("input", schemaAdjust(newObj, schema))
        },
        routeToLibrary(libraryRoute, uuid) {
            return this.$router.push({
              name: "edit-" + libraryRoute,
              params: { uuid: uuid },
            })
        },
        /**
         * Checks the current item's status
         * @param  {Object} status_dates - Item's top-level "status_dates" attr
         * @return {Object} Statuses with corresponding status colors
         */
        currentStatus(status_dates) {
            let approved = {
                status: "Approved",
                color: "#0091EA"
            }
            let released = {
                status: "Released",
                color: "#00C853",
                nextStatus: approved.status,
                nextColor: approved.color,
            }
            let candidate = {
                status: "Candidate",
                color: "#FF9800",
            }
            let draft = {
                status: "Draft",
                color: "#90A4AE",
                nextStatus: candidate.status,
                nextColor: candidate.color,
            }
            candidate = {
                ...candidate,
                nextStatus: released.status,
                nextColor: released.color,
                prevStatus: draft.status,
                prevColor: draft.color
            }
            if (!status_dates) {
                return draft
            }
            switch(true) {
                case "approved" in status_dates:
                  return approved
                case "released" in status_dates:
                  return released
                case "candidate" in status_dates:
                  return candidate
                default:
                  return draft
            }
        },
        statusColor(status) {
            let color_map = {
              "approved": "#0091EA",
              "released": "#00C853",
              "candidate": "#FF9800",
              "draft": "#90A4AE",
            }
            return color_map[status]
        },
        /**
         * Checks if tower is a draft
         * @param  {Object} status_dates - Tower's top-level "status_dates" attr
         * @return {Boolean} true if tower is a draft
         */
        checkIfTowerDraft(status_dates) {
            if (_.isEmpty(status_dates) || status_dates === undefined) {
                return true
            } else {
                return Object.keys(status_dates).length === 0
            }
        },
        /**
         * Uses internalNotes if exists; else uses notesList
         * converted into a string separated by new lines characters
         * @param  {Array} notesList - Legacy list of notes usually from value.notes
         * @param  {String} internalNotes - internal notes usually from value.internal_notes
         */
        handleInternalNotes(notesList, internalNotes) {
            if (!internalNotes && notesList) {
                return !_.isEmpty(notesList) ? notesList.join('\n') : ''
            }
            return internalNotes ?? ''
        },
         /**
         * Checks if item has tower ref not in 'draft' status
         * @param  {Array} tower_references - List of tower info [(uuid, name, status), ...]
         * @return {Boolean} true if non-draft tower found in refs
         */
         isLockedItem(tower_references) {
            if (tower_references) {
                for (let tower of tower_references) {
                    if (tower[2] !== "draft") {
                        return true
                    }
                }
            }
            return false
        },
    },
});
