var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": "",
      "required": "",
      "value": _vm.value.anchor,
      "disabled": _vm.disabled
    },
    on: {
      "change": function change($event) {
        return _vm.updateValue('anchor', $event, _vm.value);
      }
    }
  }, [_c('div', {
    attrs: {
      "v:slot": "label"
    }
  }, [_vm._v(" Anchor ")]), _c('v-radio', {
    attrs: {
      "label": "Top",
      "value": "TOP"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Bottom",
      "value": "BASE"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('comma-separated-list', {
    attrs: {
      "label": "Vertical offsets",
      "units": "m",
      "value": _vm.value.vertical_offsets,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('vertical_offsets', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('radial-pattern', {
    attrs: {
      "value": _vm.value.radial_pattern,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('radial_pattern', $event, _vm.value);
      }
    }
  })], 1), _c('v-row', [_c('trait', {
    attrs: {
      "value": _vm.value.trait,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('trait', $event, _vm.value);
      }
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-icon', {
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_vm._v(" mdi-delete ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }