<template>
  <v-row>
    <v-col
      v-if="!hideShape"
      cols="2"
    >
      <v-radio-group
        :value="value.shape"
        mandatory
        row
        :disabled="disabled"
        @change="updateValue('shape', $event, value)"
      >
        <v-radio
          label="L"
          value="L"
        />
        <v-radio
          label="T"
          value="T"
        />
      </v-radio-group>
    </v-col>
    <v-col>
      <numeric-text-field
        label="Neck Height"
        :value="value.neck_height"
        units="mm"
        :disabled="disabled"
        @input="updateValue('neck_height', $event, value)"
      />
    </v-col>
    <v-col>
      <numeric-text-field
        label="Neck Thickness"
        :value="value.neck_thickness"
        units="mm"
        :disabled="disabled"
        @input="updateValue('neck_thickness', $event, value)"
      />
    </v-col>
    <v-col>
      <numeric-text-field
        label="Plate Height"
        :value="value.plate_height"
        units="mm"
        :disabled="disabled"
        @input="updateValue('plate_height', $event, value)"
      />
    </v-col>
    <v-col>
      <numeric-text-field
        label="Plate Width"
        :value="value.plate_width"
        units="mm"
        :disabled="disabled"
        @input="updateValue('plate_width', $event, value)"
      />
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "FlangeProfile",
  components: {
    NumericTextField,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideShape: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
