var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._b({
    attrs: {
      "suffix": _vm.units,
      "rules": _vm.rules,
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.displayValue,
      callback: function callback($$v) {
        _vm.displayValue = $$v;
      },
      expression: "displayValue"
    }
  }, 'v-text-field', _vm.$attrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }