<template>
  <v-card
    class="mb-4"
    :style="{
      width: (headers.length <= 4) ? '450px' : '100%'
    }"
  >
    <v-alert
      v-if="alertMessage"    
      type="warning"
      dense
    >
      {{ alertMessage }}
    </v-alert>
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="showAddCsvBtn"
            icon
            :loading="isSelecting"
            @click="handleCsvImport()"
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            >
            <v-icon color="primary">
              mdi-table-large-plus
            </v-icon>
          </v-btn>
        </template>
        Add Data from CSV
      </v-tooltip>
      <input
        id="file"
        ref="uploader"
        class="d-none"
        type="file"
        accept=".csv"
        @change="onFileChanged($event)"
      >
    </v-card-title>
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
    <v-card-actions v-if="items.length === 0">
      <v-btn
        icon
        :disabled="disabled"
        @click="addElement()"
      >
        <v-icon left>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>
    <v-data-table
      :headers="headers"
      :items="items"
      v-bind="optionalProps"
      disable-pagination
    >
      <!-- Add Row Button -->
      <template #[`item.add_row`]="{ index }">
        <v-col
          v-if="!disabled"
          class="pa-0 ma-0 d-flex"
          style="height: 100%;"
        >
          <v-btn
            v-if="index === 0"
            fab
            x-small
            elevation="3"
            class="align-self-start"
            style="transform:translateY(-17px)"
            @click="addElement()"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            fab
            x-small
            elevation="3"
            class="align-self-end"
            style="
              transform:translateY(17px);
              position:absolute;
              z-index: 2
            "
            @click="insertElementAt(index)"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </template>

      <!-- Optional: Name Text Field -->
      <!-- Used in Turbine>Excluded Frequencies -->
      <template #[`item.name`]="{ item }">
        <v-text-field
          v-model="item.name"
          reverse
          style="width: 12rem;"
          :disabled="disabled"
        />
      </template>

      <!-- Numeric Text Field -->
      <template
        v-for="header in valueUnits"
        #[`item.${header.value}`]="{ item }"
      >
        <numeric-text-field
          :key="header.value"
          v-model="item[header.value]"
          :units="header.units ? header.units : null"
          required
          :disabled="disabled"
          @input="numericFieldChange()"
          style="width: 8rem; padding-top: 0;"
        />
      </template>

      <!-- Optional: warn_only checkbox -->
      <!-- Used in Turbine>Excluded Frequencies -->
      <template #[`item.warn_only`]="{ item }">
        <v-simple-checkbox
          v-model="item.warn_only"
          :ripple="false"
          :disabled="disabled"
        />
      </template>

      <!-- Delete Row Button -->
      <template #[`item.action`]="{ item }">
        <v-btn
          v-if="!disabled"
          icon
          @click="deleteElement(items.indexOf(item))"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "CustomDataTable",
  components: {
    NumericTextField,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    valueUnits: {
      type: Array,
    },
    dataEmptyRow: {
      type: Object,
    },
    orderedRowItems: {
      type: Array,
    },
    reverseData: {
      type: Boolean,
    },
    optionalProps: {
      type: Object,
    },
    showAddCsvBtn: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  data(){
    return {
      isSelecting: false,
      selectedFile: null,
      alertMessage: null,
    }
  },
  methods: {
    addElement() {
      if (this.reverseData) {
        this.$emit("input", [
          ...this.items,
          { ...this.dataEmptyRow }
        ])
      } else {
        this.$emit("input", [
          { ...this.dataEmptyRow },
          ...this.items
        ])
      }
    },
    insertElementAt(index) {
        let temp = [...this.items]
        let fixedIndex;
        if (this.reverseData) {
            // If the data-table reverses the data
            // We must set the correct index for insert
            fixedIndex = this.items.length - (index + 1)
        }
        else {
            fixedIndex = index + 1
        }
        this.$emit("input", [
            ...temp.slice(0, fixedIndex),
            { ...this.dataEmptyRow },
            ...temp.slice(fixedIndex)
        ])
    },
    deleteElement(index) {
        let temp = [...this.items]
        temp.splice(index, 1)
        this.$emit("input", temp)
    },
    numericFieldChange() {
      this.$emit('input', this.items)
    },
    customSort(items) {
      return items.reverse()
    },
    handleCsvImport() {
      this.alertMessage = null
      this.isSelecting = true
      // Return btn to normal state after closing file select
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      const reader = new FileReader();
      reader.readAsText(this.selectedFile)
      reader.onload = (e) => {
        let csvAsArray = e.target.result.split("\n").map((line) => {
          return line.split(",")
        })
        csvAsArray.pop()
        let anyNaN = false
        for (let cell of csvAsArray) {
          if (isNaN(cell)) {
            anyNaN = true
          }
        }
        this.updateDataTable(anyNaN ? csvAsArray.slice(1) : csvAsArray)
      }
    },
    updateDataTable(csvAsArray) {
      if (this.orderedRowItems.length !== csvAsArray[0].length) {
        this.alertMessage = "Data Table Warning: CSV column count does not match data table column count."
        this.$refs.uploader.value = ""
        return;
      }
      let data = []
      csvAsArray.forEach((row) => {
        let item = {}
        this.orderedRowItems.map((x, i) => {
          return item[x] = Number(row[i])
        })
        data.push(item)
      })
      if (this.reverseData) {
        this.$emit('input', data.reverse())  
      } else {
        this.$emit('input', data)
      }
      this.$refs.uploader.value = ""
    },
  },
}
</script>