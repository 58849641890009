var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('sn-curve', {
    attrs: {
      "value": _vm.value.sn_curve,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('sn_curve', $event, _vm.value);
      }
    }
  }), _c('knockdowns', {
    attrs: {
      "value": _vm.value.knockdowns,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('knockdowns', $event, _vm.value);
      }
    }
  }), _c('traits', {
    attrs: {
      "value": _vm.value.trait_types,
      "is-global": _vm.isGlobal
    },
    on: {
      "input": function input($event) {
        return _vm.updateValue('trait_types', $event, _vm.value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }