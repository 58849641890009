<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-col cols="3">
          <v-switch
            v-model="optimize"
            label="Optimize"
            :disabled="disabled"
          />
        </v-col>
        <v-col>
          <v-radio-group
            v-model="shape"
            label="Shape"
            mandatory
            row
            :disabled="disabled"
          >
            <v-radio
              label="L"
              value="L"
            />
            <v-radio
              label="T"
              value="T"
            />
          </v-radio-group>
        </v-col>
        <v-spacer />
      </v-row>
      <template v-if="!optimize">
        <v-row no-gutters>
          <v-col cols="3">
            <v-select
              label="Material"
              :items="$store.state.materials"
              item-text="data.name"
              item-value="data"
              :value="value.material"
              :disabled="disabled"
              @change="updateValue('material', $event, value)"
            />
          </v-col>
        </v-row>
        <bolt-circle
          :value="value.bolt_circle"
          :disabled="disabled"
          @input="updateValue('bolt_circle', $event, value)"
        />
        <flange-profile
          v-if="!optimize"
          :hide-shape="true"
          :value="value.profile"
          :disabled="disabled"
          @input="updateValue('profile', $event, value)"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash"
import FlangeProfile from "@/components/FlangeProfile"
import BoltCircle from "@/components/BoltCircle"

export default {
  name: "FlangeSpec",
  components: {
    FlangeProfile,
    BoltCircle,
  },
  props: ["value", "disabled"],
  computed: {
    shape: {
      get() {
        if (this.value === undefined) {
          return "L"
        } else if (this.value === "L" || this.value === "T") {
          return this.value
        } else {
          return this.value.profile.shape
        }
      },
      set(val) {
        if (typeof this.value === "object") {
          let temp = _.cloneDeep(this.value)
          temp.profile.shape = val
          this.$emit("input", temp)
        } else {
          this.$emit("input", val)
        }
      },
    },
    optimize: {
      get() {
        return typeof this.value != "object"
      },
      set(val) {
        if (val) {
          this.$emit("input", this.value.profile.shape)
        } else {
          const blank = {
            material: {},
            profile: { shape: this.shape },
            bolt_circle: {},
          };
          this.$emit("input", blank)
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch("fetchMaterials")
  },
}
</script>
