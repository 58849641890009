<template>
  <v-text-field
    v-model="localValue"
    :dense="dense"
    :label="label"
    reverse
    :prefix="units"
    :disabled="disabled"
    :rules="localRules"
    class="numeric-text-field"
    :error="error"
    :error-messages="errorMessages"
    v-bind="$attrs"
    @blur="compute"
    @keydown.enter="compute"
    @focus="disableRules"
    @input="disableRules"
  />
</template>

<script>
import { create, all } from "mathjs";
const math = create(all);

export default {
  name: "NumericTextField",
  props: {
    value: Number,
    units: String,
    label: String,
    disabled: Boolean,
    rules: Array,
    required: Boolean,
    dense: Boolean,
  },
  data() {
    return {
      localRules: this.rules,
      localValue: this.value?.toString(),
      error: false,
      errorCount: null,
      errorMessages: null,
    };
  },
  watch: {
    value(v) {
      this.localValue = v?.toString()
    },
  },
  methods: {
    compute() {
      this.clearError();
      try {
        if (this.localValue === undefined
            || this.localValue === null
            || this.localValue.trim() === "") {
          if (this.required) {
            this.displayError("This field is required.");
          } else {
            this.$emit("input", null)
          }
        } else {
          const result = math.evaluate(this.localValue);
          const n =
            typeof result === "number"
              ? result
              : result.to(this.units).toNumber();
          this.localValue = n.toString()
          this.$emit("input", n);
        }
        this.enableRules();
      } catch (e) {
        this.displayError(e.message);
      }
    },
    disableRules() {
      this.localRules = [];
    },
    enableRules() {
      this.localRules = this.rules;
    },
    displayError(message) {
      this.error = true;
      this.errorMessages = [message];
    },
    clearError() {
      this.error = false;
      this.errorMessages = [];
    },
  },
}
</script>
