<template>
  <v-container fluid>
    <v-alert
      v-if="showWarning && $route.name === 'edit-load-set'"
      type="warning"
      dismissible
      class="mb-2"
    >
      <ul>
        <li
          v-for="(warning, index) in warnings"
          :key="index"
        >
          {{ warning }}
        </li>
      </ul>
    </v-alert>
    <div v-if="$route.name === 'create-load-set'">
      <load-set
        :value="value"
        ref="loadSetRef"
        @input="$emit('input', $event)"
        @validityChange="$emit('validityChange', $event)"
      />
    </div>
    <div v-else>
      <load-set
        v-if="value"
        :editLoadset=true
        :value="value"
        ref="loadSetRef"
        :disabled="disabled"
        @input="$emit('input', $event)"
        @validityChange="$emit('validityChange', $event)"
      />
      <v-progress-circular
        v-else
        indeterminate
        width="3"
        size="30"
        color="primary"
        style="width:100%"
        class="d-flex justify-center my-2"
      >
        Loading LoadSet...
      </v-progress-circular>
      <div
        v-if="moreThanOneLoadCase"
        class="mt-2"
        style="text-align:center;" 
      >
        Cannot display S3 file - More than one load type found<br />
        <strong>One load type per file is required for standardized input</strong>
      </div>
      <div
        v-else-if="loadSetS3 === null"
        class="mt-2"
        style="text-align:center;" 
      >
        <v-btn icon large @click="handleDownloadCSV">
          <v-icon color="primary">mdi-download</v-icon>
        </v-btn><br />
        <strong>Error Loading S3 File - Invalid Format</strong><br />
        Unexpected column or expected column not found.<br />
      </div>
      <v-card
        v-else-if="loadSetS3 || loadTypeIsMarkov"
        elevation="3"
        max-width="1000px"
        class="mt-4"
        style="margin:0 auto;"
      >
        <v-card-title class="pb-2">
          <div class="mr-4">{{value.load_set_type}} LoadSet Data</div>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                :loading="downloading"
                @click="handleDownloadCSV"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            Download CSV
          </v-tooltip>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-card-subtitle
          v-if="loadTypeIsMarkov"
        >
          Note: Downloading Markov data may take a few minutes
        </v-card-subtitle>
        <v-data-table
          v-if="loadTypeIsMarkov"
          :headers="[]"
          :items="[]"
          hide-default-footer
          no-data-text="No preview available - Markov data exceeds browser capacity"
        />
        <v-data-table
          v-else
          :headers="loadSetHeaders"
          :items="loadSetS3"
          loading-text="Loading LoadSet..."
          :caption="!loadSetType && !headersExist ? 'Load Set Type needs to be selected & saved for data preview' : ''"
          :search="search"
        />
      </v-card>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash"
import LoadSet from "@/components/LoadSet"

export default {
  name: "EditLoadSet",
  components: {
    LoadSet,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    extraData: {
      type: [Array, String],
      default: null,
    },
    warnings: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      downloading: false,
    }
  },
  computed: {
    loadSetHeaders() {
      switch (this.value.load_set_type) {
        case "Buckling":
          return this.bucklingHeaders
        case "Damage Equivalent":
          return this.damageEqHeaders
        case "Markov":
          return this.markovHeaders
        case "Thickness":
          return this.thicknessHeaders
        default:
          return []
      }
    },
    headersExist() {
      return !_.isEmpty(this.loadSetHeaders)
    },
    bucklingHeaders() {
      return [
        { text: "elevation (m)", value: "elevation" },
        { text: "f_x (kN)", value: "f_x" },
        { text: "f_y (kN)", value: "f_y" },
        { text: "f_z (kN)", value: "f_z" },
        { text: "m_x (kNm)", value: "m_x" },
        { text: "m_y (kNm)", value: "m_y" },
        { text: "m_z (kNm)", value: "m_z" },
        { text: "windspeed (m/s)", value: "windspeed" },
        { text: "partial_load_factor", value: "partial_load_factor" },
        { text: "load_case", value: "load_case" },
      ]
    },
    damageEqHeaders() {
      return [
        { text: "elevation (m)", value: "elevation" },
        { text: "moment_delta (kNm)", value: "moment_delta" },
      ]
    },
    markovHeaders() {
      return [
        { text: "elevation (m)", value: "elevation" },
        { text: "width", value: "width" },
        { text: "mean", value: "mean" },
        { text: "n", value: "n" },
      ]
    },
    thicknessHeaders() {
      return [
        { text: "elevation (m)", value: "elevation" },
        { text: "min_thickness (mm)", value: "min_thickness" },
      ]
    },
    loadTypeIsMarkov() {
      return  ['Markov', 'Markov ZIP'].includes(this.value.load_set_type)
    },
    loadSetS3() {
      // If data is a string then the file is a legacy loadset s3 file
      // The columns may not follow the format
      if (typeof this.extraData === 'string') {
        return null
      }
      return this.extraData
    },
    moreThanOneLoadCase() {
      if (this.value?.load_case_counts) {
        return Object.keys(this.value?.load_case_counts).length !== 1
      } else {
        return false
      }
    },
    loadSetType() {
      return this.value?.load_set_type
    },
    showWarning() {
      return !_.isEmpty(this.warnings)
    }
  },
  methods: {
    downloadCsv(data, fileName) {
      // Helper function to handleDownloadCSV
      // Converts csv flask response to browser download
      const a = document.createElement("a")
      document.body.appendChild(a)
      a.style = "display: none"
      const blob = new Blob([data], {type: "octet/stream"})
      a.href = window.URL.createObjectURL(blob)
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(a.href)
    },
    handleDownloadCSV() {
      this.downloading = true
      const csvName = this.value.name
      this.$store.dispatch("downloadLoadSetCSV", this.$route.params.uuid)
        .then((csvData) => {
          this.downloadCsv(csvData, `${csvName}.csv`)
        })
        .finally(() => this.downloading = false)
    },
    saveLoadSet() {
      // Ref method for CrudView
      return this.$refs.loadSetRef.handleSave()
    },
    uploadLoadSet() {
      // Ref method for CrudView
      return this.$refs.loadSetRef.handleUpload()
    },
  },
}
</script>
