<template>
  <v-container>
    <v-card
      elevation="24"
      class="my-1"
    >
      <v-card-title class="headline v-subheader justify-center">
        Inventory
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Name"
              :value="value.name"
              :disabled="disabled"
              @input="updateValue('name', $event, value)"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-select
              v-model="material"
              label="Material"
              :items="materialNames"
              required
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="3">
            <numeric-text-field
              label="Nominal Width"
              units="m"
              :value="value.nominal_width"
              required
              :disabled="disabled"
              @input="updateValue('nominal_width', $event, value)"
            />
          </v-col>
          <v-col cols="3">
            <numeric-text-field
              label="Min Width"
              units="m"
              :value="value.min_width"
              required
              :disabled="disabled"
              @input="updateValue('min_width', $event, value)"
            />
          </v-col>
          <v-col cols="3">
            <numeric-text-field
              label="Max Length"
              units="m"
              :value="value.max_length"
              required
              :disabled="disabled"
              @input="updateValue('max_length', $event, value)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="24" class="my-1">
      <v-card-title class="headline v-subheader justify-center">
        Thicknessess
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row>
              <custom-data-table
                :headers="thickessRangeHeaders"
                :items="value?.thickness_ranges??[]"
                :value-units="thicknessRangeValueUnits"
                :data-empty-row="thicknessRangeEmptyRow"
                :optional-props="{
                  'disable-pagination': true,
                  'hide-default-footer': true,
                  'dense': true,
                }"
                :disabled="disabled"
                @input="updateValue('thickness_ranges', $event, value)"
              />
            </v-row>
            <v-row>
              <v-col cols="9">
                <comma-separated-list
                  :value="value.thicknesses"
                  label="Legacy Comma-separated Thicknesses"
                  units="mm"
                  :disabled="disabled"
                  @input="updateValue('thicknesses', $event, value)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"
import CommaSeparatedList from "@/components/common/CommaSeparatedList"
import CustomDataTable from "@/components/common/CustomDataTable"

export default {
  name: "SheetInventory",
  components: {
    NumericTextField,
    CommaSeparatedList,
    CustomDataTable,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    materials() {
      return this.$store.state.materials.map((item) => item.data)
    },
    materialNames () {
      return this.materials.map((item) => item.name)
    },
    material: {
      get () {
        return this.value.material?.name
      },
      set (v) {
        const m = this.materials.find((item) => item.name === v)
        this.updateValue('material', m, this.value)
      },
    },
    thickessRangeHeaders() {
      return [
        { text: "", value: "add_row", sortable: false, width: "1px" },
        { text: "Start", value: "start" },
        { text: "Stop", value: "stop" },
        { text: "Step", value: "step" },
        { text: "", value: "action", sortable: false },
      ]
    },
    thicknessRangeValueUnits() {
      return [
        { value: "start", units: "mm" },
        { value: "stop", units: "mm" },
        { value: "step", units: "mm" },
      ]
    },
    thicknessRangeEmptyRow() {
      return {
        start: null,
        stop: null,
        step: null,
      }
    },
  },
  mounted() {
    this.$store.dispatch("fetchMaterials")
  },
}
</script>
