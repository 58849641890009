<template>
  <v-card
    width="100%"
    elevation="3"
  >
    <v-card-title
      class="subtitle-1"
      style="padding-bottom: 0"
    >
      Regional safety factors
    </v-card-title>
    <v-card-actions>
      <v-btn
        rounded
        small
        :disabled="isGlobal"
        @click="addSafetyFactor()"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Add SRF
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-card
        v-for="(sFactor, idx) in value"
        :key="idx"
        width="100%"
        class="safetyfactors"
      >
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                :value="sFactor.name"
                label="Name"
                :rules="[formRules.required]"
                :disabled="isGlobal"
                @input="updateValue([idx, 'name'], $event, value)"
              />
            </v-col>
            <v-col>
              <v-select
                label="Regional safety factor"
                :items="safetyFactorSchemas.map(s => s.type)"
                :value="sFactor.type"
                :disabled="isGlobal"
                @input="updateType(idx, $event)"
              />
            </v-col>
            <v-btn
              :disabled="isGlobal"
              icon
              @click="deleteSafetyFactor(idx)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-row>
          <v-row dense>
            <v-col v-if="['ConstSafetyFactor', 'RampingSafetyFactor'].includes(sFactor.type)">
              <numeric-text-field
                label="Factor"
                :value="sFactor.factor"
                :disabled="isGlobal"
                @input="updateValue([idx, 'factor'], $event, value)"
              />
            </v-col>
            <template v-if="sFactor.type === 'RampingSafetyFactor'">
              <v-col>
                <numeric-text-field
                  label="Low"
                  units="m"
                  :value="sFactor.low"
                  :disabled="isGlobal"
                  @input="updateValue([idx, 'low'], $event, value)"
                />
              </v-col>
              <v-col>
                <numeric-text-field
                  label="High"
                  units="m"
                  :value="sFactor.high"
                  :disabled="isGlobal"
                  @input="updateValue([idx, 'high'], $event, value)"
                />
              </v-col>
              <v-col>
                <numeric-text-field
                  label="Baseline"
                  :value="sFactor.baseline"
                  :disabled="isGlobal"
                  @input="updateValue([idx, 'baseline'], $event, value)"
                />
              </v-col>
            </template>
          </v-row>
          <template v-if="sFactor.type === 'ElevationMapSafetyFactor'">
            <v-row>
              <numeric-text-field
                label="Max delta"
                :value="sFactor.max_delta"
                units="m"
                :disabled="isGlobal"
                @input="updateValue([idx, 'max_delta'], $event, value)"
              />
            </v-row>
            <v-row>
              <elevation-mapping
                :value="sFactor.safety_factor_map"
                :disabled="isGlobal"
                @input="updateValue([idx, 'safety_factor_map'], $event, value)"
              />
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"
import ElevationMapping from "@/components/design/ElevationMapping"
import {dropIndex, schemaAdjust} from "@/utils/fp"
import {set} from 'lodash/fp'

export default {
  name: "SafetyFactors",
  components: {
    NumericTextField,
    ElevationMapping,
  },
  props: ["value", "isGlobal"],
  computed: {
    safetyFactorSchemas () {
      return [
        {
          type: "ConstSafetyFactor",
          name: undefined,
          factor: undefined,
        },
        {
          type: "ElevationMapSafetyFactor",
          name: undefined,
          safety_factor_map: [],
          max_delta: undefined,
        },
        {
          type: "RampingSafetyFactor",
          name: undefined,
          low: undefined,
          high: undefined,
          factor: undefined,
          baseline: undefined,
        },
      ]
    },
  },
  methods: {
    addSafetyFactor () {
      this.$emit("input", this.value.concat({}))
    },
    deleteSafetyFactor (idx) {
      this.$emit("input", dropIndex(this.value, idx))
    },
    updateType (idx, newType) {
      const schema = this.safetyFactorSchemas.find(a => a.type == newType)
      const newObj = schemaAdjust(
        {...this.value[idx], type: newType},
        schema)
      this.$emit("input", set(idx, newObj, this.value))
    },
  },
}
</script>
