<template>
  <v-container>
    <v-row>
      <v-select
        label="SCF rule"
        :items="scfRuleSchemas.map((schema) => schema.type)"
        :value="value.type"
        :disabled="disabled"
        :rules="[formRules.required]"
        @input="updateType($event)"
      />
    </v-row>

    <v-row v-if="value.type === 'ConstSCFRule'">
      <numeric-text-field
        label="Factor"
        :value="value.factor"
        :disabled="disabled"
        @input="updateValue('factor', $event, value)"
      />
    </v-row>

    <v-row v-if="value.type === 'TaperDeltaSCFRule'">
      <v-col>
        <numeric-text-field
          label="Constant factor"
          :value="value.const_factor"
          :disabled="disabled"
          @input="updateValue('const_factor', $event, value)"
        />
      </v-col>
      <v-col>
        <numeric-text-field
          label="Delta factor"
          :value="value.delta_factor"
          :disabled="disabled"
          @input="updateValue('const_factor', $event, value)"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="
        ['MinThicknessSCFRule', 'NearestThicknessSCFRule'].includes(
          value.type
        )
      "
    >
      <v-btn
        icon
        :disabled="disabled"
        @click="addThickness()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-data-table
        :headers="scfHeaders"
        :items="value.thickness_mapping"
        disable-pagination
        hide-default-footer
        disable-sort
      >
        <template #item="{ item, index }">
          <tr>
            <td>
              <numeric-text-field
                units="mm"
                :value="item.thickness"
                required
                :disabled="disabled"
                @input="
                  updateValue(
                    ['thickness_mapping', index, 'thickness'],
                    $event,
                    value
                  )
                "
              />
            </td>
            <td>
              <numeric-text-field
                :value="item.scf"
                required
                :disabled="disabled"
                @input="
                  updateValue(
                    ['thickness_mapping', index, 'scf'],
                    $event,
                    value
                  )
                "
              />
            </td>
            <td>
              <v-icon
                small
                :disabled="disabled"
                @click="deleteThickness(index)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField";
import { dropIndex, schemaAdjust } from "@/utils/fp";

export default {
  name: "ScfRule",
  components: {
    NumericTextField,
  },
  props: ["value", "disabled"],
  computed: {
    scfHeaders() {
      return [
        { text: "Thickness (mm)", value: "thickness" },
        { text: "SCF", value: "scf" },
        { text: "", value: "action" },
      ];
    },
    scfRuleSchemas() {
      return [
        {
          type: "ConstSCFRule",
          factor: undefined,
        },
        {
          type: "TaperDeltaSCFRule",
          const_factor: undefined,
          delta_factor: undefined,
        },
        {
          type: "MinThicknessSCFRule",
          thickness_mapping: [],
        },
        {
          type: "NearestThicknessSCFRule",
          thickness_mapping: [],
        },
      ];
    },
  },
  methods: {
    addThickness() {
      this.updateValue(
        "thickness_mapping",
        (this.value.thickness_mapping ?? []).concat({}),
        this.value
      );
    },
    deleteThickness(idx) {
      this.updateValue(
        "thickness_mapping",
        dropIndex(this.value.thickness_mapping, idx),
        this.value
      );
    },
    updateType(newType) {
      const schema = this.scfRuleSchemas.find((a) => a.type == newType);
      const newObj = { ...this.value, type: newType };
      this.$emit("input", schemaAdjust(newObj, schema));
    },
  },
};
</script>
