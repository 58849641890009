<template>
  <v-container fluid>
    <sn-curve
      :value="value.sn_curve"
      :is-global="isGlobal"
      @input="updateValue('sn_curve', $event, value)"
    />
    <v-row dense>
      <v-col md="6">
        <traits
          :value="value.trait_types"
          :is-global="isGlobal"
          @input="updateValue('trait_types', $event, value)"
        />
      </v-col>
      <v-col md="6">
        <safety-factors
          :value="value.regional_safety_factors"
          :is-global="isGlobal"
          @input="updateValue('regional_safety_factors', $event, value)"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="6">
        <knockdowns
          :value="value.knockdowns"
          :is-global="isGlobal"
          @input="updateValue('knockdowns', $event, value)"
        />
      </v-col>
      <v-col md="6">
        <v-card width="100%">
          <v-card-title
            class="subtitle-1"
            style="padding-bottom: 0"  
          >
            Sources
          </v-card-title>
          <v-card-actions>
            <v-btn
              rounded
              small
              :disabled="isGlobal"
              @click="addSource()"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add Source
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-row
              v-for="(source, idx) in value.sources"
              :key="idx"
              dense
            >
              <v-col
                md="11"
                class="p-0 m-0"
              >
                <v-select
                  :items="sources"
                  label="Source"
                  :value="value.sources[idx]"
                  :disabled="isGlobal"
                  :rules="[formRules.required]"
                  @input="updateValue(['sources', idx], $event, value)"
                />
              </v-col>
              <v-col 
                md="1"
                cols="auto"
                class="align-self-center"
              >
                <v-btn
                  :disabled="isGlobal"
                  icon
                  @click="deleteSource(idx)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>  
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Traits from "@/components/design/Traits"
import Knockdowns from "@/components/design/Knockdowns"
import SnCurve from "@/components/design/SnCurve"
import SafetyFactors from "@/components/design/SafetyFactors"
import { dropIndex } from "@/utils/fp"

export default {
  name: "MarkovEquivalentAnalyzer",
  components: {
    Traits,
    Knockdowns,
    SnCurve,
    SafetyFactors,
  },
  props: ["value", "isGlobal"],
  data() {
    return {
      sources: ["FLANGE", "CAN", "SPIRAL"],
    }
  },
  methods: {
    addSource() {
      this.$emit('input', { ...this.value, sources: this.value.sources.concat(null)})
    },
    deleteSource (idx) {
      this.$emit('input', { ...this.value, sources: dropIndex(this.value.sources, idx) })
    },
  },
}
</script>

<style scoped>
.safetyfactors.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0;
  height: 0;
  visibility: hidden;
}
</style>
