<template>
  <v-row>
    <v-col>
      <v-card elevation="24">
        <v-card-text>
          <v-row no-gutters>      
            <v-col cols="12" md="8">
              <v-text-field
                label="Name"
                :value="value.name"
                dense
                outlined
                :disabled="disabled"
                :rules="[formRules.required, formRules.filename]"
                @input="updateValue('name', $event, value)"
              />
            </v-col>
            <v-col cols="12" md="3" :class="{'ml-3': $vuetify.breakpoint.mdAndUp}">
             <model-number-select   
                outlined
                ref="modelNumberRef"
                :disabled="disabled"
                :turbine="value.turbine"
                :value="value.model_number"
                @input="updateValue('model_number', $event, value)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <library-select 
                :value="value.design_basis"
                label="Design Basis"
                route="design-basis"
                :items="$store.state.designBases"
                require-link
                :disabled="disabled"
                @input="updateValue('design_basis', $event, value)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <library-select 
                :value="value.can_inventory" 
                label="Can Materials"
                route="inventory"
                :items="$store.state.inventories"
                require-link
                :disabled="disabled"
                @input="updateValue('can_inventory', $event, value)"
              />
            </v-col>
            <v-col cols="6">
              <library-select 
                :value="value.spiral_inventory" 
                label="Spiral Materials"
                route="inventory"
                :items="$store.state.inventories"
                require-link
                :disabled="disabled"
                @input="updateValue('spiral_inventory', $event, value)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <comma-separated-list
                :value="value.corner_shifts"
                label="Corner Shifts"
                units="mm"
                data-lpignore="true"
                dense
                :disabled="disabled"
                @input="updateValue('corner_shifts', $event, value)"
              />
            </v-col>
            <v-col cols="3">
              <numeric-text-field
                label="Boss Min Distance"
                units="mm"
                placeholder="67"
                :value="value.boss_min_dist"
                :disabled="disabled"
                @input="updateValue('boss_min_dist', $event, value)"
                dense
              />
            </v-col>
            <v-col cols="3">
              <numeric-text-field
                label="Boss Target Distance"
                units="mm"
                placeholder="100"
                :value="value.boss_target_dist"
                :disabled="disabled"
                @input="updateValue('boss_target_dist', $event, value)"
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=6 class="pt-0">
              <NumThicknesses
                :value="value.num_thicknesses"
                @input="updateValue('num_thicknesses', $event, value)"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
          <notes
            :value="value.design_basis_notes"
            label="Blueprint Report Notes: 'Tower Design Basis'"
            notes-info="Added to pg. 1 of Blueprint under 'Tower Design Basis'. (This overrides Design Basis Blueprint Notes)"
            :col-width=8
            :disabled="disabled"
            @input="updateValue('design_basis_notes', $event, value)"
          />
          <notes
            :value="value.certification_intent_notes"
            label="Certification Report Notes: 'Design Notes - Notes'"
            notes-info="Added to Certification Report pg. 3 'Design Notes' under 'Notes' header. (If left blank 'Note' section will be removed)"
            :col-width=8
            :disabled="disabled"
            @input="updateValue('certification_intent_notes', $event, value)"
          />
          <notes
            :value="value.certification_loadset_notes"
            label="Certification Report Notes: 'Design Notes - Load Set Notes'"
            notes-info="Added to Certification Report pg. 3 'Design Notes' under 'Notes' header. (If left blank defaults will be used)"
            :col-width=8
            :disabled="disabled"
            @input="updateValue('certification_loadset_notes', $event, value)"
          />
        </v-card-text>
      </v-card>

      <v-card elevation="24">
        <v-card-title>Turbine</v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="1">
              <img
                class="turbineImg"
                src="@/assets/nacelle.png"
              >
            </v-col>
            <v-col>
              <v-row>
                <v-col md="6">
                  <library-select 
                    :value="value.turbine" 
                    label="Turbine"
                    route="turbine"
                    :items="$store.state.turbines"
                    require-link
                    :disabled="disabled"
                    @input="updateValue('turbine', $event, value)"
                  />
                </v-col>

                <v-col md="6">
                  <v-select
                    label="Load Set"
                    :items="sortedLoadSets"
                    item-text="data.name"
                    item-value="uuid"
                    :value="value.load_sets"
                    :rules="[formRules.required]"
                    multiple
                    chips
                    @change="updateValue('load_sets', $event, value)"
                  >
                    <template #item="{ item, attrs, on }">
                      <v-list-item
                        v-on="on"
                        v-bind="attrs"
                        #default="{ active }"
                        :disabled="disabled"
                      >
                        <v-list-item-action>
                          <v-checkbox :input-value="active" />
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-row no-gutters align="center">
                            <span>{{ item.data.name }}</span>
                            </v-row>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="loadSetUsesTurbine(item.data?.turbine)">
                            <span class="green--text">Compatible:</span> Uses Current Turbine
                            <bordered-text-span
                              :text="currentTurbineName"
                              font-weight="bold"
                              padding="2px"
                            />
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else-if="item.data?.turbine">
                            <bordered-text-span
                              :text="getLoadSetTurbineName(item.data.turbine)"
                              font-weight="bold"
                              padding="2px"
                            />
                            (Incompatible with selected turbine)
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else>
                            No Turbine Assigned
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template #selection="selection">
                      <v-chip 
                        @click="routeToLibrary('load-set', selection.item.uuid)" 
                        v-text="selection.item.data.name"
                      />
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row v-if="value.hub_height">
                <v-col>
                  <v-radio-group
                    label="Target"
                    :value="value.hub_height.target"
                    mandatory
                    row
                    :disabled="disabled"
                    @change="updateValue('hub_height.target', $event, value)"
                  >
                    <v-radio
                      label="Hub Height"
                      value="HUB"
                    />
                    <v-radio
                      label="Tip Height"
                      value="BLADE_TIP"
                    />
                  </v-radio-group>
                </v-col>

                <v-col cols="3">
                  <numeric-text-field
                    label="Max Height"
                    units="m"
                    :value="value.hub_height.height"
                    required
                    :disabled="disabled"
                    @input="updateValue('hub_height.height', $event, value)"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <unit-results :results="turbineResults" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col
          cols="12"
          class="reverseorder"
        >
          <v-row
            v-for="(section, sectionIndex) in sections"
            :key="sectionIndex"
          >
            <v-col>
              <section-intent
                :value="section"
                :section-index="sectionIndex"
                :model="model?.sections[sectionIndex]"
                :prevent-delete="value.sections.length <= 1"
                :disabled="disabled"
                :assemblyDrawingNum="assemblyDrawingNumber"
                :shellMaterial="secShellMaterial[sectionIndex] + ` or equivalent`"
                @input="updateValue(['sections',sectionIndex], $event, value)"
                @delete="deleteSection(sectionIndex)"
                @duplicate="duplicateSection(sectionIndex)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <foundation-intent
        :value="value.foundation"
        :disabled="disabled"
        @input="updateValue('foundation', $event, value)"
      />
    </v-col>
  </v-row>
</template>
<script>
import _ from "lodash/fp"
import SectionIntent from "@/components/intent/SectionIntent"
import FoundationIntent from "@/components/intent/FoundationIntent"
import CommaSeparatedList from "@/components/common/CommaSeparatedList"
import NumericTextField from "@/components/common/NumericTextField"
import UnitResults from "@/components/common/UnitResults"
import LibrarySelect from "@/components/LibrarySelect"
import ModelNumberSelect from "@/components/ModelNumberSelect"
import NumThicknesses from "@/components/NumThicknesses"
import Notes from "@/components/Notes"
import BorderedTextSpan from "@/components/common/BorderedTextSpan"
import {dropIndex} from "@/utils/fp"

export default {
  name: "TowerIntent",
  components: {
    SectionIntent,
    FoundationIntent,
    CommaSeparatedList,
    NumericTextField,
    UnitResults,
    LibrarySelect,
    ModelNumberSelect,
    NumThicknesses,
    Notes,
    BorderedTextSpan,
},
  props: ["value", "model", "revision", "disabled"],
  computed: {
    sections() {
      return this.value?.sections ?? {}
    },
    turbineResults() {
      let result = this.$store.state.turbines.find((t) =>
        t.uuid === this.value.turbine)
      let model = result?.data
      if (model) {
        return [
          {
            key: "Neck height",
            value: model.flange.profile.neck_height,
            units: "mm",
          },
          {
            key: "Neck thickness",
            value: model.flange.profile.neck_thickness,
            units: "mm",
          },
          {
            key: "Plate height",
            value: model.flange.profile.plate_height,
            units: "mm",
          },
          {
            key: "Plate thickness",
            value: model.flange.profile.plate_width,
            units: "mm",
          },
          {
            key: "Number of bolts",
            value: model.flange.bolt_circle.count,
            units: "",
          },
          {
            key: "Bolt type",
            value: model.flange.bolt_circle.bolt.major_diameter + "mm",
            units: "",
          },
        ]
      } else {
        return []
      }
    },
    sortedLoadSets () {
      // Sort LoadSets A to Z
      let azLoadSets = _.sortBy('data.name')(this.$store.state.loadSets)
      // Extract LoadSets that match current turbine selected & sort A to Z
      let turbineFilteredLoadSets = azLoadSets.filter((loadSet) => {
        return loadSet.data?.turbine === this.value.turbine
      })
      turbineFilteredLoadSets = _.sortBy('data.name')(turbineFilteredLoadSets)
      // Remove LoadSets that have the current turbine selected
      let loadSets = azLoadSets.filter((loadSet) => !turbineFilteredLoadSets.includes(loadSet));
      // Prepend the LoadSets that match the current turbine selected
      return [...turbineFilteredLoadSets, ...loadSets]
    },
    currentTurbineName() {
      return this.$store.state.turbines.find((turbine) => {
        return turbine.uuid === this.value.turbine
      }).data.name
    },
    assemblyDrawingNumber() {
      let result = this.$store.state.turbines.find((t) =>
        t.uuid === this.value.turbine)
      let powerRating = result.data?.power_rating
      let oem = result.data?.oem
      let hubHeight = Math.round(this.model?.hub_height)
      let modelNumber = this.value?.model_number
      let revision = this.revision
      // The following functions come from towerdesign>numscheme.py
      function tower_id_str(oem, powerRating, hubHeight, modelNumber, revision) {
        let oemNumber = null
        switch(oem) {
          case "GE":
            oemNumber = 7
            break
          case "VESTAS":
            oemNumber = 6
            break
          case "NORDEX":
            oemNumber = 9
            break
          case "SGRE": // "SGRE is GAMESA in towerdesign"
            oemNumber = 4
            break
          default:
            break
        }

        function pad(numZeros, val) {
          return String(val).padStart(numZeros, "0")
        }

        powerRating = powerRating / 100 // convert to MegaWatts
        return `${pad(2, oemNumber)}${pad(3, powerRating)}${pad(3, hubHeight)}${pad(3, modelNumber)}-${revision}`
      }
      const towerId = tower_id_str(oem, powerRating, hubHeight, modelNumber, revision) // NOT UUID
      const seqNo = 0 // Usually zero for the assembly page
      return `${towerId}-D0${seqNo}-${revision}`
    },
    secShellMaterial() {
      let shellMaterials = {}
      if (this.model?.sections) {
        for (let secId of Object.keys(this.model.sections)) {
          let material = this.model.sections[secId].shells[0]?.material?.name
          if (material) {
            shellMaterials[secId] = material
          } else {
            shellMaterials[secId] = "MATERIAL_NAME"
          }
        }
      }
      return shellMaterials
    },
  },
  methods: {
    savingModelNumber() {
      return this.$refs.modelNumberRef.saveModelNumber()
    },
    deleteSection (idx) {
      this.updateValue(
        'sections',
        dropIndex(this.value.sections, idx),
        this.value)
    },
    duplicateSection (idx) {
      this.updateValue(
        'sections',
        this.value.sections.slice(0,idx).concat(
          this.value.sections[idx],
          this.value.sections.slice(idx)),
        this.value)
    },
    loadSetUsesTurbine(turbine) {
      return turbine ? turbine === this.value.turbine : false
    },
    getLoadSetTurbineName(turbine) {
      return this.$store.state.turbines.find((el) => {
        return el.uuid === turbine
      }).data?.name
    }
  },
}
</script>
<style scoped>
.reverseorder {
  display: flex;
  flex-direction: column-reverse;
}
</style>
