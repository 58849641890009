var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Coil Grouping",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.specify,
      callback: function callback($$v) {
        _vm.specify = $$v;
      },
      expression: "specify"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.specify ? _c('v-text-field', {
    attrs: {
      "value": _vm.value,
      "label": "Number of Thicknesses",
      "rules": [_vm.formRules.required, _vm.formRules.positiveWholeNumber]
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }