<template>
  <v-dialog  
    v-model="show" 
    max-width="500"
  >
    <v-card
      class="mx-auto"
      tile
    >
      <v-card-title>
        <span>
          <bordered-text-span
          :text="String(numRefs)"
          padding="3px"
          />
          {{ numRefs > 1 ? 'Towers' : 'Tower' }} Using {{ objectName }}
        </span>
      </v-card-title>
      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item 
            v-for="reference in references"
            :key="reference[1]"
            @click="routeToTower(reference[1])"
          >
            <v-list-item-icon>
              <v-icon
                size="30"
                color="primary"
              >
                mdi-wind-turbine
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap pb-1">
                <template>
                  <span class="text-subtitle-2 mr-2">
                    {{ reference[0] }}
                  </span>
                  <v-btn
                    x-small
                    rounded
                    outlined
                    :color="statusColor(reference[2])"
                  >
                    {{ reference[2] }}
                  </v-btn>
                </template>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          text
          @click.stop="show = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BorderedTextSpan from "@/components/common/BorderedTextSpan"

export default {
    components: {
      BorderedTextSpan,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        references: {
            type: Array,
            required: false,
        },
        numRefs: {
            type: Number,
            required: false,
        },
        objectName: {
            type: String,
            required: false,
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
                this.$emit('input', value)
            },
        },
    },
    methods: {
        routeToTower(uuid) {
            return this.$router.push({
              path: `/tower/${uuid}/intent`
            })
        },
    }
}
</script>