<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-radio-group
            row
            required
            :value="value.anchor"
            @change="updateValue('anchor', $event, value)"
            :disabled="disabled"
          >
            <div v:slot="label">
              Anchor
            </div>
            <v-radio
              label="Top"
              value="TOP"
            />
            <v-radio
              label="Bottom"
              value="BASE"
            />
          </v-radio-group>
        </v-col>
        <v-col cols="4">
          <comma-separated-list
            label="Vertical offsets"
            units="m"
            :value="value.vertical_offsets"
            @input="updateValue('vertical_offsets', $event, value)"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
      <v-row>
        <radial-pattern
          :value="value.radial_pattern"
          @input="updateValue('radial_pattern', $event, value)"
          :disabled="disabled"
        />
      </v-row>
      <v-row>
        <trait
          :value="value.trait"
          @input="updateValue('trait', $event, value)"
          :disabled="disabled"
        />
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-icon
        @click="$emit('delete')"
        :disabled="disabled"
      >
        mdi-delete
      </v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import CommaSeparatedList from "@/components/common/CommaSeparatedList"
import Trait from "@/components/intent/Trait"
import RadialPattern from "@/components/intent/RadialPattern"

export default {
  name: "SectionFeature",
  components: {
    CommaSeparatedList,
    Trait,
    RadialPattern,
  },
  props: ["value", "disabled"],
  methods: {
    deleteFeature() {
    },
  },
}
</script>

<style scoped>
</style>
