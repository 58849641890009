<template>
  <v-app-bar
    dense
    dark
    elevate-on-scroll
    :style="{
      'position': 'sticky',
      'top': appbarBreakpoint,
      'z-index': 20
    }"
  >
  <!-- :style="{ 'transform':appbarBreakpoint }" -->
    <v-tabs align-with-title>
      <v-tab
        :to="{ name: 'tower-intent', params: { uuid: $route.params.uuid } }"
      >
        Design
      </v-tab>
      <v-tab
        :to="{ name: 'tower-srf-plot', params: { uuid: $route.params.uuid } }"
      >
        SRF Plot
      </v-tab>
      <v-tab
        :to="{ name: 'tower-reports', params: { uuid: $route.params.uuid } }"
      >
        Reports
      </v-tab>
    </v-tabs>
    <slot name="actions" />
  </v-app-bar>
</template>


<script>
export default {
  name: "TowerNav",
  mounted() {
    this.$store.dispatch("getTower", this.$route.params.uuid)
    .then((response) => {
      this.$store.commit("setTowerIntent", response.data)
    })
  },
  computed: {
    appbarBreakpoint() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '56px'
        case 'sm': return '56px'
        default: return '64px'
      }
    }
  }
}
</script>
