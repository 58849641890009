<template>
  <div>
    <tower-nav />
    <v-container fluid>
      <v-row>
        <v-spacer />
        <v-col>
          <div v-html="towerIntent.srf_plot" />
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TowerNav from "@/components/common/TowerNav"

export default {
  name: "SRFPlot",
  components: {
    TowerNav,
  },
  computed: {
    towerIntent () {
      return this.$store.state.towerIntent
    },
  },
}
</script>
