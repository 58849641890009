<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="4">
        <v-alert
          v-if="error_message"
          type="error"
          dismissible
        >
          {{ error_message }}
        </v-alert>
        <v-card v-if="$store.getters.needChangePassword()">
          <v-card-title>Change password</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="newPassword"
                :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="[formRules.required, localRules.min]"
                :type="showNewPassword ? 'text' : 'password'"
                name="input-10-1"
                label="New password"
                @click:append="showNewPassword = !showNewPassword"
              />
              <v-text-field
                :append-icon="showRePassword ? 'mdi-eye-off' : 'mdi-eye'"
                :rules="[formRules.required, localRules.min, localRules.match]"
                :type="showRePassword ? 'text' : 'password'"
                name="input-10-2"
                label="Reenter password"
                @click:append="showRePassword = !showRePassword"
              />
              <v-btn @click="submitPasswordChange()">
                Change Password
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>

        <v-card v-else>
          <v-card-title>Sign In</v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                label="User name"
                data-lpignore="false"
                @keyup.enter="signIn()"
              />
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                autocomplete="on"
                data-lpignore="false"
                @click:append="showPassword = !showPassword"
                @keyup.enter="signIn()"
              />
              <v-btn @click="signIn()">
                Sign in
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    return {
      username: null,
      password: null,
      newPassword: null,
      showPassword: false,
      showNewPassword: false,
      showRePassword: false,
      error_message: null,
      localRules: {
        min: (value) => (value && value.length >= 8) || "Min 8 characters.",
        match: (value) => value === this.newPassword || "Must match",
      },
    };
  },
  methods: {
    signIn() {
      this.error_message = null;
      this.$store
        .dispatch("signIn", {
          username: this.username,
          password: this.password,
        })
        .catch((err) => (this.error_message = err.message));
    },
    submitPasswordChange() {
      this.$store
        .dispatch("completeNewPassword", this.newPassword)
        .catch((err) => (this.error_message = err.message));
    },
  },
};
</script>
<style scoped></style>
