<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <numeric-text-field
            label="SN slope"
            :value="value.sn_slope"
            :disabled="isGlobal"
            @input="updateValue('sn_slope', $event, value)"
          />
        </v-col>
        <v-col>
          <numeric-text-field
            label="Detail category"
            :value="value.detail_category"
            units="MPa"
            :disabled="isGlobal"
            @input="updateValue('detail_category', $event, value)"
          />
        </v-col>
      </v-row>
      <v-row>
        <knockdowns
          :value="value.knockdowns"
          :is-global="isGlobal"
          @input="updateValue('knockdowns', $event, value)"
        />
      </v-row>
      <v-row>
        <traits
          :value="value.trait_types"
          :is-global="isGlobal"
          @input="updateValue('trait_types', $event, value)"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"
import Traits from "@/components/design/Traits"
import Knockdowns from "@/components/design/Knockdowns"

export default {
  name: "DamageEquivalentAnalyzer",
  components: {
    Traits,
    Knockdowns,
    NumericTextField,
  },
  props: ["value", "isGlobal"],
}
</script>
