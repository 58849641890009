var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('custom-data-table', {
    attrs: {
      "title": _vm.name || 'Elevation mapping',
      "headers": _vm.elevationHeaders,
      "items": _vm.value,
      "value-units": _vm.elevationValueUnits,
      "data-empty-row": _vm.dataEmptyRow,
      "ordered-row-items": ['elevation', 'srf'],
      "optional-props": {
        'disabled-pagination': true,
        'hide-default-footer': true,
        'dense': true,
        'disable-sort': true,
        class: 'mb-4'
      },
      "showAddCsvBtn": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }