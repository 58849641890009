<template>
  <v-simple-table dense>
    <tbody>
      <tr
        v-for="(result, index) in results"
        :key="index"
      >
        <td>{{ result.key }}</td>
        <td v-if="result.units === 'mm' || result.units === 'kg'">
          {{ result.value | formatNumber }} {{ result.units }}
        </td>
        <td v-else>
          {{ result.value }} {{ result.units }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: "UnitResults",
  props: ["results"],
  data: () => {
    return {};
  },
};
</script>

<style scoped>
.embedded {
  padding: 0px;
  margin: 0px;
}
td {
  color: gray;
}
</style>
