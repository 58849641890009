<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <span v-on="on">{{ datetimeToDistance(timeStr) }}</span>
    </template>
    <span>{{
      new Date(timeStr).toLocaleDateString(undefined, {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TimeDistance",
  props: ["timeStr"],
  data() {
    return {};
  },
};
</script>
