<template>
  <v-card elevation="24">
    <v-card-title>Foundation</v-card-title>
    <v-card-text>
      <v-row v-if="value">
        <v-col cols="1">
          <img
            src="@/assets/foundation.png"
            height="35px"
            width="35px"
          >
        </v-col>
        <v-col cols="3">
          <numeric-text-field
            label="Height"
            units="m"
            :value="value.height"
            :disabled="disabled"
            @input="updateValue('height', $event, value)"
          />
        </v-col>
        <v-col cols="3">
          <numeric-text-field
            label="Stiffness"
            units="GNm/rad"
            :value="value.stiffness"
            :disabled="disabled"
            @input="updateValue('stiffness', $event, value)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NumericTextField from "@/components/common/NumericTextField"

export default {
  name: "FoundationIntent",
  components: {
    NumericTextField,
  },
  props: ["value", "disabled"],
}
</script>
