<template>
  <div>
    <v-list v-if="groupBy">
      <v-list-group
        v-for="(subObjects, groupName) in groupedObjects"
        :key="groupName"
        no-action
      >
        <template #activator>
          <v-list-item-title>
            {{ groupName === 'GAMESA' ? 'SGRE' : groupName }}
          </v-list-item-title>
        </template>
        <template v-if="sublist">
          <v-list-group
            v-for="object in subObjects"
            :key="object.uuid"
            no-action
            sub-group
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ object.data.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="modelGroup in object.model_numbers"
              :key="modelGroup.model_name"
              :to="{
                name: 'towers-list-turbine-model-number',
                params: { turbine: object.uuid, model_number: modelGroup.model_number }
              }"
              :class="{
                activeLink: $route.params.turbine === object.uuid
                  && $route.params.model_number === modelGroup.model_number
              }"
            >
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <v-list-item-title
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ modelGroup.model_name }}
                  </v-list-item-title>
                </template>
                <span>{{ modelGroup.model_name }}</span>
              </v-tooltip>
            </v-list-item>
            <v-list-item
              :to="{ name: 'towers-list-turbine', params: { turbine: object.uuid } }"
              :class="{ activeLink: $route.params.turbine === object.uuid }"
            >
              Other
            </v-list-item>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item
            v-for="object in subObjects"
            :key="object.uuid"
            no-action
            @click="subObjectNavTo(object)"
          >
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-list-item-title
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ object.data.name }}
                </v-list-item-title>
              </template>
              <span>{{ object.data.name }}</span>
            </v-tooltip>
          </v-list-item>
        </template>
      </v-list-group>
      <v-list-item
        v-if="addOtherOption"
        :to="`/${$route.path.split('/')[1]}`"
      >
        <v-list-item-title>Other</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item
        v-for="(item, index) in objects"
        :key="index"
        :class="{ activeLink: $route.params.uuid === item.uuid }"
        :to="{ name: route, params: { uuid: item.uuid } }"
      >
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-list-item-title
              v-bind="attrs"
              v-on="on"
            >
              {{ item.data.name }}
            </v-list-item-title>
          </template>
          <span>{{ item.data.name }}</span>
        </v-tooltip>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import _ from 'lodash/fp'

export default {
  name: "NavList",
  props: ["objects", "route", "groupBy", "sublist", "addOtherOption"],
  computed: {
    groupedObjects () {
      if (!this.sublist) {
        return _.groupBy(this.groupBy, this.objects)
      } else {
        // Add relevant model number data to each turbine object for UI
        for (var object of this.objects) {
          let turbine = object.uuid
          let organization_id = object.organization_id
          object.model_numbers = _.sortBy("model_name")(this.sublist.filter((modNum) => modNum.turbine === `${organization_id}#${turbine}`))
        }
        return _.groupBy(this.groupBy, this.objects)
      }
    },
  },
  methods: {
    subObjectNavTo(object) {
      if (this.$route.path.split("/")[1] === "load-set") {
        this.$router.push(`/${this.$route.path.split('/')[1]}?sortBy=${object.uuid}`)
      } else {
        this.$router.push({
          name: this.route,
          params: { uuid: object.uuid},
        })
      }
    },
  },
}
</script>

<style scoped>
  >>>.v-list-item--active {
    background-color: white;
    color: black;
  }
  >>>.v-list-item--link:before {
    background-color: white;
  }
</style>
